<p-toast position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<p-tabView class="center-tab">
    <p-tabPanel header="Pointage ouvrier">
        <button type="button" class="btn btn-secondary no-background ms-5" routerLink="/dashboard/rh">
            <i class="pi pi-arrow-circle-left"></i>
            retour
        </button>
        <div class="container">
            <div class="row mb-5 mt-5">
              <div class="col-xxl-3 col-xl-6 col-md-6 col-sm-6" *ngFor="let chantier of chantiers; index as i">
                <!--begin::Card-->
                <div class="card card-custom gutter-b card-stretch mb-5">
                  <!--begin::Body-->
                  <div class="card-body d-flex justify-content-center flex-column">
                    <!--begin::Toolbar-->

                    <!--end::Toolbar-->
                    <!--begin::User-->
                    <div class="d-flex align-items-end py-2 mb-5">
                      <!--begin::Pic-->
                      <div class="d-flex align-items-center">
                        <!--begin::Pic-->
                        <div class="d-flex flex-shrink-0 mr-5">
                          <div class="symbol symbol-50px me-2">
                            <span class="symbol-label">
                              <img alt="" class="w-25px" src="../../../assets/images/aven.svg" />
                            </span>
                          </div>
                        </div>
                        <!--end::Pic-->
                        <!--begin::Title-->
                        <div class="d-flex flex-column">
                          {{chantier.nom}}
                          <span class="text-gray-400 fw-bold d-block"> {{chantier.client.nom}} </span>
                        </div>
                        <!--end::Title-->

                      </div>
                      <!--end::Title-->

                    </div>
                    <!--end::User-->
                    <!--begin::Desc-->

                    <!--end::Desc-->
                    <!--begin::Contacts-->
                    <div class="py-2">
                      <div class="d-flex align-items-center mb-5">
                        <i class="pi pi-map-marker"></i>

                        <span class="text-muted font-weight-bold ms-3"> {{chantier.adresse}}-{{chantier.ville}} {{
                          chantier.codePostal }}</span>
                      </div>

                      <div class="d-flex align-items-center mb-5">
                        <span class="flex-shrink-0">
                          <i class="pi pi-pi-box"></i>
                          <a class="text-muted text-hover-primary font-weight-bold ms-4">budget :
                            {{chantier.chiffre_affaire/250 }} j/h</a>
                        </span>
                      </div>
                      <!-- <div class="d-flex align-items-center mb-5">
                        <span class="flex-shrink-0">
                          <i class="pi pi-pi-box" ></i>
                        <a  class="text-muted text-hover-primary font-weight-bold ms-4">Homme jour : {{chantier.hj}} j/h</a>
                      </span>
                      </div> -->
                      <p-progressBar [value]="chantier.pourcentageHj" [showValue]="true"></p-progressBar>
                      <div class="d-flex mt-6">
                      
                        <button pButton pRipple type="button" label="Pointage" icon="pi pi-check-circle"
                          class="p-button-raised p-button-text"
                          [routerLink]="['/dashboard/rh/verifpointage/',chantier.id,date]"></button>
                      </div>
                    </div>
                    <!--end::Contacts-->
                    <!--begin::Actions-->

                    <!--end::Actions-->
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Card-->
              </div>
            </div>

            <!--end::Container-->
          </div>
    </p-tabPanel>  

    <p-tabPanel header="Pointage chef chantiers et conducteurs">
        <button type="button" class="btn btn-secondary no-background ms-5" routerLink="/dashboard/rh">
            <i class="pi pi-arrow-circle-left"></i>
            retour
        </button>
        <app-pointage-chef-conducteur></app-pointage-chef-conducteur>
    </p-tabPanel>
   
</p-tabView>