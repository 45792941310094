<div class="content fs-6 d-flex flex-column flex-column-fluid full-height" id="kt_content">
  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
  
    <div class="container">
      <div class="row g-5 gx-xxl-12 mb-xxl-3">
        <div class="col-xxl-12" data-select2-id="select2-data-139-ub4n">
          <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur/chantiers">
            <i class="pi pi-arrow-circle-left" ></i>
            Retour
          </button>
          <div class="card card-xxl-stretch mb-xl-3 p-10">
            <div class="control-section">
              <ejs-gantt id="ganttDefault" height="450px" [dataSource]="data" taskMode="Custom" [treeColumnIndex]="1"
                validateManualTasksOnLinking="true" [taskFields]="taskSettings" [toolbar]="toolbar" [columns]="columns">
              </ejs-gantt>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>