<p-toast></p-toast>

<p-confirmPopup></p-confirmPopup>
<div class="content fs-6 d-flex flex-column flex-column-fluid no-padding" id="kt_content"
  data-select2-id="select2-data-kt_content">
  <!--end::Toolbar-->
  <!--begin::Post-->
  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post" data-select2-id="select2-data-kt_post">
    <!--begin::Container-->
    <div class="container-fluid no-padding" data-select2-id="select2-data-152-wce8 ">
      <!--begin::Search form-->
      <div class="
          card
          mb-5
          bgi-no-repeat bgi-position-y-top bgi-size-cover
          chantier-bg
        " data-select2-id="select2-data-148-ndjy">
        <!--begin::body-->
        <div class="card-body d-flex flex-column flex-center py-10 py-lg-20" data-select2-id="select2-data-147-o5m9">
          <div class="w-xxl-800px d-flex flex-column" data-select2-id="select2-data-141-ph1f">
            <!--begin::Simple form-->
            <div class="
                rounded
                d-flex
                flex-column flex-lg-row
                align-items-lg-center
                bg-body
                p-5
                min-h-75px
              ">
              <!--begin::Row-->
              <div class="row flex-grow-1 mb-5 mb-lg-0" data-select2-id="select2-data-140-zsd7">
                <!--begin::Col-->
                <div class="col-lg-12 d-flex align-items-center mb-5 mb-lg-0">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Input-->
                  <input type="text" class="
                      form-control form-control-sm form-control-flush
                      fw-boldest
                      flex-grow-1
                    " name="search" value="" placeholder="rechercher des ouvriers" />
                  <!--end::Input-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->

                <!--end::Col-->
                <!--begin::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Action-->
              <div class="min-w-150px text-end">
                <button type="submit" class="btn btn-primary" id="kt_advanced_search_button_1">
                  rechercher des ouvriers
                </button>
              </div>
              <!--end::Action-->
            </div>
          </div>
          <!--end::Block-->
        </div>
        <!--end::body-->
      </div>
    </div>
    <!--end::Container-->
  </div>
</div>
<div class="container">
  <div class="d-flex justify-content-between">
    <!-- <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard">
      <i class="pi pi-arrow-circle-left" ></i>
      retour
    </button> -->
    <div class="
        d-flex
        flex-column
        align-items-start
        justify-content-center
        flex-wrap
        me-2
        mt-5
        mb-10
      ">
      <!--begin::Title-->
      <h1 class="text-dark fw-bolder my-1 fs-2">
        Pointage chantier {{chantierName}}
      </h1>
      <small class="text-muted fs-6 fw-normal ms-1">{{
        currentDate | date: " d MMM, y"
        }}</small>
    </div>
  </div>
  <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur/planing"
    *ngIf="conducteur">
    <i class="pi pi-arrow-circle-left"></i>
    Retour
  </button>
  <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/" *ngIf="!conducteur">
    <i class="pi pi-arrow-circle-left"></i>
    Retour
  </button>
  <p-dataView #dv [value]="ouvriers" [rows]="10" [rowsPerPageOptions]="[5,10,30]" [paginator]="true"
    filterBy='user.firstName'>
    <ng-template pTemplate="header">
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <i class="pi pi-search"></i>
          <input type="search" pInputText placeholder="Recherche par nom" (input)="dv.filter($event.target.value)">
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="listItem" let-ouvrier let-rowIndexValue="rowIndex">
      <div id="kt_project_users_card_pane" class="container-fluid mt-10 ">
        <!--begin::Row-->
        <div class="row g-6 g-xl-9 mb-5">
          <!--begin::Col-->

          <div class="col-md-12 mt-0 col-xxl-12">
            <!--begin::Card-->
            <div class="card">
              <!--begin::Card body-->
              <div class="card-body d-flex ali pt-12 card-style p-9">
                <!--begin::Avatar-->
                <div class="d-flex align-items-center">
                  <div class="me-4 align-items-center">
                    <div class="symbol symbol-65px symbol-circle">
                      <div class="symbol symbol-50px me-5">
                        <img alt="Logo" src="../../../assets/images/avtar.jpg" *ngIf="!ouvrier?.image">
                        <img [src]="ouvrier?.image" *ngIf="ouvrier?.image" alt="logo" class="card-img rounded-circle" />
                      </div>
                    </div>
                  </div>

                  <!--end::Avatar-->
                  <div>
                    <!--begin::Name-->
                    <div class="fs-3 text-gray-800 fw-boldest mb-1">
                      {{ ouvrier.user.firstName }} {{ ouvrier.user.lastName }}
                    </div>
                    <!--end::Name-->
                    <!--begin::Position-->
                    <div class="fs-5 fw-bold text-gray-400">
                      {{ ouvrier.user.email }}
                    </div>
                  </div>
                </div>
                <form [formGroup]="datePointage">
                  <div formArrayName="pointage">
                    <div class="d-flex flex-wrap flex-center" [formGroupName]="rowIndexValue">
                      <div class="border border-dashed rounded min-w-75px py-3">
                        <div *ngIf="ouvrier.duree == 'JOURNEE'" class="fs-6 fw-boldest text-pointage">
                          Journée
                        </div>
                        <div *ngIf="ouvrier.duree == 'MATIN'" class="fs-6 fw-boldest text-pointage">
                          Matin
                        </div>
                        <div *ngIf="ouvrier.duree == 'SOIR'" class="fs-6 fw-boldest text-pointage">
                          Soir
                        </div>

                      </div>
                      <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">
                        <label class="fs-7 fw-bold text-gray-400">date d'entrée</label>
                        <div class="fw-bold text-gray-400" *ngIf="!ouvrier.entryDate &&!ouvrier.absent">

                          <p-calendar class="p-inputtext-sm" formControlName="entryDate" [timeOnly]="true"
                            inputId="timeonly" [style]="{'width':'50%'}"></p-calendar>

                        </div>
                        <small class="p-error"
                          *ngIf="validIndex == rowIndexValue && datePointage.get('pointage')['controls'][rowIndexValue].controls.entryDate.hasError('required')">
                          La date d'entrée est requise.</small>

                        <div class="fw-bold date-pointage" *ngIf="ouvrier.entryDate && !ouvrier.absent">
                          {{ ouvrier.entryDate | date: 'HH:mm'}}
                        </div>
                      </div>
                      <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">
                        <label class="fs-7 fw-bold text-gray-400">date de sortie</label>
                        <div class="fw-bold date-pointage" *ngIf="ouvrier.exitDate && !ouvrier.absent">
                          {{ ouvrier.exitDate | date: 'HH:mm'}}
                        </div>
                        <div class="fw-bold text-gray-400" *ngIf="!ouvrier.exitDate &&!ouvrier.absent">
                          <p-calendar formControlName="exitDate" class="p-inputtext-sm" [style]="{'width':'50%'}"
                            [timeOnly]="true" inputId="timeonly"></p-calendar>
                        </div>
                        <small class="p-error"
                          *ngIf="validIndex == rowIndexValue && datePointage.get('pointage')['controls'][rowIndexValue].controls.exitDate.hasError('required')">
                          La date de sortie est requise.</small>
                      </div>

                      <!--end::Stats-->
                    </div>
                  </div>
                </form>
                <div class="d-flex flex-wrap flex-center">
                  <div class="
                  border border-dashed
                  rounded
                  min-w-150px
                  d-flex
                  flex-colmun
                  justify-content-center
                ">
                    <div>
                      <div class="my-1 d-flex flex-column buttons-group" data-select2-id="select2-data-137-5dg3">
                        <div class="alert alert-danger" *ngIf="ouvrier.absent">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo5/dist/../src/media/svg/icons/Code/Warning-1-circle.svg--><svg
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                              height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          Absence confirmée
                        </div>

                        <div class="alert alert-success" *ngIf="ouvrier.confirmed">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo5/dist/../src/media/svg/icons/Communication/Clipboard-check.svg--><svg
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                              height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                  fill="#000000" opacity="0.3" />
                                <path
                                  d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                                  fill="#000000" />
                                <path
                                  d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                  fill="#000000" />
                              </g>
                            </svg>
                          </span>
                          pointage confirmé
                        </div>

                        <button (click)="changeStatusUser($event, ouvrier.id,rowIndexValue)" value="confirmed" *ngIf="
                        ouvrier.pointed && !ouvrier.absent && !ouvrier.confirmed 
                      " class="btn btn-primary mb-2">
                          Confirmer le pointage
                        </button>
                        <button (click)="changeStatusUser($event, ouvrier.id,rowIndexValue)" value="pointed_confirmed"
                          *ngIf="
                        !ouvrier.pointed &&
                        !ouvrier.confirmed &&
                        !ouvrier.absent
                      " class="btn btn-secondary mb-2">
                          Cliquer pour pointer
                        </button>
                        <button (click)="changeStatusUser($event, ouvrier.id,rowIndexValue)" value="absent"
                          class="btn btn-danger mb-2" *ngIf="!ouvrier.absent && !ouvrier.confirmed">
                          Absent
                        </button>
                        <button (click)="changeStatusUser($event, ouvrier.id,rowIndexValue)"
                          value="annuler_pointed_confirmed" *ngIf="ouvrier.confirmed" class="btn btn-dark">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                                  fill="#000000" fill-rule="nonzero" />
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          Annuler pointage
                        </button>
                        <button (click)="changeStatusUser($event, ouvrier.id,rowIndexValue)" value="annuler_absent"
                          *ngIf="ouvrier.absent" class="btn btn-dark mb-2">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                                  fill="#000000" fill-rule="nonzero" />
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          Annuler l'absence
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--end::Stats-->
                </div>
                <div>
                  <button pButton pRipple icon="pi pi-pencil" (click)="updatePointage(ouvrier)"
                    class=" p-mr-4  me-5 button-edit" *ngIf="!ouvrier.absent && ouvrier.pointed"></button>
                </div>

                <!--end::Info-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>


<p-dialog [(visible)]="updatePointageDialog" header="Modification pointage" [modal]="true" styleClass="p-fluid "
  class="overflow-show">
  <div id="kt_project_users_card_pane" class="container-fluid mt-10 ">
    <!--begin::Row-->
    <div class="row g-6 g-xl-9 mb-5">
      <!--begin::Col-->
      <div class="col-md-12 mt-0 col-xxl-12">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card body-->
          <div class="card-body d-flex ali pt-12 card-style p-9">
            <!--begin::Avatar-->
            <div class="d-flex align-items-center">
              <div class="me-4 align-items-center">
                <div class="symbol symbol-65px symbol-circle">
                  <div class="symbol symbol-50px me-5">
                    <img alt="Logo" src="../../../assets/images/avtar.jpg" *ngIf="!ouvrierUpdate?.image">
                    <img [src]="ouvrierUpdate?.image" *ngIf="ouvrierUpdate?.image" alt="logo"
                      class="card-img rounded-circle" />
                  </div>
                </div>
              </div>

              <!--end::Avatar-->
              <div>
                <!--begin::Name-->
                <div class="fs-3 text-gray-800 fw-boldest mb-1">
                  {{ ouvrierUpdate?.user?.firstName }} {{ ouvrierUpdate?.user?.lastName }}
                </div>
                <!--end::Name-->
                <!--begin::Position-->
                <div class="fs-5 fw-bold text-gray-400">
                  {{ ouvrierUpdate?.user?.email }}
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap flex-center">
              <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">

                <div class="fw-bold text-gray-400">
                  <label for="time">Date d'entrée</label>
                  <p-calendar class="p-inputtext-sm" [(ngModel)]="entryDateUpdate" [timeOnly]="true" inputId="timeonly"
                    [style]="{'width':'50%'}"></p-calendar>

                </div>
                <small class="p-error" *ngIf="submitted && entryDateUpdate==null">
                  La date d'entrée est requise.</small>

              </div>
              <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">
                <div class="fw-bold text-gray-400">
                  <label for="time">Date de sortie</label>
                  <p-calendar class="p-inputtext-sm" [(ngModel)]="exitDateUpdate" [style]="{'width':'50%'}"
                    [timeOnly]="true" inputId="timeonly"></p-calendar>
                </div>
                <small class="p-error" *ngIf="submitted && exitDateUpdate==null">
                  La date de sortie est requise.</small>
              </div>
            </div>
            <div class="d-flex flex-wrap flex-center">
              <div class="
              border border-dashed
              rounded
              min-w-150px
              d-flex
              flex-colmun
              justify-content-center
            ">
                <div>
                  <button pButton pRipple label="Modifier" (click)="saveUpdatePointage(ouvrierUpdate.id)"
                    icon="pi pi-plus" class="btn btn-primary p-mr-2 mt-5"></button>
                  <!--end::Stats-->
                </div>
                <!--end::Info-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
        </div>
      </div>
    </div>
  </div>
</p-dialog>