import { Component, OnInit } from '@angular/core';
import { DatePipe } from "@angular/common";
import { ElementRef, ViewChild } from "@angular/core";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import { PlanningService } from "../planning.service";


@Component({
  selector: 'app-calendrier-chantier',
  templateUrl: './calendrier-chantier.component.html',
  styleUrls: ['./calendrier-chantier.component.css']
})
export class CalendrierChantierComponent implements OnInit {

  ChantierSelect
  allChantier: any[];
  usersPointage: any[];
  startDate: Date;
  data=[];
  calendarOptions: CalendarOptions;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('calendar', { static: true }) calendar: ElementRef<any>

  constructor(private planningService: PlanningService, public datepipe: DatePipe) { }

  ngOnInit() {
    this.getallChantiers()
    
    this.calendarOptions = {
      
      initialView: "dayGridMonth",
      locale: "fr",
      nextDayThreshold: '09:00:00',
      weekends: false ,
      dateClick: this.handleDateClick.bind(this),
    
    
      headerToolbar: {
        left: "myPredButton,myNextButton",
        center: "title",
        right: "",
      },
      customButtons: {
        myNextButton: {
          text: '>',
          click: () => this.gonext()
        },
        myPredButton: {
          text: '<',
          click: () => this.goPrev()
        }
      }
      };
  }
  gonext() {
    const calendarApi = this.calendarComponent.getApi();
       calendarApi.next(); // call a method on the Calendar object
       let startDate = this.datepipe.transform( calendarApi.view.currentStart, "yyyy-MM-dd");
       let endDate =this.datepipe.transform(calendarApi.view.currentEnd, "yyyy-MM-dd") ;
       this.getPointageOuvrierByChantier(this.ChantierSelect.id,startDate,endDate);
     }
     goPrev() {
       const calendarApi = this.calendarComponent.getApi();
          calendarApi.prev(); // call a method on the Calendar object
        let startDate = this.datepipe.transform( calendarApi.view.currentStart, "yyyy-MM-dd");
       let endDate =this.datepipe.transform(calendarApi.view.currentEnd, "yyyy-MM-dd") ;
   
       this.getPointageOuvrierByChantier(this.ChantierSelect.id,startDate,endDate);
        }
        handleDateClick(arg) {
          console.log('date click! ' + arg.dateStr)
        }

  selectAllChantiers() {
    this.getPointageOuvrierByChantier(this.ChantierSelect.id, null, null);
     this.getallChantiers();
   }


getallChantiers(){
this.planningService.getListChantier().subscribe((response: any) => {
  this.allChantier = response
});    
  }

  getPointageOuvrierByChantier(idChantier,startDate,endDate) {
    if (startDate==null &&endDate==null ) {
      startDate = this.datepipe.transform(new Date().setMonth(new Date().getMonth()-1), "yyyy-MM-dd");
     endDate = this.datepipe.transform( new Date().setMonth(new Date().getMonth()+1), "yyyy-MM-dd");
    }
    this.calendarOptions.events=[];
    this.data=[];
    this.planningService.getPointageUserByIdChantierAndDate(startDate,endDate, idChantier).subscribe((response: any) => {
      
       response.forEach(obj => {
       
       let pointage={
         "id":obj.id,
         "title":obj.user.firstName+" " +obj.user.lastName ,
          "start":obj.workDay,
          "color":''
       }
       if (obj.exitDate && obj.entryDate) {
        pointage.title=  pointage.title +" /" +  this.datepipe.transform(obj.entryDate, "HH:mm") +" - "+ this.datepipe.transform(obj.exitDate, "HH:mm") 
       }
       if (obj.confirmed) {
        pointage. color='green'
       }
       if (obj.absent) {
        pointage. color='red'
       }
       if (obj.pointed && !obj.confirmed) {
        pointage. color='#FBC02D'
       }
       this.data.push(pointage);
       this.calendarOptions.events=this.data
      });
    })
  }

}
