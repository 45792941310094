<p-toast></p-toast>
<div classe="body">
    <main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
        <div class="container">
            <div class="card login-card">
                <div class="row no-gutters">
                    <div class="col-md-5">
                        <!-- <img src="/assets/img/login.jpg" alt="login" class="login-card-img"> -->
                    </div>
                    <div class="col-md-7">
                        <div class="card-body">
                            <div class="brand-wrapper">
                                <img src="../../../assets/images/logo.png" alt="logo" />
                            </div>
                            <p class="login-card-description">Réinitialisez votre mot de passe pour continuer.</p>
                            <form [formGroup]="resetForm">
                              
                                <div class="p-fluid">
                                    <div class="p-field">
                                        <p-password formControlName="newPassword"
                                            [toggleMask]="true" placeholder="Nouveau mot de passe"></p-password>
                                    </div>
                                    <div >
                                        <small class="text-danger" *ngIf="submited && resetForm.controls.newPassword.hasError('required')">
                                            mot de passe est requis.</small>
                                        <small class="text-danger" *ngIf="submited && resetForm.controls.newPassword.hasError('minlength')">
                                             Le mot de passe doit comporter 5 caractères.</small>
                                    </div>
                    

                                    <br>
                                    <div class="p-field">
                                        <p-password formControlName="conPassword"
                                            [toggleMask]="true" placeholder="Confirmer votre mot de passe"></p-password>
                                    </div>
                                    <small class="text-danger" *ngIf="submited && resetForm.controls.newPassword.hasError('required')">
                                        mot de passe est requis.</small>
                                    <small class="text-danger"
                                        *ngIf="submited && resetForm.get('conPassword').hasError('compare')">mot de passe ne correspondent pas.</small>
                                </div>
                                <br>
                                    <button class="btn btn-primary w-100 fw-bolder me-3 my-2" type="button" [disabled]="loading" (click)="submitForm()" >
                                        <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                                        Sauvegarder <span *ngIf="loading">...</span>
                                      </button>
                            </form>
                            <br>
                            <a href routerLink="/login" class="link-primary fs-6 ">login ?</a>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>

</div>
