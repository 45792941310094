import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsommationService {


  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }

  getAllChantier() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantiers/', { headers: headers });
    return chantier;
  }

  getHommeJour(idChantier,startDate,endDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'paye/countHJ/', {params: params, headers: headers });
    return chantier;
  }

  getSituation(idChantier,fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('startDate', fromDate);
    params = params.append('endDate', toDate);

    const situation = this.httpClient.get(environment.baseURL + environment.apiPath + 'paye/countSituation', {params: params, headers: headers });
    return situation;
  }

  getMasseSalarialesByChantier(idChantier,fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('startDate', fromDate);
    params = params.append('endDate', toDate);

    const ms = this.httpClient.get(environment.baseURL + environment.apiPath + 'paye/countMasseSalariales', {params: params, headers: headers });
    return ms;
  }
  getFacturesByChantier(idChantier,fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('startDate', fromDate);
    params = params.append('endDate', toDate);

    const facture = this.httpClient.get(environment.baseURL + environment.apiPath + 'paye/CountSumfacturesByChantier', {params: params, headers: headers });
    return facture;
  }

  getCountSumDespensesByChantier(idChantier,fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('startDate', fromDate);
    params = params.append('endDate', toDate);

    const despense = this.httpClient.get(environment.baseURL + environment.apiPath + 'paye/CountSumDespensesByChantier', {params: params, headers: headers });
    return despense;
  }

  getMasseSalarialesUser(idChantier,fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('startDate', fromDate);
    params = params.append('endDate', toDate);

    const listMasse = this.httpClient.get(environment.baseURL + environment.apiPath + 'paye/masseSalarialesByUser', {params: params, headers: headers });
    return listMasse;
  }

  getFacturesByChantierAandDate(idChantier,fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);

    const listMasse = this.httpClient.get(environment.baseURL + environment.apiPath + 'factures/getFacturesValidByChantierAndDate', {params: params, headers: headers });
    return listMasse;
  }

 
}
