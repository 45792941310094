<div class="content fs-6 d-flex justify-content-center full-height">
  <div class="col-xl-8">
    <!--begin::List Widget 3-->
    <div class="card card-xl-stretch mb-xl-8">
      <!--begin::Header-->



      <div class="card-body pt-5">

        <p-toolbar styleClass="p-mb-4">
          <ng-template pTemplate="left">
            <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur">
              <i class="pi pi-arrow-circle-left" ></i>
              Retour
            </button>
          </ng-template>

          <ng-template pTemplate="right">
            <button pButton pRipple type="button" label="Ajouter mon pointage" icon="pi pi-check-circle"
            class="p-button-raised p-button-text ml-1"
            [routerLink]="['/dashboard/monPointage']"></button>
            
            <button pButton pRipple type="button"label="Consulter pointages ouvriers " icon="pi pi-calendar"
            tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
             class="p-button-raised p-button-text" routerLink="/dashboard/conducteur/planing/calendrier"></button>
             <button pButton pRipple type="button"label="Consulter pointages chantiers" icon="pi pi-calendar"
             class="p-button-raised p-button-text" routerLink="/dashboard/conducteur/planing/calendrierChantier"></button>
          </ng-template>
      </p-toolbar>


        <p-table #dt [value]="chantiers" [rows]="4" [paginator]="true" [showCurrentPageReport]="true"
        responsiveLayout="scroll"  [globalFilterFields]="['nom','client.nom']">

          <ng-template pTemplate="caption" >
            <div class="d-flex justify-content-end ">   <div class="p-d-flex p-ai-center p-jc-between">
              <span class="p-input-icon-left ">
                <i class="pi pi-search"></i>
                <input type="text" class="
                      form-control form-control-sm form-control-solid  ps-10 "
                  (input)="dt.filterGlobal($event.target.value, 'contains')" name="search" value=""
                  placeholder="Chercher" />
              </span>
            </div></div>

          </ng-template>


          <ng-template pTemplate="header">
            <tr class="
              text-start text-gray-400
              fw-boldest
              fs-7
              text-uppercase
            " class="tr-hover">
              <th  pSortableColumn="nom">Chantiers<p-sortIcon field="nom"></p-sortIcon>
              </th>
              <th >Budget</th>
              <th >Progression</th>
              <th >Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-chantier>
            <tr>
              <td >
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50px me-2">
                    <span class="symbol-label">
                      <img alt="" class="w-25px" src="../../../assets/images/aven.svg" />
                    </span>
                  </div>
                  <div class="ps-3">
                    <div  class="
                            text-gray-800
                            fw-boldest
                            fs-5
                            text-hover-primary
                            mb-1
                          ">{{chantier.nom}} </div>
                    <span class="text-gray-400 fw-bold d-block">{{chantier.adresse}}-{{chantier.ville}} </span>
                  </div>
                </div>
              </td>
              <td>
              <a  class="text-muted text-hover-primary font-weight-bold ms-4">{{chantier.chiffre_affaire/250 }} j/h</a>
             </td>
              <td>
                <p-progressBar [value]="chantier.pourcentageHj" [showValue]="true"></p-progressBar>
              </td>
              <td >
                
                  <a [routerLink]="['/dashboard/conducteur/planing/affectChefChantier/',chantier.id]"
                  class="btn btn-light btn-primary mr-5 btn-sm px-5">Affectation</a>

                  <a href="/craft/pages/projects/project.html" class="
                  btn btn-light btn-primary  btn-sm px-5 mr-2
                " [routerLink]="['/dashboard/pointage/',chantier.id]">Pointage</a>

             
              </td>
            </tr>
          </ng-template>

        </p-table>



      </div>
    </div>
  </div>
</div>