<div class="content fs-6 d-flex flex-column flex-column-fluid full-height" id="kt_content">
    <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
  
  
      <div class="p-field p-col-9 p-md-3 ">
        <div class=" mt-15 mb-15">
         
          <p-dropdown [options]="allChantier" optionLabel="nom"  placeholder="
          sélectionner chantier " [itemSize]="31" [filter]="false" class="min-width-360px" [(ngModel)]="ChantierSelect"
          (ngModelChange)="selectAllChantiers()" [ngModelOptions]="{standalone:true}" >
        <ng-template let-option pTemplate="item">
          <span>{{option.nom}} </span>
        </ng-template>
      </p-dropdown>
       </div>
          <div class=" mt-15">
            <p-tag styleClass="mt-20"severity="danger"  value="Absent"></p-tag><br>
            <p-tag styleClass="mt-2" severity="primary" value="Affecté"></p-tag><br>
            <p-tag styleClass="mt-2" severity="success" value="Pointé et Confirmé"></p-tag><br>
            <p-tag styleClass="mt-2" severity="warning" value="Pointé non confirmé"></p-tag>
          </div>
      </div>
  
  
      <div class="container">
        <button type="button" class="btn btn-secondary no-background mb-2" routerLink="/dashboard/conducteur/planing">
          <i class="pi pi-arrow-circle-left"></i>
          Retour
        </button>
  
        <div class="row g-5 gx-xxl-12 mb-xxl-3">
          <div class="col-xxl-12" data-select2-id="select2-data-139-ub4n">
  
  
            <div class="card card-xxl-stretch mb-xl-3 p-10">
              <div class="card-body py-0  ">
                <full-calendar editable=true selectable=true selectMirror=true dayMaxEvents=true
                #calendar [options]="calendarOptions">
  
                </full-calendar>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
