
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './authentication/login/login.component';
import { DashboardComponent } from './chef-chantier/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './components/navbar/navbar.component';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressBarModule} from 'primeng/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AsyncPipe, DatePipe } from '@angular/common';
import {InputSwitchModule} from 'primeng/inputswitch';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConducteurTravauxComponent } from './conducteur-travaux/conducteur-travaux.component';
import { ChantiersComponent } from './conducteur-travaux/chantiers/chantiers.component';
import { ChantierComponent } from './chef-chantier/chantier/chantier.component';
import { AffectChefChantierComponent } from './conducteur-travaux/planning/affect-ouvrier-chefChantier/affect-chef-chantier.component';

//primeNg
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ToastModule } from "primeng/toast";
import { ButtonModule } from "primeng/button";
import { ConfirmationService, MessageService } from "primeng/api";
import {PaginatorModule} from 'primeng/paginator';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DialogModule} from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import {RadioButtonModule} from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {DataViewModule} from 'primeng/dataview';
import { PlanningComponent } from './conducteur-travaux/planning/planning.component';
import { TableDashboardOccupationComponent } from './conducteur-travaux/planning/table-dashboard-occupation/table-dashboard-occupation.component';
import { TacheComponent } from './conducteur-travaux/planning/tache/tache.component';
import {ToolbarModule} from 'primeng/toolbar';
import {BadgeModule} from 'primeng/badge';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TabViewModule} from 'primeng/tabview';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import { MenuModule } from 'primeng/menu';
import {PasswordModule} from 'primeng/password';

//prime calendar
import {CalendarModule} from 'primeng/calendar';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { GanttAllModule } from '@syncfusion/ej2-angular-gantt';
import {ConfirmDialogModule} from 'primeng/confirmdialog';


//fire base
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { MessagingService } from './messaging.service';
import { AffecteOuvrierComponent } from './chef-chantier/affecte-ouvrier/affecte-ouvrier.component';
import { CalendrierPlanningComponent } from './conducteur-travaux/planning/calendrier-planning/calendrier-planning.component';
import { RhComponent } from './rh/rh.component';
import { OuvriersComponent } from './rh/ouvriers/ouvriers.component';
import { VerfiPointageComponent } from './rh/pointage/verfi-pointage/verfi-pointage.component';
import { AddFacturesComponent } from './conducteur-travaux/add-factures/add-factures.component';
import { PaysComponent } from './rh/consommation/pays/pays.component';
import { ConsommationComponent } from './rh/consommation/consommation.component';
import { DepenseComponent } from './rh/consommation/depense/depense.component';
import { FactureComponent } from './rh/facture/facture.component';
import { RemboursementComponent } from './rh/remboursement/remboursement.component';
import { RemboursementFactureComponent } from './rh/facture/remboursement-facture/remboursement-facture.component';

import { ErreurNotFoundComponent } from './components/erreur-not-found/erreur-not-found.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProjetComponent } from './rh/projet/projet.component';
import { CrudChantiersComponent } from './rh/projet/crud-chantiers/crud-chantiers.component';
import { ClientsComponent } from './rh/projet/clients/clients.component';
import { FournisseurComponent } from './rh/projet/fournisseur/fournisseur.component';
import { PointageChefChantierComponent } from './chef-chantier/pointage-chef-chantier-conducteur/pointage-chef-chantier.component';
import { PointageChefConducteurComponent } from './rh/pointage/pointage-chef-conducteur/pointage-chef-conducteur.component';
import { PointageComponent } from './rh/pointage/pointage.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './authentication/forget-password/forget-password.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CalendrierChantierComponent } from './conducteur-travaux/planning/calendrier-chantier/calendrier-chantier.component';
import { TagModule } from 'primeng/tag';


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NavbarComponent,
    ChantiersComponent,
    ClientsComponent,
    OuvriersComponent,
    ConducteurTravauxComponent,
    ChantierComponent,
    PlanningComponent,
    TableDashboardOccupationComponent,
    TacheComponent,
    AffectChefChantierComponent,
    AffecteOuvrierComponent,
    CalendrierPlanningComponent,
    RhComponent,
    VerfiPointageComponent,
    AddFacturesComponent,
    PaysComponent,
    ConsommationComponent,
    DepenseComponent,
    FactureComponent,
    RemboursementComponent,
    RemboursementFactureComponent,
    CrudChantiersComponent,
    ErreurNotFoundComponent,
    ProjetComponent,
    FournisseurComponent,
    PointageChefChantierComponent,
    PointageChefConducteurComponent,
    PointageComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    ProfileComponent,
    CalendrierChantierComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    InputTextModule,
    InputSwitchModule,
    ProgressBarModule,
    ConfirmPopupModule,
    ToastModule,
    ButtonModule,
    MenuModule,
    PaginatorModule,
    ToggleButtonModule,
    DialogModule,
    InputNumberModule,
    RadioButtonModule,
    BrowserAnimationsModule,
    InputTextareaModule,
    DataViewModule,
    CalendarModule,
    FullCalendarModule,
    ToolbarModule,
    BadgeModule,
    PasswordModule,
    SelectButtonModule,
    GanttAllModule,
    ScrollPanelModule,
    OverlayPanelModule,
    TabViewModule,
    MultiSelectModule,
    FileUploadModule,
    ConfirmDialogModule,
    GalleriaModule,
    TabsModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    RxReactiveFormsModule,
    TagModule,
    AngularFireModule.initializeApp(environment.firebase),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [DatePipe,ConfirmationService, MessageService,MessagingService,AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
