
        <div class="container-xxl">
        <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh/facture">
            <i class="pi pi-arrow-circle-left"></i>
            Retour
        </button>
        </div>
        <div class="content fs-6 d-flex justify-content-center full-height">
           
            <div class="col-xl-8">
                <div class="card card-flush mt-6 mt-xl-9">
                    <div class="card-header mt-5">
                        <div class="card-title flex-column">
                            <h2 class="fw-boldest mb-2"> Remboursement Facture le {{
                                remboursement?.date | date: " d MMM, y"
                                }}</h2>
                            <div class="fs-6 fw-bold text-gray-400">
    
                            </div>
                        </div>
    
                        <div class="card-toolbar my-1" data-select2-id="select2-data-217-w6yy">
                            
                            <!--end::Search-->
                        </div>
                        <!--begin::Card toolbar-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-0">
                        <!--begin::Table container-->
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <div id="kt_profile_overview_table_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="table-responsive">
                                  
                                 <table  class="table">

                                
                                            <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase"
                                                class="tr-hover">
                                                <th>
                                                    Utilisateur
                                                </th>
                                                <th>
                                                    Ref Facture
                                                </th>
                                                <th>
                                                    Date Facture
                                                </th>
                                            
                                                <th>
                                                    Montant rembourser
                                                </th>
                                              
                                                <th>
                                                    Validé et Payé
                                                </th>

                                             
                                            </tr>
                                   
                                            <tr >
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="me-5 position-relative">
                                                            <div class="symbol symbol-35px symbol-circle">
                                                                <span
                                                                    class=" symbol-label bg-light-danger text-danger fw-bold">
                                                                    {{remboursement?.user.firstName|uppercase|slice:0:1 }}
                                                                    </span>
                                                            </div>
                                                            <div
                                                                class=" bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100  ms-n1 mt-n1 ">
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center">
                                                            <a 
                                                                class="mb-1 text-gray-800 text-hover-primary"> {{remboursement?.user.firstName}} {{remboursement?.user.lastName}}
                                                            </a>
                                                            <div class="fw-bold fs-6 text-gray-400">
                                                                {{remboursement?.user.email}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{remboursement?.facture.refFacture}}
                                                </td>
                                                <td>
                                                    {{remboursement?.date| date: "dd-MM-yyyy"}}
                                                </td>
                                               
                                              
                                                <td>
                                                    {{remboursement?.montant | currency: 'EUR'}}
                                                </td>
                                                <td>
    
                                                
                                                    <button pButton label="à Valider" icon="pi pi-check"
                                                        *ngIf="!remboursement?.isValid" (click)="validerRemboursement(remboursement?.id)"
                                                        class="p-button-raised p-button-success p-button-text p-mr-2 me-2"></button>
    
                                                        <i class="pi pi-check p-button-succes p-mr-4 me-2 "
                                                        style="font-size: 2rem" *ngIf="remboursement?.payer"></i>
                                                    <button pButton label=" à Payer " icon="pi pi-check"
                                                    *ngIf="remboursement?.isValid && !remboursement?.payer " (click)="payerRemboursement(remboursement?.id)"
                                                        class="p-button-raised p-button-success p-button-text p-mr-2 me-2"></button>
    
                                                </td>
                                            </tr>
                                     
                                        </table>
                                </div>
                            </div>
                            <!--end::Table-->
                        </div>
                        <!--end::Table container-->
                    </div>
                    <!--end::Card body-->
                </div>
            </div>
        </div>




