import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PointageChefService } from 'src/app/chef-chantier/pointage-chef-chantier-conducteur/pointage-chef.service';
import { PointageChefConducteurService } from '../pointage-chef-conducteur.service';

const CONFIRMED="confirmed";
const ABSENT="absent";
const POINTED_CONFIRMED="pointed_confirmed";
const ANNULER_POINTAGE_CONFIRMED="annuler_pointed_confirmed";
const ANNULER_ABSENT="annuler_absent";

@Component({
  selector: 'app-pointage-chef-conducteur',
  templateUrl: './pointage-chef-conducteur.component.html',
  styleUrls: ['./pointage-chef-conducteur.component.css']
})

export class PointageChefConducteurComponent implements OnInit {
  pointed: boolean;
  datePointagechefConducteur = new Date();
  pointages
  addPointageDialog:boolean;
 conducteur:boolean;
 listChefConducteur
 userChefConducteur
 submitted: boolean;
 updatePointageDialog
 entryDateUpdate
 exitDateUpdate
 ouvrierUpdate

  constructor(private pointageChefConducteurService: PointageChefConducteurService, public datepipe: DatePipe,
    private confirmationService: ConfirmationService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getPointageByChefConducteurByDate(this.datePointagechefConducteur);
    this.getChefChantierConducteur()
    const authorities = localStorage.getItem('authorities');
    if(authorities=="CONDUCTEUR_TRAVAUX"){
      this.conducteur=true;
    }else{
      this.conducteur=false;
    }
  }

  getPointageByChefConducteurByDate(date){
    this.pointageChefConducteurService.getPointageChefConducteurByDateInRh(date).subscribe((response: any) => {
      this.pointages = response
      console.log(response);
      
      this.pointages.forEach(element => {
        this.pointageChefConducteurService.findImageUser(element.user.email).subscribe((rep: any) => { 
          console.log(rep);  
          if (rep) {     
            this.addImage(element,rep)
          }
        })
      });
    })
   
    
  }

  addImage(obj, key) {
    obj["image"] = key

  }
  updatePointage(ouvrier){
    this.updatePointageDialog=true
    this.submitted=false;
    this.entryDateUpdate=null;
    this.exitDateUpdate=null;
    if (ouvrier.entryDate!=null) {
      this.entryDateUpdate=new Date( ouvrier.entryDate) 
    }
  if (ouvrier.exitDate!=null) {
    this.exitDateUpdate=new Date( ouvrier.exitDate)
  }
    this.ouvrierUpdate=ouvrier;
  }

  changeDateGetPointage() {
    let Datet = this.datepipe.transform(this.datePointagechefConducteur, "yyyy-MM-dd")
    this.getPointageByChefConducteurByDate(Datet)

  }

  getChefChantierConducteur() {
    this.pointageChefConducteurService.getChefChantierConducteurNoPointedByDate().subscribe((response: any) => {
      this.listChefConducteur = response
    })
  }

  addPointageTochefConducteur(){
    this.addPointageDialog=true

  }

  savePointageChefChantier(){
    console.log(this.userChefConducteur);
    this.submitted=true
    if (this.userChefConducteur==null ) {
      return;
    }


   this.pointageChefConducteurService.savePointageChefChantierInRh(this.userChefConducteur.email).subscribe( (msg) => {
      console.log(msg)
    },
    (error) => console.log(error)
    ,
    () => {
      this.addPointageDialog=false
      this.ngOnInit()

    }
  ); 
  }
  saveUpdatePointage(id){
    this.submitted=true;
    if (this.entryDateUpdate==null||this.exitDateUpdate==null ) {
      return
    }
    if (this.entryDateUpdate>this.exitDateUpdate) {
      this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
      return;
    }

    let status = {
      "entryDate": this.datepipe.transform(this.entryDateUpdate, "yyyy-MM-dd HH:mm"),
      "exitDate": this.datepipe.transform(this.exitDateUpdate, "yyyy-MM-dd HH:mm"),
    }
    this.pointageChefConducteurService.changeStatusInRh(status, id).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => console.log(error)
      ,
      () => {

        this.getPointageByChefConducteurByDate(this.datePointagechefConducteur);
      }
    );


    this.updatePointageDialog=false
  }
 
  changeStatusUser(event: any, ouvrier) {
    
    if (event.target.value == CONFIRMED || event.target.value == POINTED_CONFIRMED) {
      if (ouvrier.entryDate==null||ouvrier.exitDate==null) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Ajouter date entrée/sortie !'});
         return
       }
       if (ouvrier.entryDate>ouvrier.exitDate) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
        return;
      }
       
      var  status = {
        "pointed":true,
        "confirmed":true,
        "absent":false
      }

      this.pointageChefConducteurService.changeStatusInRh(status,ouvrier.id).subscribe(
        (msg) => {
          console.log(msg)
        },
        (error) => console.log(error)
        ,
        () => {
          this.getPointageByChefConducteurByDate(this.datePointagechefConducteur);
        }
      );
    }
    else if (event.target.value == ABSENT) {
      var  status = {
        "pointed":false,
        "confirmed":false,
        "absent":true
      }
      this.pointageChefConducteurService.changeStatusInRh(status,ouvrier.id).subscribe(
        (msg) => {
          console.log(msg)
        },
        (error) => console.log(error)
        ,
        () => {
          this.getPointageByChefConducteurByDate(this.datePointagechefConducteur);
        }
      );
    }
    else if (event.target.value == ANNULER_POINTAGE_CONFIRMED) {
      var  status = {
        "pointed":false,
        "confirmed":false,
        "absent":false
      }
      this.pointageChefConducteurService.changeStatusInRh(status,ouvrier.id).subscribe(
        (msg) => {
          console.log(msg);
        },
        (error) => {
          console.log(error);
        }
        ,
        () => {
          console.log("ok");
          this.getPointageByChefConducteurByDate(this.datePointagechefConducteur);
        }
      );
    }
    else if (event.target.value == ANNULER_ABSENT) {
      var  status = {
        "pointed":false,
        "confirmed":false,
        "absent":false
      }
      this.pointageChefConducteurService.changeStatusInRh(status,ouvrier.id).subscribe(
        (msg) => {
          console.log(msg);
        },
        (error) => console.log(error)
        ,
        () => {
          console.log("ok");
          this.getPointageByChefConducteurByDate(this.datePointagechefConducteur);
        }
      );
    }

  }

  deletePointage(id) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de supprimer le pointage ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.pointageChefConducteurService.deletePointage(id).subscribe(
          (msg) => {
            console.log(msg)
           
          },
          (error) => {
            console.log(error),
            this.messageService.add({severity:'error', summary:'Erreur', detail:'Pointage non supprimé'});
          },
          () => {
            this.getPointageByChefConducteurByDate(this.datePointagechefConducteur);
            this.getChefChantierConducteur();
            this.messageService.add({severity:'info', summary:'Confirmation', detail:'Pointage supprimé'});
          }
        );        
      }
  });  
  }
}
