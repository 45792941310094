<div class="container-fluid content full-height mt-10">
    <!--begin::Row-->
    <div class="row g-xl-8 mx-auto" style="justify-content: center">
      <!--begin::Col-->
      <div class="col-xl-8" style="justify-content: center">
        <!--begin::Stats Widget 5-->
        <div class="card bg-gray-100 card-xl-stretch mb-5 mb-xl-8">
          <!--begin::Body-->
          <div class="card-body p-0 pb-9">

            <!--begin::Info-->
            <div class="
                px-9
                pt-6
                rounded
                h-250px
                w-100
                bgi-no-repeat bgi-size-cover bgi-position-y-top
              " style="background-image: url('../../assets/images/construction.png')">
              <!--begin::Header-->
  
              <!--end::Header-->
              <!--begin::Balance-->
              <div class="d-flex align-items-center align-self-center flex-wrap pt-8">
                <div class="fw-bolder fs-7 text-white flex-grow-1 pe-2">
                  Bonjour, {{role}}
                </div>
              </div>
              <!--begin::Balance-->
            </div>
            <!--end::Info-->
            <!--begin::Items-->
            <div class="mx-9 mt-n20">
              <!--begin::Row-->
              <div class="row g-8 row-cols-1 row-cols-sm-2">
                   <!--begin::Col-->
                <div class="col mt-sm-n2">
                    <div class="bg-body shadow-xs rounded text-center p-7">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-50px mt-1">
                        <i class="pi pi-users" style="font-size: 2rem"></i>
                      </div>
                      <!--end::Symbol-->
                      <a href="#" class="
                          text-gray-800 text-hover-primary
                          fw-boldest
                          fs-5
                          mt-4
                          d-block
                        " [routerLink]="['/dashboard/rh/ouvriers']">ouvriers</a>
                    </div>
                  </div>
                  <!--end::Col-->
                <!--begin::Col-->
                <div class="col mt-n2">
                  <div class="text-center bg-body shadow-xs rounded p-7">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-50px mt-1">
                      <i class="pi pi-briefcase" style="font-size: 2rem"></i>
                    </div>
                    <!--end::Symbol-->
                    <a [routerLink]="['/dashboard/rh/projet']" class="
                        text-gray-800 text-hover-primary
                        fw-boldest
                        fs-5
                        mt-4
                        d-block
                      ">Projet</a>
                  </div>
                </div>
                <!--end::Col-->
             
               
                <!--begin::Col-->
                <div class="col">
                    <div class="bg-body shadow-xs rounded text-center p-7">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-50px mt-1">
                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path opacity="0.3"
                                d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                fill="black"></path>
                              <path
                                d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                fill="black"></path>
                            </svg>
                          </span>
                      </div>
                      <!--end::Symbol-->
                      <a href="#" class="
                          text-gray-800 text-hover-primary
                          fw-boldest
                          fs-5
                          mt-4
                          d-block
                        " [routerLink]="['/dashboard/rh/pointage']">Pointage</a>
                    </div>
                  </div>
                  <!--end::Col-->

                    <!--begin::Col-->
                 <div class="col">
                  <div class="bg-body shadow-xs rounded text-center p-7">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-50px mt-1">
                    
                      <i class="pi pi-dollar " [ngStyle]="{color:'var(--blue-500)'}" style="font-size: 2rem"></i>
                    </div>
                    <!--end::Symbol-->
                    <a href="#" class="
                        text-gray-800 text-hover-primary
                        fw-boldest
                        fs-5
                        mt-4
                        d-block
                      " [routerLink]="['/dashboard/rh/consomamation']">Consomamation</a>
                  </div>
                </div>
                <!--end::Col-->

                   <!--begin::Col-->
                   <div class="col">
                    <div class="bg-body shadow-xs rounded text-center p-7">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-50px mt-1">
                        <span class="symbol-label bg-light-danger">
                          <!--begin::Svg Icon | path: icons/duotune/communication/com010.svg-->
                          <span class="svg-icon svg-icon-2x svg-icon-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
                                fill="black"></path>
                              <path opacity="0.3"
                                d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
                                fill="black"></path>
                            </svg>
                          </span>
                          <!--end::Svg Icon-->
                        </span>
                      </div>
                      <!--end::Symbol-->
                      <a href="#" class="
                          text-gray-800 text-hover-primary
                          fw-boldest
                          fs-5
                          mt-4
                          d-block
                        " [routerLink]="['/dashboard/rh/facture']">Factures</a>
                    </div>
                  </div>
                  <!--end::Col-->

                      <!--begin::Col-->
                      <div class="col">
                        <div class="bg-body shadow-xs rounded text-center p-7">
                          <!--begin::Symbol-->
                          <div class="symbol symbol-50px mt-1">
                            <i class="pi pi-euro" [ngStyle]="{color:'var(--yellow-500)'}" style="font-size: 2rem"></i>
                          </div>
                          <!--end::Symbol-->
                          <a href="#" class="
                              text-gray-800 text-hover-primary
                              fw-boldest
                              fs-5
                              mt-4
                              d-block
                            " [routerLink]="['/dashboard/rh/remboursement']">Remboursement</a>
                        </div>
                      </div>
                      <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Items-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 5-->
      </div>
      <!--end::Col-->
      <!--begin::Col-->
  
      <!--end::Col-->
      <!--begin::Col-->
  
      <!--begin::Col-->
    </div>
  </div>
