import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FactureService } from '../facture.service';


@Component({
  selector: 'app-remboursement-facture',
  templateUrl: './remboursement-facture.component.html',
  styleUrls: ['./remboursement-facture.component.css']
})
export class RemboursementFactureComponent implements OnInit {
  remboursement
  DateRemboursement= new Date();
  id
  constructor(private factureService: FactureService, private router: ActivatedRoute) { }

  ngOnInit(): void {

    this.id = this.router.snapshot.paramMap.get('id'); 
    this.getRemboursementByFacture(this.id)

  }

  getRemboursementByFacture(idFacture){
    this.factureService.getRemboursementByFacture(idFacture).subscribe((response: any) => {
      this.remboursement = response
    })
    
  }
  validerRemboursement(idRemboursement) {
    this.factureService.validerRemboursement(idRemboursement).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.getRemboursementByFacture(this.id);
      }
    );
  }
  payerRemboursement(idRemboursement) {
    this.factureService.payerRemboursement(idRemboursement).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.getRemboursementByFacture(this.id);
      }
    );
  }

}
