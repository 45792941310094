import { DatePipe } from "@angular/common";
import { ClassGetter } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { LoginComponent } from "src/app/authentication/login/login.component";
import { PlanningService } from "../planning.service";

export interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

@Component({
  selector: "app-tache",
  templateUrl: "./tache.component.html",
  styleUrls: ["./tache.component.css"],
  providers: [MessageService]
})
export class TacheComponent implements OnInit {

  tacheDialog = false;
  tacheForm
  myDates: Date;
  taches: any[];
  users: any[];
  currentDate = new Date();
  chantierName;
  submitted: boolean;

  constructor( private messageService: MessageService,private plannningService: PlanningService, private router: ActivatedRoute, public datepipe: DatePipe) {
  }

  ngOnInit(): void {
    let id = this.router.snapshot.paramMap.get('id');
    this.getTachByChantierAndDate(id);
    this.getChantierById(id);
    this.tacheForm = new FormGroup({
      nom: new FormControl('', [Validators.required]),
      idChantier: new FormControl(id),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      myDates: new FormControl('',[Validators.required]),
    });
  }

  
  getTachByChantierAndDate(id) {
    this.plannningService.getTachByChantierAndDate(id).subscribe((response: any) => {
      this.taches = response
    })
  }
  getChantierById(id){
    this.plannningService.getChantierById(id).subscribe((response: any) => {
        this.chantierName = response.nom;
      
    })
  }

  openNew() {
    this.submitted = false;
    this.tacheDialog = true;
  }

  changePeriodeTache() {
    let startDate = this.datepipe.transform(this.tacheForm.value.myDates[0], "yyyy-MM-dd")
    let endDate = this.datepipe.transform(this.tacheForm.value.myDates[1], "yyyy-MM-dd")
    this.tacheForm.patchValue({ startDate: startDate })
    this.tacheForm.patchValue({ endDate: endDate })
  }
  saveTache() {
    

    this.submitted = true;

    if (this.tacheForm.invalid) {
      return;
    }
    this.plannningService.saveTache(this.tacheForm.value).subscribe(
      (msg) => {
        console.log(msg)

      },
      (error) => {
        console.log(error)
      },
      () => {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Tâche ajouté !'});
        this.ngOnInit();
      }
    );
    this.tacheForm.reset();
    this.tacheDialog = false
   
  }
}
