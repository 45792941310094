<p-tabView class="center-tab">
    <p-tabPanel header="Facture">
        <div class="container-xxl">
            <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur/">
                <i class="pi pi-arrow-circle-left"></i>
                Retour
            </button>
            <div class="row g-xl-8">

                <div class="col-xxl-12 gy-0 gy-xxl-8">
                    <!--begin::Engage Widget 1-->
                    <div class="card card-xxl-stretch mb-5 mb-xl-8">
                        <!--begin::Body-->
                        <div class="card-body">
                            <!--begin::Wrapper-->
                            <div class="d-flex flex-column justify-content-between h-100">
                                <!--begin::Section-->
                                <div class=" d-flex justify-content-center">
                                    <div class="text-center py-2">
                                        <div class="  d-flex align-items-center flex-nowrap  text-nowrap  py-1 ">
                                            <button pButton pRipple label="Ajouter Facture" (click)="openNewFacture()"
                                                icon="pi pi-plus" class="btn btn-primary p-mr-2"></button>
                                        </div>
                                    </div>
                                    <!--end::Action-->
                                </div>
                                <!--end::Section-->
                                <!--begin::Image-->
                                <div  class="flex-grow-1 bgi-no-repeat bgi-size-contain  bgi-position-x-center  bgi-position-y-bottom  card-rounded-bottom
                        max-h-150px  min-h-150px "
                                    style=" background-image: url('../../../../assets/images/fac3.png');background-size: 11%; "></div>
                                <!--end::Image-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Engage Widget 1-->
                </div>

            </div>
        </div>

        <div class="content fs-6 d-flex justify-content-center full-height">
            <div class="col-xl-8">
                <div class="card card-flush mt-6 mt-xl-9">
                    <div class="card-header mt-5">
                        <div class="card-title flex-column">
                            <h2 class="fw-boldest mb-2">Facture</h2>
                            <div class="fs-6 fw-bold text-gray-400">

                            </div>
                        </div>

                        <div class="card-toolbar my-1" data-select2-id="select2-data-217-w6yy">
                            <div class="d-flex align-items-center position-relative my-1">
                                <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                                <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                            transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                        <path
                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                            fill="black"></path>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                                <p-calendar inputId="basic" [disabledDays]="[0,6]" id="kt_filter_search" dateFormat="dd-mm-yy"
                                    [(ngModel)]="dateFacture" (onSelect)="changeDateGetFacture()" [showIcon]="true"
                                    [minDate]="today" placeholder="Selectionner date">
                                </p-calendar>
                            </div>
                            <!--end::Search-->
                        </div>
                        <!--begin::Card toolbar-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-0">
                        <!--begin::Table container-->
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <div id="kt_profile_overview_table_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="table-responsive">
                                    <p-table #dt [value]="listFactures" [rows]="4" [paginator]="true"
                                        [showCurrentPageReport]="true" [globalFilterFields]="['nom','user.firstName']">

                                        <ng-template pTemplate="header">
                                            <tr class="   text-start text-gray-400  fw-boldest
                            fs-7 text-uppercase " class="tr-hover">
                                                <th >
                                                    Réf Facture
                                                </th>
                                                <th >
                                                    Description
                                                </th>
                                                <th >
                                                    Date Facture
                                                </th>

                                                <th >
                                                    Montant
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-facture>
                                            <tr class="even">
                                                <td>

                                                    <div class="d-flex align-items-center">

                                                        <div class="me-5 position-relative">

                                                            <div class="symbol symbol-35px symbol-circle">
                                                                <span class="
                                        symbol-label
                                        bg-light-danger
                                        text-danger
                                        fw-bold
                                      ">{{ facture.refFacture|uppercase|slice:0:1 }}</span>
                                                            </div>

                                                            <div class="
                                      bg-success
                                      position-absolute
                                      h-8px
                                      w-8px
                                      rounded-circle
                                      translate-middle
                                      start-100
                                      top-100
                                      ms-n1
                                      mt-n1
                                    "></div>

                                                        </div>

                                                        <div class="d-flex flex-column justify-content-center">
                                                            <a (click)="getFilesFacture(facture.id)"
                                                                style="cursor: pointer"
                                                                class="mb-1 text-gray-800 text-hover-primary">{{facture.refFacture}}
                                                            </a>
                                                            <div class="fw-bold fs-6 text-gray-400">
                                                                <!-- {{chantierUser.ouvrier.email}} -->
                                                            </div>
                                                        </div>

                                                    </div>

                                                </td>
                                                <td data-order="2021-02-21T00:00:00+01:00">
                                                    {{facture.description}}
                                                </td>
                                                <td data-order="2021-02-21T00:00:00+01:00">
                                                    {{facture.date | date: "dd-MM-yyyy"}}
                                                </td>
                                                <td >
                                                    {{facture.montant}}
                                                </td>
                                            </tr>
                                        </ng-template>

                                    </p-table>



                                </div>
                            </div>
                            <!--end::Table-->
                        </div>
                        <!--end::Table container-->
                    </div>
                    <!--end::Card body-->
                </div>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>


<p-dialog header="Ajouter facture" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
    [(visible)]="addFactureDialog" [style]="{width: '600px'}" [contentStyle]="{height: '600px'}" class="overflow-show">
    <div class="p-grid p-fluid flex-column">
        <div class="p-col-12 p-md-4">
            <form [formGroup]="factureForm">


                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="float-input-invalid"> Référence facture</label>
                        <input type="text" pInputText id="refFacture" required autofocus formControlName="refFacture" />
                        <small class="p-error"
                            *ngIf="submitted && factureForm.controls.refFacture.hasError('required')">La
                            Référence est requise.</small>
                    </div>

                    <div class="col-md-6">
                        <label for="currency-us">Montant TTC</label>
                        <p-inputNumber formControlName="montant" inputId="currency-us" mode="currency" currency="USD"
                            locale="en-US">
                        </p-inputNumber>
                        <small class="p-error" *ngIf="submitted && factureForm.controls.montant.hasError('required')">Le
                            Montant est requis.</small>
                    </div>

                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="float-input-invalid">Date facture</label>
                        <p-calendar formControlName="date" [disabledDays]="[0,6]" placeholder="sélectionner date"
                            dateFormat="dd-mm-yy" class="min-width-360px" [minDate]="today"></p-calendar>
                        <small class="p-error" *ngIf="submitted  && factureForm.controls.date.hasError('required')">
                            Date facture est requise.</small>
                    </div>
                    <div class="col-md-6">
                        <label for="float-input-invalid">Description</label>
                        <input type="text" pInputText id="description" required autofocus
                            formControlName="description" />
                        <small class="p-error"
                            *ngIf="submitted && factureForm.controls.description.hasError('required')">La
                            Description est requise.</small>
                    </div>

                </div>
                <div class="row mt-3">

                    <div class="col-md-10">
                        <p-fileUpload #fileInput name="file" [showUploadButton]="false" maxFileSize="1000000"
                            multiple="multiple" accept="image/*">
                        </p-fileUpload>
                        <small class="p-error" *ngIf="submitted  && this.fileInput.files.length==0">
                            Photo est requise.</small>
                    </div>

                </div>

                <div class="row mt-3">
                    <div class="col-md-6">

                        <label for="float-input-invalid">Chantier</label>
                        <p-dropdown [options]="chantiers" formControlName="chantier"
                            defaultLabel=" sélectionner chantier" placeholder="Sélectionner" optionLabel="nom"
                            class="min-width-360px">
                            <ng-template let-option pTemplate="item">
                                <span>{{option.nom}} </span>
                            </ng-template>
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted  && factureForm.controls.chantier.hasError('required')">
                            Chantier est requis.</small>
                    </div>
                    <div class="col-md-6">

                        <label for="float-input-invalid">Fournisseur</label>
                        <p-dropdown [options]="fournisseurs" formControlName="fournisseur"
                            defaultLabel=" sélectionner fournisseur" placeholder="Sélectionner" optionLabel="nom"
                            class="min-width-360px">
                            <ng-template let-option pTemplate="item">
                                <span>{{option.nom}} </span>
                            </ng-template>
                        </p-dropdown>
                        <small class="p-error"
                            *ngIf="submitted  && factureForm.controls.fournisseur.hasError('required')">
                            Chantier est requis.</small>
                    </div>

                </div>

                <div class="row mt-6">
                    <div class="col-md-4">
                        <label for="float-input-invalid">Remboursement</label>
                        <p-toggleButton [(ngModel)]="rembourser" offIcon="pi pi-times"
                            [ngModelOptions]="{standalone: true}" onLabel="remboursé" offLabel=" non remboursé"
                            [style]="{'width': '12em'}">
                        </p-toggleButton>

                    </div>
                    <div class="col-md-8" *ngIf="rembourser">
                        <label for="float-input-invalid">Ouvrier</label>
                        <p-dropdown [options]="listUser" [(ngModel)]="userRemborser" optionLabel="firstName"
                            [filter]="true" filterBy="firstName,lastName" [showClear]="true"
                            [ngModelOptions]="{standalone: true}" placeholder="Sélectionner l'ouvrier à rembourser">
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value" *ngIf="userRemborser">
                                    <div>{{ userRemborser.firstName }} {{ userRemborser.lastName }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-USER pTemplate="item">
                                <div class="country-item">
                                    <div>{{ USER.firstName }} {{ USER.lastName }}</div>

                                </div>
                            </ng-template>
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted  && this.userRemborser==null">
                            Utilisateur est requis.</small>
                    </div>
                </div>
                <button pButton pRipple label="Sauvegarder facture" *ngIf="!saved" (click)="saveFacture()"
                    icon="pi pi-plus" class="btn btn-primary p-mr-2 mt-5"></button>
                <p-progressBar mode="indeterminate" *ngIf="saved" [style]="{'height': '6px'}"></p-progressBar>
            </form>
        </div>
    </div>
</p-dialog>

<p-dialog header="Image Facture" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
    [(visible)]="dialogImage" [style]="{width: '800px'}" [contentStyle]="{height: '450px'}" class="overflow-show">
    <p-galleria [(value)]="images" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
        [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}" [numVisible]="7"
        [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false"
        [baseZIndex]="100000">
        <ng-template pTemplate="item" let-item>
            <img [src]="item.image" style="width: 400%; display: block;" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.image" style="display: block;" />
            </div>
        </ng-template>
    </p-galleria>
    <div *ngIf="images" class="p-grid" style="max-width: 400px;">
        <div *ngFor="let image of images; let index = index;" class="p-col-3" key="index">
            <img [src]="image.image" [alt]="image.alt" style="cursor: pointer" (click)="imageClick(index)" />
        </div>
    </div>

</p-dialog>