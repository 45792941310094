<div class="container">
  <div class="d-flex justify-content-between">
    <div class=" d-flex flex-column align-items-start justify-content-center flex-wrap me-2 mt-5 mb-10">
      <!--begin::Title-->

      <small class="text-muted fs-6 fw-normal ms-1">
        Pointage le :
        {{datePointagechefConducteur | date: " d MMM, y"}}</small>
    </div>

    <div>
      <p-calendar inputId="basic" [disabledDays]="[0,6]" id="kt_filter_search" [(ngModel)]="datePointagechefConducteur"
        dateFormat="dd-mm-yy" (onSelect)="changeDateGetPointage()" [showIcon]="true" placeholder="Selectionner date">
      </p-calendar>
    </div>
  </div>

  <p-dataView #dv [value]="pointages" [rows]="3" [rowsPerPageOptions]="[5,10,30]" [paginator]="true"
    filterBy='user.firstName'>
    <ng-template pTemplate="header">
      <div>
        <button pButton pRipple type="button" label="Ajouter les Pointages d'aujourd'hui"
          (click)="addPointageTochefConducteur()" icon="pi pi-plus-circle"
          class="p-button-raised p-button-text "></button>
      </div>
    </ng-template>
    <ng-template pTemplate="listItem" let-pointage>
      <div id="kt_project_users_card_pane" class="container-fluid mt-10 ">
        <!--begin::Row-->
        <div class="row g-6 g-xl-9 mb-5">
          <!--begin::Col-->

          <div class="col-md-12 mt-0 col-xxl-12">
            <!--begin::Card-->
            <div class="card">
              <!--begin::Card body-->
              <div class="card-body d-flex ali pt-12 card-style p-9">
                <!--begin::Avatar-->
                <div class="d-flex align-items-center">
                  <div class="me-4 align-items-center">
                    <div class="symbol symbol-65px symbol-circle">
                        <div class="symbol symbol-50px me-5">
                          <img alt="Logo" src="../../../assets/images/avtar.jpg" *ngIf="!pointage?.image">
                          <img [src]="pointage?.image" *ngIf="pointage?.image" alt="logo"  class="card-img rounded-circle" />
                      </div>
                   
                      </div>
                
                  </div>

                  <!--end::Avatar-->
                  <div>
                    <!--begin::Name-->
                    <div class="fs-3 text-gray-800 fw-boldest mb-1">
                      {{ pointage.user.firstName }} {{
                      pointage.user.lastName }}
                    </div>
                    <!--end::Name-->
                    <!--begin::Position-->
                    <div class="fs-5 fw-bold text-gray-400">
                      {{ pointage.user.email }}
                    </div>
                  </div>
                </div>
                <!--end::Position-->
                <!--begin::Info-->
                <div class="d-flex flex-wrap flex-center">
                  <div class="border border-dashed rounded min-w-75px py-3">
                    <div class="fs-6 fw-boldest text-pointage">
                      Journée
                    </div>
                  </div>
                  <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">
                    <label class="fs-7 fw-bold text-gray-400">date d'entrée</label>
                    <div class="fw-bold  date-pointage" *ngIf="!pointage.absent">
                      {{ pointage.entryDate | date: 'HH:mm'}}
                    </div>
                  </div>
                  <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">
                    <label class="fs-7 fw-bold text-gray-400">date de sortie</label>
                    <div class="fw-bold  date-pointage" *ngIf="!pointage.absent">
                      {{ pointage.exitDate | date: 'HH:mm'}}
                    </div>

                  </div>
                  <div  *ngIf="!pointage?.exitDate && !pointage?.entryDate">
                    <button pButton pRipple icon="pi pi-pencil" (click)="updatePointage(pointage)"
                      class=" p-mr-4  me-5 button-trash" *ngIf="!pointage.absent"></button>
                      </div>
                  <!--end::Stats-->
                </div>
                <div class="d-flex flex-wrap flex-center">
                  <div class="  border border-dashed rounded min-w-150px d-flex flex-colmun justify-content-center ">
                    <div>
                      <div class="my-1 d-flex flex-column buttons-group" data-select2-id="select2-data-137-5dg3">
                        <div class="alert alert-danger" *ngIf="pointage.absent">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo5/dist/../src/media/svg/icons/Code/Warning-1-circle.svg--><svg
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                              height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          Absence confirmée
                        </div>
                        <div class="alert alert-success" *ngIf="pointage.confirmed">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo5/dist/../src/media/svg/icons/Communication/Clipboard-check.svg--><svg
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                              height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                  fill="#000000" opacity="0.3" />
                                <path
                                  d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                                  fill="#000000" />
                                <path
                                  d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                  fill="#000000" />
                              </g>
                            </svg>
                          </span>
                          pointage confirmé
                        </div>
                        <button (click)="changeStatusUser($event, pointage)" value="confirmed" *ngIf="
                                                pointage.pointed && !pointage.absent && !pointage.confirmed
                                              " class="btn btn-primary mb-2">
                          Confirmer le pointage
                        </button>
                        <button (click)="changeStatusUser($event, pointage)" value="pointed_confirmed" *ngIf="
                                                !pointage.pointed &&
                                                !pointage.confirmed &&
                                                !pointage.absent
                                              " class="btn btn-secondary mb-2">
                          Cliquer pour pointer
                        </button>
                        <button (click)="changeStatusUser($event, pointage)" value="absent" class="btn btn-danger mb-2"
                          *ngIf="!pointage.absent && !pointage.confirmed">
                          Absent
                        </button>
                        <button (click)="changeStatusUser($event, pointage)" value="annuler_pointed_confirmed"
                          *ngIf="pointage.confirmed" class="btn btn-dark">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                                  fill="#000000" fill-rule="nonzero" />
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          Annuler pointage
                        </button>
                        <button (click)="changeStatusUser($event, pointage)" value="annuler_absent"
                          *ngIf="pointage.absent" class="btn btn-dark mb-2">
                          <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                                  fill="#000000" fill-rule="nonzero" />
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          Annuler l'absence
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::Info-->

                <div class="fs-6 fw-boldest text-gray-700">

                  <button pButton pRipple icon="pi pi-trash" (click)="deletePointage(pointage.id)"
                    class="p-button-warning button-trash"></button>
                </div>
                <div  *ngIf="pointage?.exitDate && pointage?.entryDate">
                <button pButton pRipple icon="pi pi-pencil" (click)="updatePointage(pointage)"
                  class=" p-mr-4  me-5 button-trash" *ngIf="!pointage.absent"></button>
                  </div>
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>

          <!--end::Col-->
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>

<p-dialog header="Ajouter pointages chef chantier et conducteur" [(visible)]="addPointageDialog" [modal]="true"
  [style]="{width: '500px' ,height:'250'}" styleClass="p-fluid " class="overflow-show">



  <p-dropdown [options]="listChefConducteur" [(ngModel)]="userChefConducteur" optionLabel="firstName" [filter]="true"
    filterBy="firstName,lastName" [showClear]="true" [ngModelOptions]="{standalone: true}"
    placeholder="Sélectionner l'Utilisateur">
    <ng-template pTemplate="selectedItem">
      <div class="country-item country-item-value" *ngIf="userChefConducteur">
        <div>{{ userChefConducteur.firstName }} {{ userChefConducteur.lastName }}</div>
      </div>
    </ng-template>
    <ng-template let-USER pTemplate="item">
      <div class="country-item">
        <div>{{ USER.firstName }} {{ USER.lastName }}</div>

      </div>
    </ng-template>
  </p-dropdown>
  <small class="p-error" *ngIf="submitted  && this.userChefConducteur==null">
    Utilisateur est requis.</small>
  <button pButton pRipple label="Sauvegarder pointage" (click)="savePointageChefChantier()" icon="pi pi-plus"
    class="btn btn-primary p-mr-2 mt-15"></button>


</p-dialog>



<p-dialog [(visible)]="updatePointageDialog" header="Modification pointage" [modal]="true" styleClass="p-fluid "
  class="overflow-show">
  <div id="kt_project_users_card_pane" class="container-fluid mt-10 ">
    <!--begin::Row-->
    <div class="row g-6 g-xl-9 mb-5">
      <!--begin::Col-->
      <div class="col-md-12 mt-0 col-xxl-12">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card body-->
          <div class="card-body d-flex ali pt-12 card-style p-9">
            <!--begin::Avatar-->
            <div class="d-flex align-items-center">
              <div class="me-4 align-items-center">
                <div class="symbol symbol-65px symbol-circle">
                  <div class="symbol symbol-50px me-5">
                    <img alt="Logo" src="../../../assets/images/avtar.jpg" *ngIf="!ouvrierUpdate?.image">
                    <img [src]="ouvrierUpdate?.image" *ngIf="ouvrierUpdate?.image" alt="logo"  class="card-img rounded-circle" />
                </div>
                </div>
              </div>

              <!--end::Avatar-->
              <div>
                <!--begin::Name-->
                <div class="fs-3 text-gray-800 fw-boldest mb-1">
                  {{ ouvrierUpdate?.user?.firstName }} {{ ouvrierUpdate?.user?.lastName }}
                </div>
                <!--end::Name-->
                <!--begin::Position-->
                <div class="fs-5 fw-bold text-gray-400">
                  {{ ouvrierUpdate?.user?.email }}
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap flex-center">
              <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">
                <div class="fw-bold text-gray-400">
                  <label for="time">date d'entrée</label>
                  <p-calendar class="p-inputtext-sm" [(ngModel)]="entryDateUpdate" [timeOnly]="true" inputId="timeonly"
                    [style]="{'width':'50%'}"></p-calendar>
                </div>
                <small class="p-error" *ngIf="submitted && entryDateUpdate==null">
                  La date d'entrée est requise.</small>

              </div>
              <div class="border border-dashedrounded min-w-75px py-3 px-4 mx-2">
                <div class="fw-bold text-gray-400">
                  <label for="time">date de sortie</label>
                  <p-calendar class="p-inputtext-sm" [(ngModel)]="exitDateUpdate" [style]="{'width':'50%'}"
                    [timeOnly]="true" inputId="timeonly"></p-calendar>
                </div>
                <small class="p-error" *ngIf="submitted && exitDateUpdate==null">
                  La date de sortie est requise.</small>
              </div>
            </div>
            <div class="d-flex flex-wrap flex-center">
              <div class="border border-dashed rounded min-w-150px d-flex flex-colmun justify-content-center">
                <div>
                  <button pButton pRipple label="Modifier" (click)="saveUpdatePointage(ouvrierUpdate.id)"
                    icon="pi pi-plus" class="btn btn-primary p-mr-2 mt-5"></button>
                  <!--end::Stats-->
                </div>
                <!--end::Info-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
        </div>
      </div>
    </div>
  </div>
</p-dialog>