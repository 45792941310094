import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ClientService } from './client.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ClientsComponent implements OnInit {
  clients: any[];
  clientDialog: boolean;
  submitted: boolean;
  clientForm: FormGroup;
  showAddButton: boolean = true;

  constructor(private clientService: ClientService, private messageService: MessageService
              , private confirmationService: ConfirmationService) { }


              
  ngOnInit(): void {
    this.clientForm = new FormGroup({
      id: new FormControl(),
      nom: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      numTel: new FormControl('',  [Validators.required,Validators.pattern('[- +()0-9]+')]),
      adresse: new FormControl('', [Validators.required]),
      codePostal: new FormControl('', [Validators.required]),
      ville: new FormControl('', [Validators.required]),
    })

    this.getAllClients();
  }
  getAllClients() {
    this.clientService.getAllClient().subscribe((response: any) => {
      this.clients = response
    })
  }

  openNew() {
    this.submitted = false;
    this.clientDialog = true;
    this.clientForm.reset();
    this.showAddButton = true;
  }

  editClient(client) {
    this.showAddButton = false;
    this.clientForm.patchValue(client)
    this.clientDialog = true;
  }

  hideDialog() {
    this.clientDialog = false;
    this.submitted = false;
  }


  saveClient() {
    this.submitted = true;
    if (this.clientForm.invalid) {
      return;
    }
    this.clientService.addClient(this.clientForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Client Créé', life: 3000 });

      },
      (error) => {
        console.log(error)
        this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Client Non Créé', life: 3000 })
      },
      () => {

        this.getAllClients();
      }
    );
    this.clientForm.reset();
    this.clientDialog = false;

  }

  deleteClient(client) {
  this.confirmationService.confirm({
    target: event.target,
      message: 'Etes-vous sûr que vous voulez supprimer ' + client.nom + '?',
      header: 'Confirmer',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.clientService.deleteClient(client.id).subscribe(
          (msg) => {
            console.log(msg)
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Client Supprimé', life: 3000 });
          },
          (error) => {
            console.log(error),
              this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Client Non Supprimé', life: 3000 })

          },
          () => {
            this.getAllClients();
          }
        );
      }
    });
  }
 
  saveUpdate() {
    this.submitted = true;
    this.clientDialog = false;
    this.showAddButton = true;

    if (this.clientForm.invalid) {
      return;
    }
    this.clientService.updateClient(this.clientForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Client modifié', life: 3000 });

      },
      (error) => {
        console.log(error),
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Client non modifié', life: 3000 })
      },
      () => {
        this.getAllClients();
      }
    );

  }
}
