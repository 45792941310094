import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ChantierConducteurService } from 'src/app/conducteur-travaux/chantiers/chantier-conducteur.service';



@Component({
  selector: 'app-crud-chantiers',
  templateUrl: './crud-chantiers.component.html',
  styleUrls: ['./crud-chantiers.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class CrudChantiersComponent implements OnInit {
  chantiers: any[];
  clients: any[];
  adresse: any[];
  chantierDialog: boolean;
  submitted: boolean;
  chantierForm: FormGroup;
  showAddButton: boolean = true;
  
    constructor(private chantierConducteurService:ChantierConducteurService, private messageService: MessageService
      , private confirmationService: ConfirmationService) { }
  
    ngOnInit(): void {
      this.chantierForm = new FormGroup({
        id: new FormControl(),
        nom: new FormControl('', [Validators.required]),
        project_desc: new FormControl('', [Validators.required]),
        client: new FormControl('', [Validators.required]),
        adresse: new FormControl('', [Validators.required]),
        codePostal: new FormControl('', [Validators.required]),
        ville: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required,Validators.email]),
        numTel: new FormControl('', [Validators.required,Validators.pattern('[- +()0-9]+')]),
        chiffre_affaire: new FormControl('', [Validators.required]),
        startDate: new FormControl('', [Validators.required]),
        endDate: new FormControl('', [Validators.required]),
        latitude: new FormControl('', [Validators.required]),
         longitude: new FormControl('', [Validators.required])
      })
  
      this.getAllClients()
      this.getAllChantier()
    }
    getAllChantier() {
      this.chantierConducteurService.getAllChantier().subscribe((response: any) => {
        this.chantiers = response
      })
    }
  
    getAllClients() {
      this.chantierConducteurService.getAllClient().subscribe((response: any) => {
        this.clients = response 
      })
    }
    openNew() {
      this.submitted = false;
      this.chantierDialog = true;
      this.chantierForm.reset();
      this.showAddButton = true;
    }
  
    editChantier(chantier) {
      this.showAddButton = false;
  //    this.chantierForm.patchValue(chantier)
      const startDate = new Date(chantier.startDate)
      const endDate = new Date(chantier.endDate)

      this.chantierForm.controls.startDate.patchValue(startDate)
      this.chantierForm.controls.endDate.patchValue(endDate)

      this.chantierForm.controls.id.patchValue(chantier.id)
      this.chantierForm.controls.nom.patchValue(chantier.nom)
      this.chantierForm.controls.project_desc.patchValue(chantier.project_desc)
      this.chantierForm.controls.client.patchValue(chantier.client)
      this.chantierForm.controls.adresse.patchValue(chantier.adresse)
      this.chantierForm.controls.codePostal.patchValue(chantier.codePostal)
      this.chantierForm.controls.ville.patchValue(chantier.ville)
      this.chantierForm.controls.email.patchValue(chantier.email)
      this.chantierForm.controls.numTel.patchValue(chantier.numTel)
      this.chantierForm.controls.latitude.patchValue(chantier.latitude)
      this.chantierForm.controls.longitude.patchValue(chantier.longitude)
      this.chantierForm.controls.chiffre_affaire.patchValue(chantier.chiffre_affaire)

      this.chantierDialog = true;
    }
  
  
    hideDialog() {
      this.chantierDialog = false;
      this.submitted = false;
    }
    saveChantier() {
    
     this.submitted = true;
     if (this.chantierForm.invalid) {
       return;
     }
     
     this.chantierConducteurService.addChantier(this.chantierForm.value).subscribe(
       (msg) => {
         console.log(msg)
  
       },
       (error) => {
         console.log(error)
             },
       () => {
  
         this.getAllChantier();
       }
     );
     this.chantierForm.reset();
     this.chantierDialog = false;
  }
  saveUpdate() {
    this.chantierDialog = false;
    this.showAddButton = true;
    this.submitted = true;
     if (this.chantierForm.invalid) {
       return;
     }
     
    this.chantierConducteurService.updateChantier(this.chantierForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Chantier modifié', life: 3000 });
  
      },
      (error) => {
        console.log(error),
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Chantier non modifié', life: 3000 })
      },
      () => {
        this.getAllChantier();
      }
    );
  
  }
  
  
  deleteChantier(chantier) {
    this.confirmationService.confirm({
      target: event.target,
        message: 'Etes-vous sûr que vous voulez supprimer ' + chantier.nom + '?',
        header: 'Confirmer',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
  
          this.chantierConducteurService.deleteChantier(chantier.id).subscribe(
            (msg) => {
              console.log(msg)
              this.messageService.add({ severity: 'success', summary: 'succès', detail: 'chantier supprimé', life: 3000 });
            },
            (error) => {
              console.log(error),
                this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'chantier non supprimé', life: 3000 })
  
            },
            () => {
              this.getAllChantier();
            }
          );
        }
      });
    }
}
