import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conducteur-travaux',
  templateUrl: './conducteur-travaux.component.html',
  styleUrls: ['./conducteur-travaux.component.css']
})
export class ConducteurTravauxComponent implements OnInit {
role
  constructor() { }

  
  ngOnInit(): void {
    this.role = localStorage.getItem('authorities');
  }

}
