import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { RemboursementService } from './remboursement.service';


@Component({
  selector: 'app-remboursement',
  templateUrl: './remboursement.component.html',
  styleUrls: ['./remboursement.component.css']
})
export class RemboursementComponent implements OnInit {
  listRemboursement
  DateRemboursement= new Date();
  updateRemboursementDialog= false;
  listUser
  userRemborser
  idRemborsemnt
  submitted: boolean;
  constructor(private remboursementService: RemboursementService, public datepipe: DatePipe, private messageService: MessageService
    , private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.getRemboursementByDate(this.datepipe.transform(this.DateRemboursement, "yyyy-MM-dd"), this.datepipe.transform(this.DateRemboursement, "yyyy-MM-dd"))
    this. getAllUser();
  }

  //Remboursement
  changeDateGetRemboursement() {
    let DateRemboursement = this.datepipe.transform(this.DateRemboursement, "yyyy-MM-dd")
    this.getRemboursementByDate(DateRemboursement, DateRemboursement)
  }

  getRemboursementByDate(startDate, endDate){
    this.remboursementService.getRemboursementsByDate(startDate, endDate).subscribe((response: any) => {
      this.listRemboursement = response
    })
  }

  getAllUser() {
    this.remboursementService.getAllUser().subscribe((response: any) => {
      this.listUser = response
    })
  }


  validerRemboursement(idRemboursement) {
    this.remboursementService.validerRemboursement(idRemboursement).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.getRemboursementByDate(this.datepipe.transform(this.DateRemboursement, "yyyy-MM-dd"), this.datepipe.transform(this.DateRemboursement, "yyyy-MM-dd"))
      }
    );
  }
  payerRemboursement(idRemboursement) {
    this.remboursementService.payerRemboursement(idRemboursement).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.getRemboursementByDate(this.datepipe.transform(this.DateRemboursement, "yyyy-MM-dd"), this.datepipe.transform(this.DateRemboursement, "yyyy-MM-dd"))
      }
    );
  }
  deleteRemboursement(remboursement){
 this.confirmationService.confirm({
      target: event.target,
      message: 'Etes-vous sûr que vous voulez supprimer remboursement de facture ' + remboursement.facture.refFacture + '?',
      header: 'Confirmer',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
           this.remboursementService.deleteRemboursement(remboursement.id).subscribe(
          (msg) => {
            console.log(msg)
            this.messageService.add({ severity: 'success', summary: 'succès', detail: ' Remboursement facture Supprimé', life: 3000 });
          },
          (error) => {
            console.log(error),
              this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Remboursement facture Non Supprimé', life: 3000 })

          },
          () => {
            this. ngOnInit()
          }
        );
      }
    });

  }

  updateRemboursement(remboursement){
    this.userRemborser=remboursement.user
    this.idRemborsemnt=remboursement.id;
    this.updateRemboursementDialog = true;
  }

  saveupdateRemboursement(){
    this.submitted=true;
    if (this.userRemborser==null ) {
      return;
    }

    this.remboursementService.updateRemboursement(this.idRemborsemnt,this.userRemborser.id).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'succès', detail: ' Remboursement facture modifié', life: 3000 });
      },
      (error) => {
        console.log(error),
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Remboursement facture Non modifié', life: 3000 })

      },
      () => {
        this. ngOnInit()
      }
    );
    this.updateRemboursementDialog = false;
  }
 
  }

