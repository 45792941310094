import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ChantierConducteurService } from './chantier-conducteur.service';

@Component({
  selector: 'app-chantiers',
  templateUrl: './chantiers.component.html',
  styleUrls: ['./chantiers.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ChantiersComponent implements OnInit {
chantiers: any[];
clients: any[];
adresse: any[];
chantierDialog: boolean;
submitted: boolean;
chantierForm: FormGroup;
showAddButton: boolean = true;

  constructor(private chantierConducteurService:ChantierConducteurService, private messageService: MessageService
    , private confirmationService: ConfirmationService) { }

  ngOnInit(): void {


    this.getAllChantier()
  }
  getAllChantier() {
    this.chantierConducteurService.getAllChantier().subscribe((response: any) => {
      this.chantiers = response
      console.log(response);
      
    })
  }

}
