import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthServiceService } from '../auth-service.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ForgetPasswordComponent implements OnInit {
  loading: boolean = false;
  forgetForm: FormGroup;
  submited: boolean = false;
  constructor(private route:Router,private authServices: AuthServiceService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.forgetForm = new FormGroup({
    email: new FormControl('', [Validators.required,Validators.email])
  } );

  }
  submitforget() {
    this.submited = true;
    if (this.forgetForm.invalid) {
      return;
    }
    console.log(this.forgetForm.controls.email.value);
    this.loading = true;
  
     this.authServices.forget_password(this.forgetForm.controls.email.value).subscribe(
     
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Email envoyer', life: 3000 });

      },
      (error) => {
        console.log(error)
        this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Email non Validé', life: 3000 }),
        this.loading = false;
      },
      () => {

        this.route.navigateByUrl("/login");
      }
    );
     
  }
}
