<p-toast position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<div class="content fs-6 d-flex flex-column flex-column-fluid full-height" id="kt_content">
  <div class="toolbar" id="kt_toolbar">

    <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
      <div class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            flex-wrap
            me-2
          ">
        <h1 class="text-dark fw-bolder my-1 fs-2">
          Chantier: {{chantierName}} <br />
          <small class="text-muted fs-6 fw-normal ms-1">{{
            currentDate | date: " d MMM, y"
            }}</small>
        </h1>
      </div>
    </div>

  </div>


  <!--begin::Container-->



  <p-tabView class="center-tab">
    <p-tabPanel header="Affecter ouvrier">
      <div class="container-xxl">
        <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur/planing">
          <i class="pi pi-arrow-circle-left" ></i>
          Retour
        </button>
        <div class="row g-xl-8">
          <!--begin::Col-->

          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-xxl-12 gy-0 gy-xxl-8">
            <!--begin::Engage Widget 1-->
            <div class="card card-xxl-stretch mb-5 mb-xl-8">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Wrapper-->
                <div class="d-flex flex-column justify-content-between h-100">
                  <!--begin::Section-->
                  <div class="mb-10 d-flex justify-content-center">
                    <div class="text-center py-2">
                      <div class=" d-flex  align-items-center  flex-nowrap text-nowrap  py-1  ">
                        <button pButton pRipple label="Affecter Ouvrier" (click)="openNewOuvrier()" icon="pi pi-plus"
                          class="btn btn-primary p-mr-2"></button>
                      </div>
                    </div>
                    <!--end::Action-->
                  </div>
                  <!--end::Section-->
                  <!--begin::Image-->
                  <div class="  flex-grow-1  bgi-no-repeat  bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom
                          max-h-175px min-h-175px"
                    style=" background-image: url('../../../../assets/images/affect.jpg'); "></div>
                  <!--end::Image-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Engage Widget 1-->
          </div>
          <!--end::Col-->
        </div>
      </div>
      <div class="content fs-6 d-flex justify-content-center full-height">
        <div class="col-xl-8">

          <div class="card card-flush mt-6 mt-xl-9">
            <div class="card-header mt-5">
              <div class="card-title flex-column">
                <h3 class="fw-boldest mb-2">Ouvrier affecté à {{ chantierName }} le {{datePointage| date: 'dd-MM-yyyy'}}
                </h3>
                <div class="fs-6 fw-bold text-gray-400">

                </div>
              </div>

              <div class="card-toolbar my-1" data-select2-id="select2-data-217-w6yy">
                <div class="d-flex align-items-center position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="svg-icon svg-icon-3 position-absolute ms-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <p-calendar inputId="basic" [disabledDays]="[0,6]" id="kt_filter_search" [(ngModel)]="datePointage"
                  dateFormat="dd-mm-yy" (onSelect)="changeDateGetPointage()" [showIcon]="true" [minDate]="today"
                    placeholder="Selectionner date">
                  </p-calendar>
                </div>
                <!--end::Search-->
              </div>
              <!--begin::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">


              <!--begin::Table container-->
              <div id="kt_project_users_card_pane" class="container mt-10">
                <!--begin::Row-->
                <div class="row g-6 g-xl-9">
                  <div class="col-md-6 col-xxl-4" *ngFor="let ouvrier of ouvrierPointage">
                    <!--begin::Card-->
                    <div class="card">
                      <!--begin::Card body-->
                      <div class="card-body d-flex flex-center flex-column pt-12 p-9">
                        <!--begin::Avatar-->

                        <div class="symbol symbol-65px symbol-circle mb-7">
                          <span class=" symbol-label fs-2x fw-bold text-success bg-light-success
                      ">{{ouvrier.user.firstName|uppercase|slice:0:1 }}</span>
                          <div class=" bg-success position-absolute h-15px w-15px rounded-circle translate-middle start-100
                          top-100 ms-n3 mt-n3 "></div>
                        </div>

                        <!--end::Avatar-->
                        <!--begin::Name-->
                        <a href="#"
                          class="fs-3 text-gray-800 text-hover-primary fw-boldest mb-1">{{ouvrier.user.firstName}}
                          {{ouvrier.user.lastName}}</a>
                        <!--end::Name-->
                        <!--begin::Position-->
                        <div class="fs-5 fw-bold text-gray-400 mb-1">
                          {{ouvrier.user.email}}
                        </div>
                        <!--end::Position-->

                        <!--begin::Info-->
                        <div class="d-flex flex-wrap flex-center">
                          <!--begin::Stats-->
                          <div class=" border border-dashed rounded min-w-75px py-3 px-4 mx-2 mb-3 ">
                            <div class="fw-bold text-gray-400 text-center mb-5">{{ouvrier.duree}}</div>
                            <div class="fs-6 fw-boldest text-gray-700">
                              <button pButton pRipple icon="pi pi-pencil" (click)="openNewUpdateOuvrier(ouvrier)"
                                class=" p-mr-4  me-5 button-edit"></button>
                              <button pButton pRipple icon="pi pi-trash" (click)="deletePointage(ouvrier.id)"
                                class="p-button-warning button-trash"></button>
                            </div>

                          </div>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                  </div>

                </div>
              </div>


              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>


    </p-tabPanel>
    <p-tabPanel header="Affecter chef chantier">
      <div class="container-xxl">
        <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur/planing">
          <i class="pi pi-arrow-circle-left" ></i>
          Retour
        </button>
        <div class="row g-xl-8">

          <div class="col-xxl-12 gy-0 gy-xxl-8">
            <!--begin::Engage Widget 1-->
            <div class="card card-xxl-stretch mb-5 mb-xl-8">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Wrapper-->
                <div class="d-flex flex-column justify-content-between h-100">
                  <!--begin::Section-->
                  <div class="mb-10 d-flex justify-content-center">
                    <div class="text-center py-2">
                      <div class="  d-flex align-items-center flex-nowrap  text-nowrap  py-1 ">
                        <button pButton pRipple label="Affecter chef chantier" (click)="openNewChefChantier()"
                          icon="pi pi-plus" class="btn btn-primary p-mr-2"></button>
                      </div>
                    </div>
                    <!--end::Action-->
                  </div>
                  <!--end::Section-->
                  <!--begin::Image-->
                  <div class="flex-grow-1 bgi-no-repeat bgi-size-contain  bgi-position-x-center  bgi-position-y-bottom  card-rounded-bottom
                          max-h-175px  min-h-175px "
                    style=" background-image: url('../../../../assets/images/chef2.jpg'); "></div>
                  <!--end::Image-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Engage Widget 1-->
          </div>

        </div>
      </div>

      <div class="content fs-6 d-flex justify-content-center full-height">
        <div class="col-xl-8">
          <div class="card card-flush mt-6 mt-xl-9">
            <div class="card-header mt-5">
              <div class="card-title flex-column">
                <h2 class="fw-boldest mb-2">Chef chantier affecté à {{ chantierName }} le {{datePointage| date: 'dd-MM-yyyy'}}</h2>
                <div class="fs-6 fw-bold text-gray-400">

                </div>
              </div>

              <div class="card-toolbar my-1" data-select2-id="select2-data-217-w6yy">
                <div class="d-flex align-items-center position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="svg-icon svg-icon-3 position-absolute ms-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input type="text" id="kt_filter_search"
                    class="  form-control form-control-solid form-select-sm  w-150px ps-9 "
                    placeholder="Chercher" />
                </div>
                <!--end::Search-->
              </div>
              <!--begin::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
              <!--begin::Table container-->
              <div class="table-responsive">
                <!--begin::Table-->
                <div id="kt_profile_overview_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="table-responsive">
                    <p-table #dt [value]="chantierUsers" [rows]="4" [paginator]="true" [showCurrentPageReport]="true"
                      [globalFilterFields]="['nom','user.firstName']">

                      <ng-template pTemplate="header">
                        <tr class="   text-start text-gray-400  fw-boldest
                              fs-7 text-uppercase " class="tr-hover">
                          <th class="min-w-250px sorting" tabindex="0" aria-controls="kt_profile_overview_table"
                            rowspan="1" colspan="1" aria-label="Manager: activate to sort column ascending"
                            style="width: 380.781px">
                            Chef Chantier
                          </th>
                          <th class="min-w-150px sorting" tabindex="0" aria-controls="kt_profile_overview_table"
                            rowspan="1" colspan="1" aria-label="Date: activate to sort column ascending"
                            style="width: 238.622px">
                            Date de début
                          </th>
                          <th class="min-w-150px sorting" tabindex="0" aria-controls="kt_profile_overview_table"
                            rowspan="1" colspan="1" aria-label="Date: activate to sort column ascending"
                            style="width: 238.622px">
                            Date de fin
                          </th>

                          <th class="min-w-50px text-end sorting" tabindex="0" aria-controls="kt_profile_overview_table"
                            rowspan="1" colspan="1" aria-label="Details: activate to sort column ascending"
                            style="width: 91.9318px">
                            Actions
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-chantierUser>
                        <tr class="even">
                          <td>
                            <!--begin::User-->
                            <div class="d-flex align-items-center">
                              <!--begin::Wrapper-->
                              <div class="me-5 position-relative">
                                <!--begin::Avatar-->
                                <div class="symbol symbol-35px symbol-circle">
                                  <span class="
                                          symbol-label
                                          bg-light-danger
                                          text-danger
                                          fw-bold
                                        ">{{ chantierUser.ouvrier.firstName|uppercase|slice:0:1 }}</span>
                                </div>
                                <!--end::Avatar-->
                                <!--begin::Online-->
                                <div class="
                                        bg-success
                                        position-absolute
                                        h-8px
                                        w-8px
                                        rounded-circle
                                        translate-middle
                                        start-100
                                        top-100
                                        ms-n1
                                        mt-n1
                                      "></div>
                                <!--end::Online-->
                              </div>
                              <!--end::Wrapper-->
                              <!--begin::Info-->
                              <div class="d-flex flex-column justify-content-center">
                                <a href=""
                                  class="mb-1 text-gray-800 text-hover-primary">{{chantierUser.ouvrier.firstName}}
                                  {{chantierUser.ouvrier.lastName}}</a>
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{chantierUser.ouvrier.email}}
                                </div>
                              </div>
                              <!--end::Info-->
                            </div>
                            <!--end::User-->
                          </td>
                          <td data-order="2021-02-21T00:00:00+01:00">
                            {{chantierUser.startDate}}
                          </td>
                          <td data-order="2021-02-21T00:00:00+01:00">
                            {{chantierUser.endDate}}
                          </td>
                          <td class="text-end">
                            <!-- <button pButton pRipple icon="pi pi-pencil" class=" p-mr-4  button-edit"></button> -->
                            <button pButton pRipple icon="pi pi-trash" (click)="deleteUserAffecter(chantierUser.id)"
                              class="p-button-warning button-trash"></button>
                          </td>
                        </tr>
                      </ng-template>

                    </p-table>



                  </div>
                </div>
                <!--end::Table-->
              </div>
              <!--end::Table container-->
            </div>
            <!--end::Card body-->
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <!--end::Container-->
</div>
<p-dialog [(visible)]="affectChefDialog" header="Affecter chef Chantier" [modal]="true" styleClass="p-fluid "
  class="overflow-show">
  <div class="p-grid p-fluid flex-column">
    <div class="p-col-12 p-md-4">
      <form [formGroup]="chefChantierForm">

        <div class="p-inputgroup mb-5">

          <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3"
                d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                fill="black" />
              <path
                d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                fill="black" />
              <path
                d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                fill="black" />
            </svg></span>
          <p-calendar formControlName="myDates" [disabledDays]="[0,6]" selectionMode="range" [readonlyInput]="true"
          (onSelect)="changePeriodeAffectChef()"   dateFormat="dd-mm-yy" inputId="range" placeholder="sélectionner date" class="min-width-360px" [minDate]="today"></p-calendar>
          </div>
            <small class="p-error" *ngIf="submitted  && chefChantierForm.controls.startDate.hasError('required')"> La date
              début est
              requis.</small>
            <small class="p-error" *ngIf="submitted  && chefChantierForm.controls.endDate.hasError('required')"> La date fin
              est
              requis.</small>
        <div class="p-inputgroup mb-2">
          <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3"
                d="M20.859 12.596L17.736 13.596L10.388 20.944C10.2915 21.0406 10.1769 21.1172 10.0508 21.1695C9.9247 21.2218 9.78953 21.2486 9.65302 21.2486C9.5165 21.2486 9.3813 21.2218 9.25519 21.1695C9.12907 21.1172 9.01449 21.0406 8.918 20.944L2.29999 14.3229C2.10543 14.1278 1.99619 13.8635 1.99619 13.588C1.99619 13.3124 2.10543 13.0481 2.29999 12.853L11.853 3.29999C11.9495 3.20341 12.0641 3.12679 12.1902 3.07452C12.3163 3.02225 12.4515 2.9953 12.588 2.9953C12.7245 2.9953 12.8597 3.02225 12.9858 3.07452C13.1119 3.12679 13.2265 3.20341 13.323 3.29999L21.199 11.176C21.3036 11.2791 21.3797 11.4075 21.4201 11.5486C21.4605 11.6898 21.4637 11.8391 21.4295 11.9819C21.3953 12.1247 21.3249 12.2562 21.2249 12.3638C21.125 12.4714 20.9989 12.5514 20.859 12.596Z"
                fill="black" />
              <path
                d="M14.8 10.184C14.7447 10.1843 14.6895 10.1796 14.635 10.1699L5.816 8.69997C5.55436 8.65634 5.32077 8.51055 5.16661 8.29469C5.01246 8.07884 4.95035 7.8106 4.99397 7.54897C5.0376 7.28733 5.18339 7.05371 5.39925 6.89955C5.6151 6.7454 5.88334 6.68332 6.14498 6.72694L14.963 8.19692C15.2112 8.23733 15.435 8.36982 15.59 8.56789C15.7449 8.76596 15.8195 9.01502 15.7989 9.26564C15.7784 9.51626 15.6642 9.75001 15.479 9.92018C15.2939 10.0904 15.0514 10.1846 14.8 10.184ZM17 18.6229C17 19.0281 17.0985 19.4272 17.287 19.7859C17.4755 20.1446 17.7484 20.4521 18.0821 20.6819C18.4158 20.9117 18.8004 21.0571 19.2027 21.1052C19.605 21.1534 20.0131 21.103 20.3916 20.9585C20.7702 20.814 21.1079 20.5797 21.3758 20.2757C21.6437 19.9716 21.8336 19.607 21.9293 19.2133C22.025 18.8195 22.0235 18.4085 21.925 18.0154C21.8266 17.6223 21.634 17.259 21.364 16.9569L19.843 15.257C19.7999 15.2085 19.7471 15.1697 19.688 15.1432C19.6289 15.1167 19.5648 15.1029 19.5 15.1029C19.4352 15.1029 19.3711 15.1167 19.312 15.1432C19.2529 15.1697 19.2001 15.2085 19.157 15.257L17.636 16.9569C17.2254 17.4146 16.9988 18.0081 17 18.6229ZM10.388 20.9409L17.736 13.5929H1.99999C1.99921 13.7291 2.02532 13.8643 2.0768 13.9904C2.12828 14.1165 2.2041 14.2311 2.29997 14.3279L8.91399 20.9409C9.01055 21.0381 9.12539 21.1152 9.25188 21.1679C9.37836 21.2205 9.51399 21.2476 9.65099 21.2476C9.78798 21.2476 9.92361 21.2205 10.0501 21.1679C10.1766 21.1152 10.2914 21.0381 10.388 20.9409Z"
                fill="black" />
            </svg></span>

          <p-dropdown [options]="chefChantiers" optionLabel="lastName" formControlName="user" placeholder="
              sélectionner ouvrier " [virtualScroll]="true" [itemSize]="31" [filter]="false" class="min-width-360px">
            <ng-template let-option pTemplate="item">
              <span>{{option.firstName}} {{option.lastName}} </span>
            </ng-template>
          </p-dropdown>
         
        </div>
        <small class="p-error" *ngIf="submitted  && chefChantierForm.controls.user.hasError('required')"> l'utilisateur est
          requis.</small>
        <button pButton pRipple label="Affecter" (click)="saveChef()" icon="pi pi-plus"
          class="btn btn-primary p-mr-2 mt-5"></button>
      </form>
    </div>
  </div>
</p-dialog>


<p-dialog [(visible)]="affectOuvrierDialog" header="Affecter ouvrier" [modal]="true" styleClass="p-fluid "
  class="overflow-show">
  <div class="p-grid p-fluid flex-column">
    <div class="p-col-12 p-md-4">
      <form [formGroup]="ouvrierForm">

        <div class="p-inputgroup mb-2">

          <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3"
                d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                fill="black" />
              <path
                d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                fill="black" />
              <path
                d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                fill="black" />
            </svg></span>
          <p-calendar formControlName="myDates" [disabledDays]="[0,6]" selectionMode="range"
          (onSelect)="changePeriodePointage()"  [readonlyInput]="true" inputId="range" placeholder="sélectionner date" class="min-width-360px" 
          dateFormat="dd-mm-yy"  [minDate]="today"></p-calendar>
        </div>
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.startDate.hasError('required')"> La date
          début est
          requis.</small>
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.endDate.hasError('required')"> La date fin
          est
          requis.</small>

        <div class="p-inputgroup mb-2">
          <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3"
                d="M20.859 12.596L17.736 13.596L10.388 20.944C10.2915 21.0406 10.1769 21.1172 10.0508 21.1695C9.9247 21.2218 9.78953 21.2486 9.65302 21.2486C9.5165 21.2486 9.3813 21.2218 9.25519 21.1695C9.12907 21.1172 9.01449 21.0406 8.918 20.944L2.29999 14.3229C2.10543 14.1278 1.99619 13.8635 1.99619 13.588C1.99619 13.3124 2.10543 13.0481 2.29999 12.853L11.853 3.29999C11.9495 3.20341 12.0641 3.12679 12.1902 3.07452C12.3163 3.02225 12.4515 2.9953 12.588 2.9953C12.7245 2.9953 12.8597 3.02225 12.9858 3.07452C13.1119 3.12679 13.2265 3.20341 13.323 3.29999L21.199 11.176C21.3036 11.2791 21.3797 11.4075 21.4201 11.5486C21.4605 11.6898 21.4637 11.8391 21.4295 11.9819C21.3953 12.1247 21.3249 12.2562 21.2249 12.3638C21.125 12.4714 20.9989 12.5514 20.859 12.596Z"
                fill="black" />
              <path
                d="M14.8 10.184C14.7447 10.1843 14.6895 10.1796 14.635 10.1699L5.816 8.69997C5.55436 8.65634 5.32077 8.51055 5.16661 8.29469C5.01246 8.07884 4.95035 7.8106 4.99397 7.54897C5.0376 7.28733 5.18339 7.05371 5.39925 6.89955C5.6151 6.7454 5.88334 6.68332 6.14498 6.72694L14.963 8.19692C15.2112 8.23733 15.435 8.36982 15.59 8.56789C15.7449 8.76596 15.8195 9.01502 15.7989 9.26564C15.7784 9.51626 15.6642 9.75001 15.479 9.92018C15.2939 10.0904 15.0514 10.1846 14.8 10.184ZM17 18.6229C17 19.0281 17.0985 19.4272 17.287 19.7859C17.4755 20.1446 17.7484 20.4521 18.0821 20.6819C18.4158 20.9117 18.8004 21.0571 19.2027 21.1052C19.605 21.1534 20.0131 21.103 20.3916 20.9585C20.7702 20.814 21.1079 20.5797 21.3758 20.2757C21.6437 19.9716 21.8336 19.607 21.9293 19.2133C22.025 18.8195 22.0235 18.4085 21.925 18.0154C21.8266 17.6223 21.634 17.259 21.364 16.9569L19.843 15.257C19.7999 15.2085 19.7471 15.1697 19.688 15.1432C19.6289 15.1167 19.5648 15.1029 19.5 15.1029C19.4352 15.1029 19.3711 15.1167 19.312 15.1432C19.2529 15.1697 19.2001 15.2085 19.157 15.257L17.636 16.9569C17.2254 17.4146 16.9988 18.0081 17 18.6229ZM10.388 20.9409L17.736 13.5929H1.99999C1.99921 13.7291 2.02532 13.8643 2.0768 13.9904C2.12828 14.1165 2.2041 14.2311 2.29997 14.3279L8.91399 20.9409C9.01055 21.0381 9.12539 21.1152 9.25188 21.1679C9.37836 21.2205 9.51399 21.2476 9.65099 21.2476C9.78798 21.2476 9.92361 21.2205 10.0501 21.1679C10.1766 21.1152 10.2914 21.0381 10.388 20.9409Z"
                fill="black" />
            </svg></span>
          <p-multiSelect [options]="ouvriers" formControlName="user" defaultLabel=" sélectionner ouvrier"
            optionLabel="lastName" class="min-width-360px">
            <ng-template let-option pTemplate="item">
              <span>{{option.firstName}} {{option.lastName}} </span>
            </ng-template>
          </p-multiSelect>
        </div>
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.user.hasError('required')"> l'utilisateur est
          obligatoire.</small>
    
        <button pButton pRipple label="Affecter ouvrier" (click)="saveOuvrier()" icon="pi pi-plus"
          class="btn btn-primary p-mr-2 mt-5"></button>
      </form>
    </div>
  </div>
</p-dialog>

<p-dialog header="Modifier Pointage" [(visible)]="UpdateOuvrierDialog" [modal]="true"
  [style]="{width: '500px' ,height:'250'}" styleClass="p-fluid " class="overflow-show">
  <p-tabView>
    <p-tabPanel header="Déplacer ouvrier ">
      <form [formGroup]="updateChantierPointageForm">
        <div class="p-inputgroup mb-5">
        </div>
        <div class="p-inputgroup mb-2">

          <p-dropdown [options]="allChantier" optionLabel="nom" formControlName="NewChantier" placeholder="
              sélectionner nouveau chantier " [itemSize]="31" [filter]="false" class="min-width-360px">
            <ng-template let-option pTemplate="item">
              <span>{{option.nom}} </span>
            </ng-template>
          </p-dropdown>
         
        </div>
        <small class="p-error"
        *ngIf="submitted  && updateChantierPointageForm.controls.NewChantier.hasError('required')"> Chantier est
        requis.</small>

        <button pButton pRipple label="Sauvegarder" (click)="saveUpdateDeplace()" icon="pi pi-plus"
          class="btn btn-primary p-mr-2 mt-5"></button>
      </form>
    </p-tabPanel>
    <p-tabPanel header="Diviser pointage" *ngIf="divisie">
      <form [formGroup]="devisePointageForm">

        <div class="p-inputgroup mb-2">
          <p-dropdown [options]="allChantier" optionLabel="nom" formControlName="idChantierMatin" placeholder="
          sélectionner chantier matin" [itemSize]="31" [filter]="false" class="min-width-360px">
            <ng-template let-option pTemplate="item">
              <span>{{option.nom}} </span>
            </ng-template>
          </p-dropdown>
          
        </div>
        <small class="p-error" *ngIf="submitted  && devisePointageForm.controls.idChantierMatin.hasError('required')">
          Chantier matin est
          requis.</small>
        <div class="p-inputgroup mb-2">
          <p-dropdown [options]="allChantier" optionLabel="nom" formControlName="idChantierSoir" placeholder="
              sélectionner chantier soir" [itemSize]="31" [filter]="false" class="min-width-360px">
            <ng-template let-option pTemplate="item">
              <span>{{option.nom}} </span>
            </ng-template>
          </p-dropdown>
         
        </div>
        <small class="p-error" *ngIf="submitted  && devisePointageForm.controls.idChantierSoir.hasError('required')">
          Chantier soir est
          requis.</small>

        <button pButton pRipple label="Sauvegarder" (click)="saveDivisierPointage()" icon="pi pi-plus"
          class="btn btn-primary p-mr-2 mt-5"></button>
      </form>
    </p-tabPanel>

  </p-tabView>


</p-dialog>