import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ChantierService } from '../chantier/chantier.service';
import { PointageChefService } from './pointage-chef.service';

@Component({
  selector: 'app-pointage-chef-chantier',
  templateUrl: './pointage-chef-chantier.component.html',
  styleUrls: ['./pointage-chef-chantier.component.css']
})
export class PointageChefChantierComponent implements OnInit {
  pointed: boolean;
  currentDate = new Date();
  pointage
 showAjoutPointage
 conducteur:boolean;
 submitted: boolean;
 updatePointageDialog
 entryDateUpdate
 exitDateUpdate
 ouvrierUpdate

  constructor(private pointageChefService: PointageChefService, private messageService: MessageService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    
    this.getPointageByChefConducteurByDateAndEmail();
    const authorities = localStorage.getItem('authorities');
    if(authorities=="CONDUCTEUR_TRAVAUX"){
      this.conducteur=true;
    }else{
      this.conducteur=false;
    }
  }

  getPointageByChefConducteurByDateAndEmail(){
    this.pointageChefService.getPointageByChefConducteurByDateAndEmail().subscribe( (response) => {
     this.pointage =  response
     this.showAjoutPointage=false
     
   
      this.pointageChefService.findImageUser(this.pointage.user.email).subscribe((rep: any) => {  
        if (rep) {     
          this.addImage(this.pointage,rep)
        }
   
    });

    },
    (error) => {
      this.showAjoutPointage=true
    } ,
    () => {   
    }
  )
  }

  addImage(obj, key) {
    obj["image"] = key
  }

  saveUpdatePointage(pointage){
    this.submitted=true;
    if (this.pointage.entryDate==null&&this.entryDateUpdate==null) {
      this.messageService.add({severity:'error', summary:'Erreur', detail:'add Date de entrée'});
      return
    }
    if (this.entryDateUpdate!=null&&this.exitDateUpdate!=null ) {
      if (this.entryDateUpdate>this.exitDateUpdate) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
        return;
      }
    }else
    if (this.entryDateUpdate==null&&this.exitDateUpdate!=null ) {
      if (this.pointage.entryDate>this.exitDateUpdate) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
        return;
      }
    }
    let status = {
      "entryDate": this.datepipe.transform(this.entryDateUpdate, "yyyy-MM-dd HH:mm"),
      "exitDate": this.datepipe.transform(this.exitDateUpdate, "yyyy-MM-dd HH:mm"),
    }
    this.pointageChefService.changeStatus(status, pointage.id).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => console.log(error)
      ,
      () => {

        this.getPointageByChefConducteurByDateAndEmail();
      }
    );


    this.updatePointageDialog=false
  }

  updatePointage(ouvrier){
    this.updatePointageDialog=true
    if (ouvrier.entryDate!=null) {
      this.entryDateUpdate=new Date( ouvrier.entryDate) 
    }
  if (ouvrier.exitDate!=null) {
    this.exitDateUpdate=new Date( ouvrier.exitDate)
  }
    this.ouvrierUpdate=ouvrier;
  }

  savePointageChefChantier(){
    
   this.pointageChefService.savePointageChefChantier().subscribe( (msg) => {
      console.log(msg)
    },
    (error) => console.log(error)
    ,
    () => {
      this.showAjoutPointage=false;
      this.ngOnInit()
    }
  ); 
  }

  changeStatusUser(event: any, ouvrier) {
    this.submitted=true



    if (ouvrier.entryDate==null||ouvrier.exitDate==null) {
      this.messageService.add({severity:'error', summary:'Erreur', detail:'Ajouter date entrée/sortie !'});
       return
     }

    if (event.target.value == "pointed") {
      var  status = {
        "pointed":true
      }
      this.pointageChefService.changeStatus(status,ouvrier.id).subscribe(
        (msg) => {
          console.log(msg)
        },
        (error) => console.log(error)
        ,
        () => {
          this.submitted=false
          this.getPointageByChefConducteurByDateAndEmail();
        }
      );
    }
    else if (event.target.value == "annuler_pointed") {
      var  status = {
        "pointed":false      
      }
      this.pointageChefService.changeStatus(status,ouvrier.id).subscribe(
        (msg) => {
          console.log(msg)
        },
        (error) => console.log(error)
        ,
        () => {
          this.getPointageByChefConducteurByDateAndEmail();
        }
      );
     
    }

  }
}
