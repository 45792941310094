import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { FactureService } from './facture.service';

@Component({
  selector: 'app-facture',
  templateUrl: './facture.component.html',
  styleUrls: ['./facture.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class FactureComponent implements OnInit { 
  addFactureDialog = false;
  updateFactureDialog = false;
  rembourser: boolean = false;
  dialogImage = false; 
  factureForm
  dateFacture = new Date();
  listFactures
  listFacturesFiltre: any[];
  listUser
  userRemborser
  email
  submitted: boolean;
  selected: boolean;
  chantiers
  fournisseurs
  today: Date;
  uploadedFiles: any;
  saved: boolean;
  showAddButton: boolean = true;
  showFormFiltre: boolean = false;
  loading: boolean = true;
  filtreFactureForm
  images
  displayCustom: boolean;

  activeIndex: number = 0;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  @ViewChild('fileInput') fileInput: FileUpload;


  constructor(private factureService: FactureService, public datepipe: DatePipe, private messageService: MessageService
    , private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.email = localStorage.getItem('email');

    this.getFactureByDate(this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"), this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"))
    this.getAllChantier();
    this.getAllFournisseur()
    this.getAllUser()
    this.factureForm = new FormGroup({
      id: new FormControl(''),
      description: new FormControl('', [Validators.required]),
      refFacture: new FormControl('', [Validators.required]),
      montant: new FormControl('', [Validators.required]),
      chantier: new FormControl('', [Validators.required]),
      fournisseur: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
    });


    this.filtreFactureForm = new FormGroup({
      chantier: new FormControl(''),
      fournisseur: new FormControl(''),
      user: new FormControl(''),
      myDates: new FormControl('', [Validators.required]),
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
    });
  }


  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  getAllUser() {
    this.factureService.getAllUser().subscribe((response: any) => {
      this.listUser = response
    })
  }

  getAllChantier() {
    this.factureService.getAllChantier().subscribe((response: any) => {
      this.chantiers = response
    })
  }

  getAllFournisseur() {
    this.factureService.getAllFournisseur().subscribe((response: any) => {
      this.fournisseurs = response

    })
  }

  getFactureByDate(startDate, endDate) {
    this.factureService.getFacturesByDate(startDate, endDate).subscribe((response: any) => {
      this.listFactures = response
      this.loading = false;
      
    })
  }

  changeDateGetFacture() {
    let Datet = this.datepipe.transform(this.dateFacture, "yyyy-MM-dd")
    this.getFactureByDate(Datet, Datet)
  }

  valider(idFacture) {
    this.factureService.validerFacture(idFacture).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.getFactureByDate(this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"), this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"))
      }
    );
  }

  getFilesFacture(idFacture) {
    this.factureService.getFilesFacture(idFacture).subscribe((response: any) => {
      this.images = response
      this.dialogImage = true
    })
  }

  openNewFacture() {
    this.submitted = false;
    this.factureForm.reset();
    this.addFactureDialog = true;
    this.showAddButton = true;
  }

  saveFacture() {
    this.submitted = true;
    let promiseList = [];
    if ((this.factureForm.invalid || this.fileInput.files.length == 0) || (this.rembourser && this.userRemborser==null )) {
      return;
    }
    this.saved = true;
    this.fileInput.files.forEach(file => {
      promiseList.push(file);
    });
    this.factureService.saveFacture(this.factureForm.value, this.rembourser, promiseList, this.email, this.userRemborser).subscribe(
      (msg) => {
        console.log(msg),
          this.saved = false;
        this.fileInput.clear();
        this.rembourser = false;
        this.factureForm.reset();
        this.addFactureDialog = false;
      },
      (error) => {
        console.log(error),
          this.saved = false;
        this.fileInput.clear();
        this.rembourser = false;
        this.factureForm.reset();
        this.addFactureDialog = false;
      },
      () => {
        this.getFactureByDate(this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"), this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"))
      }
    );
  }

  deleteFacture(facture) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Etes-vous sûr que vous voulez supprimer ' + facture.refFacture + '?',
      header: 'Confirmer',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.factureService.deleteFacture(facture.id).subscribe(
          (msg) => {
            console.log(msg)
            this.messageService.add({ severity: 'success', summary: 'succès', detail: 'Facture Supprimée', life: 3000 });
          },
          (error) => {
            console.log(error),
              this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Facture Non Supprimée', life: 3000 })

          },
          () => {
            this.getFactureByDate(this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"), this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"))
          }
        );
      }
    });
  }
  updateFacture(facture) {
    this.updateFactureDialog = true;
    const ch = this.chantiers.filter(ch => ch.id === facture.chantier.id)
    const startDate = new Date(facture.date)
    this.factureForm.controls.id.patchValue(facture.id);
    this.factureForm.controls.description.patchValue(facture.description);
    this.factureForm.controls.refFacture.patchValue(facture.refFacture);
    this.factureForm.controls.montant.patchValue(facture.montant);
    this.factureForm.controls.chantier.patchValue(ch[0]);
    this.factureForm.controls.date.patchValue(startDate);
    this.updateFactureDialog = true;
  }

  saveUpdateFacture() {
    this.submitted = true;
  
    if (this.factureForm.invalid) {
      return;
    }
    this.factureService.updateFacture(this.factureForm.value).subscribe(
      (msg) => {
        console.log(msg),
         
        this.factureForm.reset();
        this.updateFactureDialog = false;
      },
      (error) => {
        console.log(error),
        this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Facture non modifiée', life: 3000 })
          this.factureForm.reset();
        this.updateFactureDialog = false;
      },
      () => {
        this.messageService.add({ severity: 'success', summary: 'succès', detail: 'Facture modifiée', life: 3000 });
        this.getFactureByDate(this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"), this.datepipe.transform(this.dateFacture, "yyyy-MM-dd"))
      }
    );
  }


//filtre

  changePeriodeFiltre() {
    this.showFormFiltre=false
    if (this.filtreFactureForm.value.myDates[1]==null) {
      this.selected=true
    }
    if (this.filtreFactureForm.value.myDates[0]!=null&&this.filtreFactureForm.value.myDates[1]!=null) {
      this.showFormFiltre=true;
    }
    let start = this.datepipe.transform(this.filtreFactureForm.value.myDates[0], "yyyy-MM-dd")
    let end = this.datepipe.transform(this.filtreFactureForm.value.myDates[1], "yyyy-MM-dd")
    this.filtreFactureForm.patchValue({ start: start })
    this.filtreFactureForm.patchValue({ end: end })

    this.getFactureByDate(start, end)
  }

  changeChantierFiltre(){
    this.factureService.getFacturesByChantierAandDate( this.filtreFactureForm.value.chantier.id,  this.filtreFactureForm.value.start, 
      this.filtreFactureForm.value.end).subscribe((response: any) => {
      this.listFactures = response   
    })
  }

  changeOuvrierFiltre(){
    console.log(this.filtreFactureForm.value);
    
    this.factureService.getFacturesByUserAandDate( this.filtreFactureForm.value.user.email,  this.filtreFactureForm.value.start, 
      this.filtreFactureForm.value.end).subscribe((response: any) => {
      this.listFactures = response   
    })
  }


  changeFournisseurFiltre(){
    console.log(this.filtreFactureForm.value);
    
    this.factureService.getFacturesByFournisseurAndDate( this.filtreFactureForm.value.fournisseur.id,  this.filtreFactureForm.value.start, 
      this.filtreFactureForm.value.end).subscribe((response: any) => {
      this.listFactures = response   
    })
  }
}