import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthServiceService } from '../auth-service.service';
import { RxwebValidators } from "@rxweb/reactive-form-validators"

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ResetPasswordComponent implements OnInit {
  resetForm;
  submited:Boolean=false;
  key:any;
  loading: boolean = false;


  constructor( private route: Router,private authServices: AuthServiceService,private router: ActivatedRoute, private messageService: MessageService) { }

  ngOnInit(): void {
    this.key = this.router.snapshot.queryParamMap.get('key');
    this.validKey(this.key);

    this.resetForm= new FormGroup({
      key:new FormControl(this.key),
      newPassword: new FormControl('',[Validators.required, Validators.minLength(5)])   ,
      conPassword: new FormControl('',  RxwebValidators.compare({ fieldName: 'newPassword' })),
  })
 }


 validKey(key){
  this.authServices.validKey(key) .subscribe(

    (msg) => {
      console.log(msg)

    },
    (error) => {
      console.log(error)
      this.route.navigateByUrl("/login");
    },
    () => {   
    })  
 }
  submitForm() {
    this.submited=true;
    if (this.resetForm.invalid) {
      return;
    }
 this.loading = true;

 console.log(this.resetForm.value);
 
  this.authServices.reset_password(this.resetForm.value) .subscribe(

    (msg) => {
      console.log(msg)

    },
    (error) => {
      console.log(error)
      this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Mot de passe non modifier', life: 3000 }),
      this.loading = false;
    },
    () => {
      this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Mot de passe modifier', life: 3000 });
      this.route.navigateByUrl("/login");
    })  
    }
}
