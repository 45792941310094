import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OuvrierService {
  baseUrl = "/api";

  constructor(private httpClient: HttpClient) { }
  getAllUser() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const users = this.httpClient.get(environment.baseURL + environment.apiPath  + 'users/getAllUsers' , { headers: headers });
    return users;
  }

  getAllRoles() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const users = this.httpClient.get(environment.baseURL + environment.apiPath  + 'roles/' , { headers: headers });
    return users;
  }
 
  addUser(user){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'auth/register' , user,  { headers: headers });
  }

  deleteUser(id) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    return this.httpClient.delete(environment.baseURL + environment.apiPath  + 'users/' + id, {headers: headers, responseType: 'text' })
  }
  updateUser(user) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.put(environment.baseURL + environment.apiPath  + 'users/' +user.id, user, {headers: headers, responseType: 'text' })
  }
  getAllRole() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const roles = this.httpClient.get(environment.baseURL + environment.apiPath  + 'roles/' , { headers: headers });
    return roles;
  }

  // details

  getDetailsByIdUser(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'user-details/findUserDetailsByUser/'+id , 
    { headers: headers });
    return details; 
  }
  addDetails(id,details){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('idUser', id);
    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'user-details/' , details, 
    { params: params, headers: headers });
  }

  updateDetailsUser(details) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);  
    return this.httpClient.put(environment.baseURL + environment.apiPath  + 'user-details/'+ details.id, details, {headers: headers, responseType: 'text' })
  }

// salaire 

  getSalaireByIdUser(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'salaire/findUserSalaireByUser/'+id , 
    { headers: headers });
    return details; 
  }
  addSalaire(id,salaire){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('idUser', id);
    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'salaire/' , salaire, 
    { params: params, headers: headers });
  }

  updateSalairUser(details) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);  
    return this.httpClient.put(environment.baseURL + environment.apiPath  + 'salaire/'+ details.id, details, {headers: headers, responseType: 'text' })
  }

  

}
