export const environment  = {
  production: false,
 baseURL:"https://idts.back.dpc.com.tn/", 
 apiPath: "api/",
 
 firebase: {
  apiKey: "AIzaSyDEQMcywZQNt7T0p9Z6lMtdBaKvDNXxAWw",
  authDomain: "idts-67182.firebaseapp.com",
  projectId: "idts-67182",
  storageBucket: "idts-67182.appspot.com",
  messagingSenderId: "142254664391",
  appId: "1:142254664391:web:c357d243d5e4737d5aece2",
  measurementId: "G-Q7E43ZM5ZM"
}
};
