import { HttpClient, HttpResponse, HttpHeaders, HttpClientModule, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {  
  baseUrl = "/api";
  authorities = [];
  tokenWeb
  roleAs: string;

  constructor(private httpClient: HttpClient, private route: Router, private messageService: MessageService) {

  }

  isLoginSubject = new BehaviorSubject<boolean>(this.isAuthentificated())

  login(log) {
    const webToken = localStorage.getItem('webToken');

   let notification={
    "tokenNotification":webToken,
    "typeDevice": "webToken"
    }

    log=Object.assign({},log,notification);

    this.httpClient.post(environment.baseURL + environment.apiPath + 'auth/login',log ).subscribe(
      (response: any) => {
      if (response.token) {
        if (response.roles[0].name == "CHEF_CHANTIER") {
          localStorage.setItem('authorities', response.roles[0].name);
          localStorage.setItem('email', log.email);
          localStorage.setItem('token', response.token);
          this.route.navigateByUrl('/dashboard').then(() => {
            window.location.reload();
          });
        }
        else if (response.roles[0].name == "CONDUCTEUR_TRAVAUX") {
          localStorage.setItem('authorities', response.roles[0].name);
          localStorage.setItem('email', log.email);
          localStorage.setItem('token', response.token);
          this.route.navigateByUrl('/dashboard/conducteur') .then(() => {
            window.location.reload();
          });
        }
        else if (response.roles[0].name == "RH") {
          localStorage.setItem('authorities', response.roles[0].name);
          localStorage.setItem('email', log.email);
          localStorage.setItem('token', response.token);
          this.route.navigate(['/dashboard/rh']).then(() => {
            window.location.reload();
          });
        }
        this.isLoginSubject.next(true);

      }else{
        this.route.navigateByUrl('/login')
      }
     
    },
    (error) =>{ 
    this.messageService.add({
      severity: "error",
      summary: "Rejetée",
      detail: "E-mail ou mot de passe incorrect !"
    });
  })

  }


  forget_password(log) {
    let params = new HttpParams();
    params = params.append('email', log);
    return this.httpClient.post(environment.baseURL + environment.apiPath +  'auth/reset-password/init', log,{ params: params });
  }

  reset_password(log){
    let params = new HttpParams();
    params = params.append('key', log.key);
    params = params.append('newPassword', log.newPassword);
    return this.httpClient.post(environment.baseURL + environment.apiPath + 'auth/reset-password/finish',log,{ params: params });
  }

  validKey(log){
    let params = new HttpParams();
    params = params.append('key', log);
    return this.httpClient.post(environment.baseURL + environment.apiPath + 'auth/validKey',log,{ params: params });
  }

  change_password(log) {
    const token = localStorage.getItem('token');
    let email = localStorage.getItem('email');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('currentPassword', log.currentPassword);
    params = params.append('email',email);
    params = params.append('newPassword', log.newPassword);
   console.log(email,log);
   
    return this.httpClient.post(environment.baseURL + environment.apiPath + 'auth/change-password', log,
     { headers: headers,params: params, responseType: 'text' });
  }

  public isAuthentificated(): boolean {
    const token = localStorage.getItem('token');
    if (token == null) {
      return false;
    } else {
      return true;
    }
  }

  public UserAuthentificated(): boolean {
      return true;   
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('authorities');
    localStorage.removeItem('webToken');
    this.isLoginSubject.next(false);
    this.route.navigateByUrl('/login')

  }

  isLoggedIn() : Observable<boolean> {
    return this.isLoginSubject.asObservable();
   }

   getRole() {
    this.roleAs = localStorage.getItem('authorities');
    return this.roleAs;
  }
 
}
