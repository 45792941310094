<div class="container-fluid content full-height mt-10">
  <!--begin::Row-->
  <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh">
    <i class="pi pi-arrow-circle-left"></i>
    Retour
  </button>
  <div class="row g-xl-8 mx-auto" style="justify-content: center">
    <!--begin::Col-->
   
    <div class="col-xl-8" style="justify-content: center">
      <!--begin::Stats Widget 5-->
      <div class="card bg-gray-100 card-xl-stretch mb-5 mb-xl-8">
        <!--begin::Body-->
        <div class="card-body p-0 pb-9">
          <!--begin::Info-->
          <div class="
                px-9
                pt-6
                rounded
                h-250px
                w-100
                bgi-no-repeat bgi-size-cover bgi-position-y-top
              " style="
                background-image: url('../../assets/images/construction.png');
              ">
            <!--begin::Header-->
            
            <!--end::Header-->
            <!--begin::Balance-->
            <div class="d-flex align-items-center align-self-center flex-wrap pt-8">
              <div class="fw-bolder fs-7 text-white flex-grow-1 pe-2">
                Bonjour, 
              </div>
            </div>
            <!--begin::Balance-->
        
          </div>
          <!--end::Info-->
         
          <!--begin::Items-->
          <div class="mx-9 mt-n20">
            <!--begin::Row-->
           
            <div class="row g-8 row-cols-1 row-cols-sm-2">
            
              <div class="col">
               
                <div class="bg-body shadow-xs rounded text-center p-7">
                 
                  <!--begin::Symbol-->
                  <div class="symbol symbol-50px mt-1">
                    <span class="symbol-label bg-light-danger">
                      <!--begin::Svg Icon | path: icons/duotune/communication/com010.svg-->
                      <span class="svg-icon svg-icon-2x svg-icon-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
                            fill="black"></path>
                          <path opacity="0.3"
                            d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
                            fill="black"></path>
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <a href="#" class="
                        text-gray-800 text-hover-primary
                        fw-boldest
                        fs-5
                        mt-4
                        d-block
                      " [routerLink]="['/dashboard/rh/consomamation/pays']">Prévision de paie</a>
                </div>
              </div>
              <div class="col">
                <div class="bg-body shadow-xs rounded text-center p-7">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-50px mt-1">
                    <span class="symbol-label bg-light-danger">
                      <!--begin::Svg Icon | path: icons/duotune/communication/com010.svg-->
                      <span class="svg-icon svg-icon-2x svg-icon-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
                            fill="black"></path>
                          <path opacity="0.3"
                            d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
                            fill="black"></path>
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <a href="#" class="
                        text-gray-800 text-hover-primary
                        fw-boldest
                        fs-5
                        mt-4
                        d-block
                      " [routerLink]="['/dashboard/rh/consomamation/depense']">Dépenses</a>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Items-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Stats Widget 5-->
    </div>
    <!--end::Col-->
    <!--begin::Col-->

    <!--end::Col-->
    <!--begin::Col-->

    <!--begin::Col-->
  </div>
</div>