import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }



  getAllClient() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath  + 'clients/' , { headers: headers });
    return chantier;
  }
  addClient(client){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'clients/' , client,  { headers: headers });
  }

  deleteClient(id) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    return this.httpClient.delete(environment.baseURL + environment.apiPath  + 'clients/' + id, {headers: headers, responseType: 'text' })
  }
  updateClient(client) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.put(environment.baseURL + environment.apiPath  + 'clients/' +client.id, client, {headers: headers, responseType: 'text' })
  }
}
