import { Component, OnInit } from '@angular/core';
import { MessagingService } from './messaging.service';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { AuthServiceService } from './authentication/auth-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit{
  title = 'IDTS';
  message;
  subscription: Subscription;
  isLoggedIn : Observable<boolean>;

  constructor(private authServices: AuthServiceService,private messagingService: MessagingService,private primeNGConfig: PrimeNGConfig, private translate: TranslateService) { 
    translate.addLangs(['fr']);
    translate.setDefaultLang('fr');

    this.subscription = this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });

    this.isLoggedIn = authServices.isLoggedIn();
  }
  isLogIn = this.authServices.isAuthentificated();
  ngOnInit() {
     const userId = 'topic_rh_conducteur';
     this.messagingService.requestPermission(userId)
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }

 



}
