import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/authentication/auth-service.service';
import { MessagingService } from 'src/app/messaging.service';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isLoggedIn : Observable<boolean>;
  message;
  noti;
  openedNotification:Boolean = false;
  value=0;
  notifications;
  name
  items: MenuItem[];
  detail
  
  constructor(private authServices: AuthServiceService,private messagingService: MessagingService,private notificationService:NotificationService) { 
    this.isLoggedIn = authServices.isLoggedIn();
  }
  isLogIn = this.authServices.isAuthentificated();
  ngOnInit(): void {
    if (this.isLogIn) {
     this.getNotification();
    }

    this. getUserByEmail();

    this.messagingService.currentMessage
        .subscribe((updatedClientData) => {
         this.noti= updatedClientData?.notification;

     if(this.noti!=undefined){
      this.getNotification();
     } 
      });

      this.items = [{
        label: 'Options',
        items: [{
            label: 'Marquer tout comme vu',
            icon: 'pi pi-eye',
            command: () => {
                this.openAllNotification();
            }
        },
        {
            label: 'Tout supprimer',
            icon: 'pi pi-times',
            command: () => {
                this.deleteAllNotification();
            }
        }
        ]},
      
    ];   
   }

 getUserByEmail() {
    this.notificationService.getUserByEmail().subscribe((response: any) => {
      this. getDetailsByIdUser(response.id);   
    })
  }
  getDetailsByIdUser(id) {
    this.notificationService.getDetailsByIdUser(id).subscribe((response: any) => {
      this.detail = response
    })
  }

getNotification(){
  this.notificationService.getUserByEmail().subscribe((response: any) => {
    this.name=response.firstName
 })

 this.notificationService.getNotification().subscribe((response: any) => {
  this.notifications =response;
});


this.notificationService.getCountOpenNotificationByUser().subscribe((response: any) => {
  this.value =response;
});
}

openAllNotification(){
  this.notificationService.openAllNotificationByUser().subscribe(
    (msg) => {
      console.log(msg)
    },
    (error) => {
      console.log(error)
    },
    () => {
     this.getNotification();
    }
  );
}

deleteAllNotification(){
  this.notificationService.deleteAllNotificationByUser().subscribe(
    (msg) => {
      console.log(msg)
    },
    (error) => {
      console.log(error)
    },
    () => {
     this.getNotification();
    }
  );
}  

  openNotification=(id)=>{
      this.notificationService.openNotification(id).subscribe(
        (msg) => {
          console.log(msg)
        },
        (error) => {
          console.log(error)
        },
        () => {
         this.getNotification();
        }
      );
   
  }

  deleteNotification(id){
    this.notificationService.deleteNotification(id).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
       this.ngOnInit();
      }
    );
 
}

logOutBar(){
  this.authServices.logout();
 
}
  }

