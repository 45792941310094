import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common'
import { environment  } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChantierService {
  
  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }

  getChantierBychef() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const email = localStorage.getItem('email');
    var date = new Date();
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");

    let params = new HttpParams();
    params = params.append('emailUser', email);
    params = params.append('date', latest_date);


    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath  + 'chantUser/getChantierByUserAndDate', { params: params, headers: headers });
    return chantier;
  }

  getPointageByIdChantierAndDate(id) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    var date = new Date();
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");

    let params = new HttpParams();
    params = params.append('idChantier', id);
    params = params.append('fromDate', latest_date);
    params = params.append('toDate', latest_date);

    const ouvrierP = this.httpClient.get(environment.baseURL + environment.apiPath +'pointage/getPointageByIdChantierAndDate', { params: params, headers: headers });
    return ouvrierP;
  }

  getChantierById(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantiers/'+id,
      { headers: headers });
      return chantier; 
  }
  
  changeStatus(status,id){

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token)
 return   this.httpClient.patch(environment.baseURL + environment.apiPath + 'pointage/updatePointageByID/'+id,status, 
 {headers: headers })
  }

  findImageUser(email){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'user-details/findImageUser/'+email , 
    { headers: headers, responseType: 'text' });
    return details; 
  }
  
  /*********************************************************************************************** */

  addChantier(cl) {
    this.httpClient.post<any>(environment.baseURL + environment.apiPath + 'produits/addProduit', cl).subscribe(
      (msg) => {
        console.log(msg),
          location.reload()
      },
      (error) => console.log(error)

    );
  }

  deleteChantier(id) {
    return this.httpClient.delete(environment.baseURL + environment.apiPath + 'produits/deletProduit/' + id)
  }

  updateChantier(id, client) {
    this.httpClient.put(this.baseUrl + 'produits/updateUser/' + id, client).subscribe(
      (msg) => {

      },
      (error) => console.log(error)
    );
  }
}
