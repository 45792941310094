import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = "/api";

  constructor(private httpClient: HttpClient) { }

  getUserByEmail(email) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('email', email);
    const users = this.httpClient.get(environment.baseURL + environment.apiPath  + 'users/getUserByEmail' , 
    {params: params, headers: headers });
    return users;
  }

  getDetailsByIdUser(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'user-details/findUserDetailsByUser/'+id , 
    { headers: headers });
    return details; 
  }

  getSalaireByIdUser(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'salaire/findUserSalaireByUser/'+id , 
    { headers: headers });
    return details; 
  }

  postFile(fileToUpload: File,email){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('email', email);

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.httpClient.post(environment.baseURL + environment.apiPath  + 'images/uploadPhotoProfil', formData,
     {params: params, headers:headers, responseType: 'text'  })
    
}

updateUser(user) {
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  return this.httpClient.put(environment.baseURL + environment.apiPath  + 'users/' +user.id, user, {headers: headers, responseType: 'text' })
}
}
