import { Component, OnInit } from '@angular/core';
import { DatePipe } from "@angular/common";
import { ElementRef, ViewChild } from "@angular/core";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import { PlanningService } from "../planning.service";


@Component({
  selector: 'app-calendrier-planning',
  templateUrl: './calendrier-planning.component.html',
  styleUrls: ['./calendrier-planning.component.css']
})
export class CalendrierPlanningComponent implements OnInit {
  userSelect
  ouvriers: any[];
  usersPointage: any[];
  startDate: Date;
  data=[];
  calendarOptions: CalendarOptions;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  @ViewChild('calendar', { static: true }) calendar: ElementRef<any>

  constructor(private planningService: PlanningService, public datepipe: DatePipe) { }

  ngOnInit() {
    this.getOuvriers()
    
    this.calendarOptions = {
      
      initialView: "dayGridMonth",
      locale: "fr",
      nextDayThreshold: '09:00:00',
      weekends: false ,
      dateClick: this.handleDateClick.bind(this),
    
    
      headerToolbar: {
        left: "myPredButton,myNextButton",
        center: "title",
        right: "",
      },
      customButtons: {
        myNextButton: {
          text: '>',
          click: () => this.gonext()
        },
        myPredButton: {
          text: '<',
          click: () => this.goPrev()
        }
      }
      };
   
  }

  handleDateClick(arg) {
    console.log('date click! ' + arg.dateStr)
  }

  gonext() {
    const calendarApi = this.calendarComponent.getApi();
       calendarApi.next(); // call a method on the Calendar object
       let startDate = this.datepipe.transform( calendarApi.view.currentStart, "yyyy-MM-dd");
       let endDate =this.datepipe.transform(calendarApi.view.currentEnd, "yyyy-MM-dd") ;
       this.getOuvrierWithPointage(this.userSelect.email,startDate,endDate);
     }
     goPrev() {
       const calendarApi = this.calendarComponent.getApi();
          calendarApi.prev(); // call a method on the Calendar object
        let startDate = this.datepipe.transform( calendarApi.view.currentStart, "yyyy-MM-dd");
       let endDate =this.datepipe.transform(calendarApi.view.currentEnd, "yyyy-MM-dd") ;
   
       this.getOuvrierWithPointage(this.userSelect.email,startDate,endDate);
        }
  selectAllUsers() {
     this.getOuvrierWithPointage(this.userSelect.email, null, null);
     this.getOuvriers();
   }


getOuvriers(){
this.planningService.getAllOuvrier().subscribe((response: any) => {
  this.ouvriers = response
});    
  }

  getOuvrierWithPointage(user,startDate,endDate) {
    if (startDate==null &&endDate==null ) {
      startDate = this.datepipe.transform(new Date().setMonth(new Date().getMonth()-1), "yyyy-MM-dd");
     endDate = this.datepipe.transform( new Date().setMonth(new Date().getMonth()+1), "yyyy-MM-dd");
    }
    this.calendarOptions.events=[];
    this.data=[];
    this.planningService.getOuvrierWithPointage(startDate,endDate, user).subscribe((response: any) => {
       response.forEach(obj => { 
       let pointage={
         "id":obj.id,
         "title":obj.chantier.nom ,
          "start":obj.workDay,
          "color":''
       }
       if (obj.exitDate && obj.entryDate) {
        pointage.title=  pointage.title +" De " +  this.datepipe.transform(obj.entryDate, "HH:mm") +" à "+ this.datepipe.transform(obj.exitDate, "HH:mm") 
       }
       if (obj.confirmed) {
        pointage. color='green'
       }
       if (obj.absent) {
        pointage. color='red'
       }
       if (obj.pointed && !obj.confirmed) {
        pointage. color='#FBC02D'
       }
       this.data.push(pointage); 
       this.calendarOptions.events=this.data
      });
    })
  }
}
