<p-toast position="top-center" ></p-toast>
<div class="content fs-6 d-flex flex-column flex-column-fluid full-height" id="kt_content">
  <div class="toolbar" id="kt_toolbar">
    <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
      <div class="
          d-flex
          flex-column
          align-items-start
          justify-content-center
          flex-wrap
          me-2
        ">
        <h1 class="text-dark fw-bolder my-1 fs-2">
          Chantier: {{ chantierName }} <br />
          <small class="text-muted fs-6 fw-normal ms-1">{{
            currentDate | date: " d MMM, y"
            }}</small>
        </h1>
      </div>
    </div>
  </div>

  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div class="container-xxl">
      <!--begin::Row-->
      <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur/chantiers">
        <i class="pi pi-arrow-circle-left" ></i>
        Retour
      </button>
      <div class="row g-xl-8">
        <!--begin::Col-->

        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xxl-12 gy-0 gy-xxl-8">
          <!--begin::Engage Widget 1-->
          <div class="card card-xxl-stretch mb-5 mb-xl-8">
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column justify-content-between h-100">
                <!--begin::Section-->
                <div class="mb-10 d-flex justify-content-center">
                  <div class="text-center py-2">
                    <div class="
                        d-flex
                        align-items-center
                        flex-nowrap
                        text-nowrap
                        py-1
                      ">
                      <button pButton pRipple label="Ajouter tâche" (click)="openNew()" icon="pi pi-plus"
                        class="btn btn-primary p-mr-2"></button>
                    </div>
                  </div>
                  <!--end::Action-->
                </div>
                <!--end::Section-->
                <!--begin::Image-->
                <div class="
                    flex-grow-1
                    bgi-no-repeat
                    bgi-size-contain
                    bgi-position-x-center
                    bgi-position-y-bottom
                    card-rounded-bottom
                    max-h-175px
                    min-h-175px
                  " style="
                    background-image: url('../../../../assets/images/list.png');
                  "></div>
                <!--end::Image-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Engage Widget 1-->
        </div>
        <!--end::Col-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <div class="content fs-6 d-flex justify-content-center full-height">
    <div class="col-xl-8">
      <div class="card card-flush mt-6 mt-xl-9">
        <div class="card-header mt-5">
          <div class="card-title flex-column">
            <h2 class="fw-boldest mb-2">Tâches de chantier</h2>
            <div class="fs-6 fw-bold text-gray-400">

            </div>
          </div>

          <div class="card-toolbar my-1" data-select2-id="select2-data-217-w6yy">
            <div class="d-flex align-items-center position-relative my-1">
              <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
              <span class="svg-icon svg-icon-3 position-absolute ms-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                    transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"></path>
                </svg>
              </span>
              <!--end::Svg Icon-->
              <input type="text" id="kt_filter_search" class="
                  form-control form-control-solid form-select-sm
                  w-150px
                  ps-9
                " placeholder="Chercher" />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <div id="kt_profile_overview_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="table-responsive">
                <p-table #dt [value]="taches" [rows]="3" [paginator]="true" [showCurrentPageReport]="true"
                  [globalFilterFields]="['nom','user.firstName']">

                  <ng-template pTemplate="header">
                    <tr class="   text-start text-gray-400  fw-boldest
                    fs-7 text-uppercase " class="tr-hover">
                    <th class="min-w-150px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1"
                    colspan="1" aria-label="Date: activate to sort column ascending" style="width: 238.622px">
                    Tâche
                  </th>
                      <th class="min-w-150px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1"
                        colspan="1" aria-label="Date: activate to sort column ascending" style="width: 238.622px">
                        Date de début
                      </th>
                      <th class="min-w-150px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1"
                        colspan="1" aria-label="Date: activate to sort column ascending" style="width: 238.622px">
                        Date de fin
                      </th>

                      <th class="min-w-50px text-end sorting" tabindex="0" aria-controls="kt_profile_overview_table"
                        rowspan="1" colspan="1" aria-label="Details: activate to sort column ascending"
                        style="width: 91.9318px">
                        Actions
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-tache>
                    <tr class="even">
                      <td>

                        <div class="d-flex align-items-center">

                          <div class="me-5 position-relative">

                            <div class="symbol symbol-35px symbol-circle">
                              <span class="
                                symbol-label
                                bg-light-danger
                                text-danger
                                fw-bold
                              ">{{ tache.nom|uppercase|slice:0:1   }}</span>
                            </div>

                            <div class="
                              bg-success
                              position-absolute
                              h-8px
                              w-8px
                              rounded-circle
                              translate-middle
                              start-100
                              top-100
                              ms-n1
                              mt-n1
                            "></div>

                          </div>

                          <td data-order="2021-02-21T00:00:00+01:00">

                            {{tache.nom}}
                          </td>

                        </div>

                      </td>
                      <td data-order="2021-02-21T00:00:00+01:00">
                        {{tache.startDate}}
                      </td>
                      <td data-order="2021-02-21T00:00:00+01:00">
                        {{tache.endDate}}
                      </td>

                      <td class="text-end">
                        <button pButton pRipple icon="pi pi-pencil" class=" p-mr-4  button-edit"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-warning button-trash"></button>
                      </td>
                    </tr>
                  </ng-template>

                </p-table>



              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Table container-->
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="tacheDialog" header="Ajouter tâche" [modal]="true" styleClass="p-fluid "
  class="overflow-show">
  <div class="p-grid p-fluid flex-column">
    <div class="p-col-12 p-md-4">
      <form [formGroup]="tacheForm">

          <div class="p-inputgroup mb-2">
            <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3"
                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z"
                  fill="black" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
              </svg></span>
              <div class="p-field">
              <input type="text" pinputtext="" placeholder="créer une tâche" formControlName="nom"
              class="p-inputtext p-component p-element min-width-360px" />
            </div>
          </div>
          <div class="mb-2">
              <small class="p-error" *ngIf="submitted  && tacheForm.controls.nom.hasError('required')"> La tâche est
                requise.</small>
            </div>
        

        <div class="p-inputgroup mb-2">

          <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3"
                d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                fill="black" />
              <path
                d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                fill="black" />
              <path
                d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                fill="black" />
            </svg></span>
          <p-calendar formControlName="myDates" selectionMode="range" [readonlyInput]="true"  dateFormat="dd-mm-yy"
            inputId="range" placeholder="sélectionner date de tâche" class="min-width-360px"  (onSelect)="changePeriodeTache()"></p-calendar>
          
          </div>
          <small class="p-error" *ngIf="submitted  && tacheForm.controls.startDate.hasError('required')"> La date
            début est
            requise.</small>
          <small class="p-error" *ngIf="submitted  && tacheForm.controls.endDate.hasError('required')"> La date fin
            est
            requise.</small>
        
        <button pButton pRipple label="Ajouter tâche" (click)="saveTache()" icon="pi pi-plus"
          class="btn btn-primary p-mr-2 mt-5"></button>
      </form>
    </div>
  </div>
</p-dialog>