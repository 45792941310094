<p-toast></p-toast>
<div class="container emp-profile">
	<button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/conducteur"
		*ngIf="roleConducteur">
		<i class="pi pi-arrow-circle-left"></i>
		Retour
	</button>
	<button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/" *ngIf="roleChef">
		<i class="pi pi-arrow-circle-left"></i>
		Retour
	</button>
	<button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh" *ngIf="roleRH">
		<i class="pi pi-arrow-circle-left"></i>
		Retour
	</button>
	<div class="row">
		<div class="col-md-4">
			<div class="profile-img">
				<img [src]="detail?.image" *ngIf="detail?.image" alt="logo" class="card-img rounded-circle" />
				<img *ngIf="!detail?.image" src="../../../assets/images/avtar.jpg" alt="logo"
					class="card-img rounded-circle" />
				<div class="file btn btn-lg btn-primary" *ngIf="detail">
					Changer Photo
					<input type="file" name="file" (change)="onSelectFile($event)" />
				</div>
				<div *ngIf="selected">
					<button pButton pRipple type="button" icon="pi pi-times"
						class="p-button-rounded p-button-danger p-button-text" (click)="onDeselectFile()"></button>
					<button pButton pRipple label="Télécharger !" type="button" icon="pi pi-upload" (click)="onUpload()"
						class="p-button-rounded  p-button-text"></button>
				</div>
			</div>
		</div>
		<div class="col-md-6" *ngIf="!edit">
			<div class="profile-head">
				<h5>
					{{user?.firstName}} {{user?.lastName}}
				</h5>
				<h6>
					<p>{{detail?.addressFr}}</p>
				</h6>
				<p class="proile-rating">email : <span>{{user?.email}}</span></p>

				<p class="proile-rating">Téléphone : <span>{{user?.phone}}</span></p>
			</div>
		</div>

		<div class="col-md-6" *ngIf="edit">
			<div class="profile-head">
				<h6>
					<p>Modifier le Profile</p>
				</h6>
				<form [formGroup]="ouvrierForm">
					<div class="row mt-3">
						<div class="col-md-6">

							<input placeholder="Nom" type="text" pInputText id="name" required autofocus
								formControlName="firstName" />
							<br>
							<small class="p-error"
								*ngIf="submitted  && ouvrierForm.controls.firstName.hasError('required')">Le nom de
								famille est requis.</small>
						</div>

						<div class="col-md-6">

							<input formControlName="lastName" placeholder="prénom" type="text" pInputText id="name"
								required autofocus />
							<br>
							<small class="p-error"
								*ngIf="submitted  && ouvrierForm.controls.lastName.hasError('required')">Le prénom est
								requis.</small>
						</div>

					</div>
					<p class="proile-rating">email : <span>
							<input type="text" pInputText id="email" required autofocus formControlName="email" />
						</span> <br>
						<small class="p-error"
							*ngIf="submitted  && ouvrierForm.controls.email.hasError('required')">L'e-mail est
							requis.</small>
						<small class="p-error" *ngIf="submitted  && ouvrierForm.controls.email.hasError('email')">Email
							non
							validé.</small>

					</p>

					<p class="proile-rating">Téléphone : <span>
							<input type="text" pInputText id="name" required autofocus formControlName="phone" />
						</span> <br>
						<small class="p-error" *ngIf="submitted && ouvrierForm.controls.phone.hasError('required')">Le
							téléphone est
							requis.</small>
						<small class="p-error"
							*ngIf="submitted && ouvrierForm.controls.phone.hasError('pattern')">téléphone non
							validé.</small>
					</p>

				</form>
			</div>
		</div>
		<div class="col-md-2">
			<div *ngIf="!edit">
				<input type="button" (click)="updateUser()" class="profile-edit-btn" name="btnAddMore"
					value="Edit Profile" />
			</div>
			<div *ngIf="edit">
				<input type="button" (click)="AnnulerUpdateUser()" class="profile-edit-btn" name="btnAddMore"
					value="Annuler" />
			</div>
			<br>
			<div *ngIf="edit">
				<input type="button" (click)="sauvgarderUpdateUser()" class="profile-edit-btn" name="btnAddMore"
					value="Sauvegarder" />
			</div>
		</div>

	</div>
	<div class="row">
		<div class="col-md-4">
			<div class="profile-work">
				<p>Paramètre</p>
				<a href="" routerLink="/changePassword">Changer mot de passe</a><br />
				<!-- <a href="">Bootsnipp Profile</a><br />
					<a href="">Bootply Profile</a>
					<p>SKILLS</p>
					<a href="">Web Designer</a><br />
					<a href="">Web Developer</a><br />
					<a href="">WordPress</a><br />
					<a href="">WooCommerce</a><br />
					<a href="">PHP, .Net</a><br /> -->
			</div>
		</div>
		<div class="col-md-8">

			<p-tabView>

				<p-tabPanel header="Détails" class="profile-tab ">
					<div *ngIf="detail">
						<!-- <div class="row">
							<div class="col-md-6">
								<label>Adresse France :</label>
							</div>
							<div class="col-md-6 ">
								<p>{{detail?.addressFr}}</p>
							</div>
						</div> -->
						<div class="row">
							<div class="col-md-6">
								<label>Numéro de cin :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.cinNumber}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Numéro de passport :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.passportNumber}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Numéro de carte Se jour :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.carteSejourNumber}}</p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<label>Numéro de recepisse carte Se jour :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.recepisse_carteSejourNumber}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Numéro de Visa :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.visaNumber}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Rib france :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.ribFr}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Rib tunisie :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.ribTn}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Numéro de Sécurité sociale tunisie :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.socialSecurityNumbertn}}</p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<label>Numéro de Sécurité sociale france :</label>
							</div>
							<div class="col-md-6">
								<p>{{detail?.socialSecurityNumberFr}}</p>
							</div>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Salaire" class="profile-tab ">
					<div *ngIf="salaire">
						<div class="row">
							<div class="col-md-6 ">
								<label>coût journalier moyen(CJM) :</label>
							</div>
							<div class="col-md-6">
								<p>{{salaire?.cjm}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Salaire NET France :</label>
							</div>
							<div class="col-md-6">
								<p>{{salaire?.salaireNetFr}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Salaire NET Tunisie :</label>
							</div>
							<div class="col-md-6">
								<p>{{salaire?.salaireNetTn}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Charge social France :</label>
							</div>
							<div class="col-md-6">
								<p>{{salaire?.chargeSocialFr}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>Charge social Tunisie :</label>
							</div>
							<div class="col-md-6">
								<p>{{salaire?.chargeSocialTn}}</p>
							</div>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>

</div>