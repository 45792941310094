import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RemboursementService {

  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }


  getRemboursementsByDate(fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  
    let params = new HttpParams();
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);
  
    const listMasse = this.httpClient.get(environment.baseURL + environment.apiPath + 'remboursement/getRemboursementByDate', {params: params, headers: headers });
    return listMasse;
  }
  validerRemboursement(idRemboursement){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('idRemboursement', idRemboursement);
  
    const listRemboursement = this.httpClient.put(environment.baseURL + environment.apiPath + 'remboursement/validerRemboursement',idRemboursement, {params: params, headers: headers, responseType: 'text' });
    return listRemboursement; 
  }
  
  payerRemboursement(idRemboursement){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('idRemboursement', idRemboursement);
  
    const listRemboursement = this.httpClient.put(environment.baseURL + environment.apiPath + 'remboursement/payerRemboursement',idRemboursement, {params: params, headers: headers, responseType: 'text' });
    return listRemboursement; 
  }

  deleteRemboursement(id) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  
    return this.httpClient.delete(environment.baseURL + environment.apiPath  + 'remboursement/' + id, {headers: headers, responseType: 'text' })
  }

  updateRemboursement(idRemboursement,idUser) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('idRemboursement', idRemboursement);
    params = params.append('idUser',idUser)
    return this.httpClient.put(environment.baseURL + environment.apiPath  + 'remboursement/updateRemboursement',idRemboursement, {params: params, headers: headers, responseType: 'text' });
  }

  getAllUser() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const users = this.httpClient.get(environment.baseURL + environment.apiPath  + 'users/getAllUsers' , { headers: headers });
    return users;
  }
}
