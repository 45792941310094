<p-toast></p-toast>
<div classe="body">
    <main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
      <div class="container">
        <div class="card login-card">
          <div class="row no-gutters">
            <div class="col-md-5">
                <!-- <img src="../../../assets/images/logo.png" alt="logo" /> -->
            </div>
            <div class="col-md-7">
              <div class="card-body">
                <div class="brand-wrapper">
                    <img src="../../../assets/images/logo.png" alt="logo" />
                </div>
                <p class="login-card-description"> Récupérer votre compte.</p>
                <form  [formGroup]="forgetForm">
                    <div class="form-group">
                        <label class="form-label fs-6 fw-bolder text-dark">Adresse e-mail</label>
                        <input class="form-control form-control-lg form-control-solid" type="text" name="email"
                          formControlName="email" />
                          <div>
                            <small class="text-danger" *ngIf="submited && forgetForm.get('email').hasError('required')">Email is
                              required.</small>
                            <small class="text-danger" *ngIf="submited && forgetForm.get('email').hasError('email')">Email not
                              valid.</small>
                          </div>   
                    </div>
                    <button class="btn btn-primary w-100 fw-bolder me-3 my-2" type="button" [disabled]="loading" (click)="submitforget()" >
                      <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                      Envoyer <span *ngIf="loading">...</span>
                    </button>
                  </form>
                  <a href routerLink="/login" class="link-primary fs-6 ">login ?</a>
                
              </div>
            </div>
          </div>
        </div>
    </div>
</main>

</div>