import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ChangePasswordComponent implements OnInit {
  loading: boolean = false;
  resetForm;
  submited:Boolean=false;

  key:any;
  constructor( private route: Router,private authServices: AuthServiceService, private messageService: MessageService) { }

  ngOnInit(): void {
  this.resetForm= new FormGroup({
      currentPassword: new FormControl('',Validators.required)   ,
      newPassword: new FormControl('',Validators.required)   ,
      conPassword: new FormControl('', [Validators.required, Validators.minLength(5), RxwebValidators.compare({ fieldName: 'newPassword' })]),
  })
 }
  submitForm() {
    this.submited=true;
    if (this.resetForm.invalid) {
      return;
    }
    this.loading =true
  this.authServices.change_password(this.resetForm.value).subscribe(
    (msg) => {
      console.log(msg)

    },
    (error) => {
      console.log(error)
      this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Mot de passe non modifier', life: 3000 }),
      this.loading = false;
    },
    () => {
      this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Mot de passe modifier', life: 3000 });
     this.authServices. logout();
    })  
    }
}
