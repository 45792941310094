import { Component, OnInit } from '@angular/core';
import { ConsommationService } from 'src/app/rh/consommation/consommation.service';
import { PlanningService } from './planning.service';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit {
  chantiers: any[];
  value: number = 0;
  
  constructor(private plannningService : PlanningService, private consommationService: ConsommationService) { }

  ngOnInit(): void {
    this.getAllChantier();
   
  }

getAllChantier() {
    this.plannningService.getAllChantier().subscribe((response: any) => {
      this.chantiers = response
      this.chantiers.forEach(element => {
        this.consommationService.getHommeJour(element.id, element.startDate, element.endDate).subscribe((hommejour: any) => {
          console.log("hj=", element.nom, hommejour);
          console.log("budjet=", element.chiffre_affaire / 250);
          const pourcentage = (hommejour / (element.chiffre_affaire / 250)) * 100
          element.pourcentageHj = pourcentage.toFixed(2)
          element.hj = hommejour
        })
      });
      
    })
  }
}
