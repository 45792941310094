<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Oups!</h1>
                <h2>
                    404 Non trouvé</h2>
                <div class="error-details">
                    Désolé, une erreur s'est produite, la page demandée n'a pas été trouvée !
                </div>
              
            </div>
        </div>
    </div>
</div>