import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsommationService } from '../consommation.service';

@Component({
  selector: 'app-pays',
  templateUrl: './pays.component.html',
  styleUrls: ['./pays.component.css']
})
export class PaysComponent implements OnInit {
  chantiers: any[];
  hj
  situation
  paysForm: FormGroup;
  startDate
  endDate
  nomChantier
  submitted: boolean;
  calculed: boolean;

  constructor(private consommationService: ConsommationService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.getAllChantier();
    this.paysForm = new FormGroup({
      chantier: new FormControl('', [Validators.required]),
      myDates: new FormControl('', [Validators.required]),
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
    });


  }
  getAllChantier() {
    this.consommationService.getAllChantier().subscribe((response: any) => {
      this.chantiers = response
    })
  }
  changePeriodePays() {
    let start = this.datepipe.transform(this.paysForm.value.myDates[0], "yyyy-MM-dd")
    let end = this.datepipe.transform(this.paysForm.value.myDates[1], "yyyy-MM-dd")
    this.paysForm.patchValue({ start: start })
    this.paysForm.patchValue({ end: end })
  }

  claculer() {
    this.submitted = true;

    if (this.paysForm.invalid) {
      return; 
    }

    this.calculed = true;
    this.startDate = this.datepipe.transform(this.paysForm.value.myDates[0], "yyyy-MM-dd")
    this.endDate = this.datepipe.transform(this.paysForm.value.myDates[1], "yyyy-MM-dd")
    let idChantier = this.paysForm.value.chantier.id;
    this.nomChantier = this.paysForm.value.chantier.nom;
    this.getHommeJour(idChantier, this.startDate, this.endDate);
    this.getSituation(idChantier, this.startDate, this.endDate);
  }


  getHommeJour(idChantier, startDate, endDate) {
    this.consommationService.getHommeJour(idChantier, startDate, endDate).subscribe((response: any) => {
      this.hj = response
    })
  }

  getSituation(idChantier, startDate, endDate) {
    this.consommationService.getSituation(idChantier, startDate, endDate).subscribe((response: any) => {
      this.situation = response
    })
  }

}
