<p-toast></p-toast>

<p-confirmPopup></p-confirmPopup>
<div class="content fs-6 d-flex flex-column flex-column-fluid full-height" id="kt_content">
  <div class="container-fluid d-flex flex-stack mb-5 flex-wrap flex-sm-nowrap" style="justify-content: end">
    <!--begin::Info-->

    <!--end::Info-->
    <!--begin::Actions-->
    <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
        <button pButton pRipple label="Ajouter client" icon="pi pi-plus" class="btn btn-primary p-mr-2" (click)="openNew()"></button>
    </div>
    <!--end::Actions-->
  </div>
  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
    <div class="container">

      <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh/projet">
        <i class="pi pi-arrow-circle-left" ></i>
        retour
      </button>
      <div class="row g-5 gx-xxl-12 mb-xxl-3">
        <div class="col-xxl-12" data-select2-id="select2-data-139-ub4n">
          <div class="card card-xxl-stretch mb-xl-3">
            <div class="card-header border-0 pt-5 pb-3" data-select2-id="select2-data-138-w70k">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-boldest text-gray-800 fs-2">liste des clients
                </span>
              </h3>

              <div class="card-toolbar">
                <!--begin::Search-->
                <div class="w-200px position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="
                      svg-icon svg-icon-3 svg-icon-gray-500
                      position-absolute
                      top-50
                      translate-middle
                      ms-6
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input type="text" class="form-control form-control-sm form-control-solid  ps-10" name="search"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"  value="" placeholder="Search" />
                </div>
                <!--end::Search-->
              </div>
            </div>
            <div class="card-body py-0">
              <!--begin::Table-->
              <div class="table-responsive">

                <p-table #dt [value]="clients" [rows]="5" [paginator]="true" [showCurrentPageReport]="true"
                  [globalFilterFields]="['nom','client.nom']"  [rowHover]="true">
                  <ng-template pTemplate="header">
                    <tr class="
                        text-start text-gray-400
                        fw-boldest
                        fs-7
                        text-uppercase
                      " class="tr-hover">
                      <th class="min-w-200px px-0" pSortableColumn="nom">clients<p-sortIcon field="nom"></p-sortIcon>
                      </th>
                      <th class="min-w-125px" pSortableColumn="numTel">Numéro <p-sortIcon field="client.nom">
                        </p-sortIcon>
                      </th>
                      <th class="min-w-125px">E-mail</th>
                      <th class="">Action</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-client>
                    <tr>
                      <td class="p-0">
                        <div class="d-flex align-items-center">
                          <div class="symbol symbol-50px me-2">
                            <span class="symbol-label">
                              <img alt="" class="w-25px" src="../../../assets/images/aven.svg" />
                            </span>
                          </div>
                          <div class="ps-3">
                            <a class="  text-gray-800
                                fw-boldest fs-5 text-hover-primary mb-1 ">{{client.nom}}
                            </a>
                            <span class="text-gray-400 fw-bold d-block">{{client.adresse}}-{{client.ville}}-{{client.codePostal}}</span>
                          </div>
                        </div>


                      </td>
                      <td>
                        <span class="text-gray-800 fw-boldest fs-5 d-block">{{client.numTel}}</span>
                      </td>


                      <td>
                        <div class="d-flex flex-column w-100 me-2 mt-2">
                         {{client.email}}
                        </div>
                      </td>

                      <td>
                        <button pButton pRipple icon="pi pi-pencil" class=" p-mr-2 me-5 button-edit" (click)="editClient(client)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-warning button-trash" (click)=" deleteClient(client)" ></button>
                      </td>
                    </tr>
                  </ng-template>

                </p-table>
              </div>
              <!--end::Table-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog [(visible)]="clientDialog" [style]="{width: '450px'}" header="Détails client" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <form class="example-form" [formGroup]="clientForm">
      <div class="p-field">
          <label for="name">Nom</label>
          <input type="text" pInputText id="name" required autofocus formControlName="nom" />
          <small class="p-error" *ngIf="submitted  && clientForm.controls.nom.hasError('required')">Le nom est requis</small>
      </div>
      <div class="p-field">
        <label for="name">E-mail</label>
        <input type="text" pInputText id="email" required autofocus formControlName="email" />
        <small class="p-error" *ngIf="submitted && clientForm.controls.email.hasError('required')">L'e-mail est requis.</small>
        <small class="p-error" *ngIf="submitted  && clientForm.controls.email.hasError('email')">Email non
          validé.</small>
      </div>
    <div class="p-field">
      <label for="name">Téléphone</label>
      <input type="text" pInputText id="Telephone" required autofocus formControlName="numTel" />
      <small class="p-error" *ngIf="submitted && clientForm.controls.numTel.hasError('required')">Le téléphone est requis.</small>
      <small class="p-error" *ngIf="submitted && clientForm.controls.numTel.hasError('pattern')">téléphone non
        validé.</small>
    </div>
    <div class="p-field">
      <label for="name">Addresse</label>
      <input type="text" pInputText id="adresse" required autofocus formControlName="adresse" />
      <small class="p-error" *ngIf="
          submitted && clientForm.controls.adresse.hasError('required')
        ">L'adresse est requise.</small>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <label for="name">Ville</label>
        <input type="text" pInputText id="ville" required autofocus formControlName="ville" />
        <small class="p-error" *ngIf="
            submitted && clientForm.controls.ville.hasError('required')
          ">Ville est requise.</small>
      </div>
      <div class="col-md-6">
        <label for="name">Code Postal</label>
        <input type="text" pInputText id="codePostal" required autofocus formControlName="codePostal" />
        <small class="p-error" *ngIf="
            submitted && clientForm.controls.codePostal.hasError('required')
          ">Code Postal est requis.</small>
      </div>
    </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
      <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <!-- <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveClient()"></button> -->

      <button pButton pRipple label="Sauvegarder" icon="pi pi-check" class="p-button-text" *ngIf="showAddButton"
      (click)="saveClient()"></button>

  <button pButton pRipple label="Modifier " icon="pi pi-check" class="p-button-text"
      *ngIf="!showAddButton" (click)="saveUpdate()"></button>

  </ng-template>
</p-dialog>
