import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PointageService {

  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }

  getAllChantier() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantiers/', { headers: headers });
    return chantier;
  }
  getPointageByIdChantierAndDate(id,date) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);


    let params = new HttpParams();
    params = params.append('idChantier', id);
    params = params.append('fromDate', date);
    params = params.append('toDate', date);

    const ouvrierP = this.httpClient.get(environment.baseURL + environment.apiPath +'pointage/getPointageByIdChantierAndDate', { params: params, headers: headers });
    return ouvrierP;
  }
  changeStatus(status,id){

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token)
 return   this.httpClient.patch(environment.baseURL + environment.apiPath + 'pointage/updatePointageByID/'+id,status, 
 {headers: headers })
  }

  getChantierById(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantiers/'+id,
      { headers: headers });
      return chantier; 
  }


  updateDeplace(id,idNewChantier){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('idChantier', idNewChantier);
    return this.httpClient.patch<any>(environment.baseURL + environment.apiPath  + 'pointage/updateChantierPointageByID/'+id,idNewChantier,
    { headers: headers });
  
  }
  
  diviserPointage(id,idChantierMatin,idChantierSoir){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('idChantierMatin', idChantierMatin);
    params = params.append('idChantierSoir', idChantierSoir);
    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'pointage/dividePointage',id,{params: params, headers: headers });
  
  }
  
  deletePointage(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.delete(environment.baseURL + environment.apiPath + 'pointage/' + id,{headers: headers })
  }

  getHommeJour(idChantier,startDate,endDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('idChantier', idChantier);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'paye/countHJ/', {params: params, headers: headers });
    return chantier;
  }
  findImageUser(email){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'user-details/findImageUser/'+email , 
    { headers: headers, responseType: 'text' });
    return details; 
  }
}
