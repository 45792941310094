import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./authentication/login/login.component";
import { ChantiersComponent } from "./conducteur-travaux/chantiers/chantiers.component";
import { ConducteurTravauxComponent } from "./conducteur-travaux/conducteur-travaux.component";
import { OuvriersComponent } from "./rh/ouvriers/ouvriers.component";
import { ChantierComponent } from "./chef-chantier/chantier/chantier.component";
import { DashboardComponent } from "./chef-chantier/dashboard/dashboard.component";
import { TableDashboardOccupationComponent } from "./conducteur-travaux/planning/table-dashboard-occupation/table-dashboard-occupation.component";
import { PlanningComponent } from "./conducteur-travaux/planning/planning.component";
import { TacheComponent } from "./conducteur-travaux/planning/tache/tache.component";
import { AffectChefChantierComponent } from "./conducteur-travaux/planning/affect-ouvrier-chefChantier/affect-chef-chantier.component";
import { AffecteOuvrierComponent } from "./chef-chantier/affecte-ouvrier/affecte-ouvrier.component";
import { CalendrierPlanningComponent } from "./conducteur-travaux/planning/calendrier-planning/calendrier-planning.component";
import { RhComponent } from "./rh/rh.component";
import { VerfiPointageComponent } from "./rh/pointage/verfi-pointage/verfi-pointage.component";
import { AddFacturesComponent } from "./conducteur-travaux/add-factures/add-factures.component";

import { ConsommationComponent } from "./rh/consommation/consommation.component";
import { DepenseComponent } from "./rh/consommation/depense/depense.component";
import { PaysComponent } from "./rh/consommation/pays/pays.component";
import { FactureComponent } from "./rh/facture/facture.component";
import { RemboursementComponent } from "./rh/remboursement/remboursement.component";
import { RemboursementFactureComponent } from "./rh/facture/remboursement-facture/remboursement-facture.component";

import { ErreurNotFoundComponent } from "./components/erreur-not-found/erreur-not-found.component";
import { CrudChantiersComponent } from "./rh/projet/crud-chantiers/crud-chantiers.component";
import { ClientsComponent } from "./rh/projet/clients/clients.component";
import { ProjetComponent } from "./rh/projet/projet.component";
import { FournisseurComponent } from "./rh/projet/fournisseur/fournisseur.component";
import { PointageChefChantierComponent } from "./chef-chantier/pointage-chef-chantier-conducteur/pointage-chef-chantier.component";
import { PointageComponent } from "./rh/pointage/pointage.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";
import { ForgetPasswordComponent } from "./authentication/forget-password/forget-password.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { ChangePasswordComponent } from "./authentication/change-password/change-password.component";
import { CalendrierChantierComponent } from "./conducteur-travaux/planning/calendrier-chantier/calendrier-chantier.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },{
    path: 'forgetPassword',
    component:ForgetPasswordComponent ,
   },
   {
    path: 'reset/finish',
    component:ResetPasswordComponent ,
   },
  {
    path: 'erreur',
    component: ErreurNotFoundComponent,
  } ,

  {
    path: 'profile',
    component: ProfileComponent,
  } ,
  {
   path: 'changePassword',
   component:ChangePasswordComponent ,
  } ,
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CHEF_CHANTIER'
    }
  },
  {
    path: "dashboard/pointage/:id",
    component: ChantierComponent,
    canActivate: [AuthGuard],
    data: {
      role: ['CHEF_CHANTIER','CONDUCTEUR_TRAVAUX'],
    }, 
   
  },
  {
    path: "dashboard/affectOuvrier/:id",
    component: AffecteOuvrierComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CHEF_CHANTIER'
    }
  },{
    path: "dashboard/monPointage",
    component: PointageChefChantierComponent,
    canActivate: [AuthGuard],
    data: {
      role: ['CHEF_CHANTIER','CONDUCTEUR_TRAVAUX'],
    }
  },
  {
    path: "dashboard/conducteur",
    component: ConducteurTravauxComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  },
  {
    path: "dashboard/conducteur/facture",
    component: AddFacturesComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  },

  {
    path: "dashboard/conducteur/chantiers",
    component: ChantiersComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  },
  {
    path: "dashboard/conducteur/planing",
    component: PlanningComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  },
  {
    path: "dashboard/conducteur/planing/tasks/:id",
    component: TacheComponent,
    canActivate: [AuthGuard],
     data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  },
  {
    path: "dashboard/conducteur/planing/affectChefChantier/:id",
    component: AffectChefChantierComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  },
  {
    path: "dashboard/conducteur/planing/tableOccupation",
    component: TableDashboardOccupationComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  },
  {
    path: "dashboard/conducteur/planing/calendrier",
    component: CalendrierPlanningComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'CONDUCTEUR_TRAVAUX'
    }
  }
    ,
    {
      path: "dashboard/conducteur/planing/calendrierChantier",
      component: CalendrierChantierComponent,
      canActivate: [AuthGuard],
      data: {
        role: 'CONDUCTEUR_TRAVAUX'
      }
    },
  {
    path: "dashboard/rh",
    component: RhComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/ouvriers",
    component: OuvriersComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/projet",
    component: ProjetComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/chantiers",
    component: CrudChantiersComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/clients",
    component: ClientsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/fournisseur",
    component: FournisseurComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/verifpointage/:chantier/:date",
    component: VerfiPointageComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
 
  {
    path: "dashboard/rh/pointage",
    component: PointageComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/facture",
    component: FactureComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },{
    path: "dashboard/rh/remboursement",
    component: RemboursementComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },{
    path: "dashboard/rh/facture/remboursement/:id",
    component: RemboursementFactureComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/consomamation",
    component: ConsommationComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/consomamation/pays",
    component: PaysComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: "dashboard/rh/consomamation/depense",
    component: DepenseComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'RH'
    }
  },
  {
    path: '**',
    component: ErreurNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
