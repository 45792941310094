<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<p-tabView class="center-tab">
    <p-tabPanel header="Facture">
        <div class="container-xxl">
            <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh">
                <i class="pi pi-arrow-circle-left"></i>
                Retour
            </button>

            
            <div class="row g-xl-8">

                <div class="col-xxl-12 gy-0 gy-xxl-8">
                    <!--begin::Engage Widget 1-->
                    <div class="card card-xxl-stretch mb-5 mb-xl-8">
                        <!--begin::Body-->
                        <div class="card-body">
                            <!--begin::Wrapper-->
                            <div class="d-flex flex-column justify-content-between h-100">
                                <!--begin::Section-->
                                <div class="mb-10 d-flex justify-content-center">
                                    <div class="text-center py-2">
                                        <div class="  d-flex align-items-center flex-nowrap  text-nowrap  py-1 ">
                                            <button pButton pRipple label="Ajouter Facture" (click)="openNewFacture()"
                                                icon="pi pi-plus" class="btn btn-primary p-mr-2"></button>
                                        </div>
                                    </div>
                                    <!--end::Action-->
                                </div>
                                <!--end::Section-->
                                <!--begin::Image-->
                                <div class="flex-grow-1 bgi-no-repeat bgi-size-contain  bgi-position-x-center  bgi-position-y-bottom  card-rounded-bottom
                        max-h-175px  min-h-175px "
                                    style=" background-image: url('../../../../assets/images/fac3.png'); "></div>
                                <!--end::Image-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Engage Widget 1-->
                </div>

            </div>
        </div>



        <div class="content fs-6 d-flex justify-content-center full-height">
            <div class="col-xl-8">
                <div class="card card-flush mt-6 mt-xl-9">
                    <div class="card-header mt-5">
                        <div class="card-title flex-column" *ngIf="!showFormFiltre">
                            <h2 class="fw-boldest mb-2">Factures le {{
                                dateFacture | date: " d MMM, y"
                                }}</h2>
                            <div class="fs-6 fw-bold text-gray-400">

                            </div>
                        </div>
                        <div class="card-title flex-column" *ngIf="showFormFiltre">
                            <h2 class="fw-boldest mb-2">Factures le {{
                                filtreFactureForm.value.start | date: "dd/MM/yyyy"
                                }} à {{
                                filtreFactureForm.value.end | date: "dd/MM/yyyy"
                                }}
                            </h2>
                            <div class="fs-6 fw-bold text-gray-400">

                            </div>
                        </div>
                        <form [formGroup]="filtreFactureForm">
                            <div class="row">
                                <div class="col-12">
                                    <div class=" forms-container  d-flex flex-column align-items-center">

                                        <!-- <p-calendar inputId="basic" [disabledDays]="[0,6]" id="kt_filter_search"
                                        dateFormat="dd-mm-yy" [(ngModel)]="dateFacture" (onSelect)="changeDateGetFacture()"
                                        [showIcon]="true" [minDate]="today" placeholder="Selectionner date">
                                    </p-calendar> -->
                                        <p-calendar formControlName="myDates" selectionMode="range"
                                            dateFormat="dd-mm-yy" [readonlyInput]="true"
                                            placeholder="Sélectionner période"  [showIcon]="true"
                                            (onSelect)="changePeriodeFiltre()" inputId="range">
                                        </p-calendar>

                                        <small class="p-error"
                                            *ngIf="selected  && filtreFactureForm.controls.end.hasError('required')">
                                            Selectionner date debut et date fin
                                            .</small>


                                        <div class="row mt-5">
                                            <div class="col-4">
                                                <p-dropdown [options]="listUser" formControlName="user"
                                                    class="col-6 mt-4 mb-5" optionLabel="firstName"
                                                    [filter]="true" (ngModelChange)="changeOuvrierFiltre()"
                                                    filterBy="firstName,lastName" [showClear]="true"
                                                    *ngIf="showFormFiltre" placeholder="Sélectionner l'ouvrier">
                                                    <ng-template pTemplate="selectedItem" let-USER>
                                                        <div class="country-item country-item-value">
                                                            <div>{{ USER.firstName }} {{ USER.lastName
                                                                }}</div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template let-USER pTemplate="item">
                                                        <div class="country-item">
                                                            <div>{{ USER.firstName }} {{ USER.lastName }}</div>

                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="col-4">
                                                <p-dropdown class="col-6 mt-4" [options]="chantiers"
                                                    *ngIf="showFormFiltre" formControlName="chantier"
                                                    (ngModelChange)="changeChantierFiltre()"
                                                    placeholder="Sélectionner un chantier" optionLabel="nom"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-4">
                                                <p-dropdown [options]="fournisseurs"
                                                    formControlName="fournisseur" [showClear]="true"
                                                    class="col-6  mb-5 mt-4" (ngModelChange)="changeFournisseurFiltre()"
                                                    defaultLabel=" sélectionner fournisseur"
                                                    *ngIf="showFormFiltre"
                                                    placeholder="sélectionner fournisseur" optionLabel="nom">
                                                    <ng-template let-option pTemplate="item">
                                                        <span>{{option.nom}} </span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-toolbar my-1" data-select2-id="select2-data-217-w6yy">
                            <div class="d-flex align-items-center position-relative my-1">
                                <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                                <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                            transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                        <path
                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                            fill="black"></path>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                             
                            </div>
                            <!--end::Search-->
                        </div>
                        <!--begin::Card toolbar-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-0">
                        <!--begin::Table container-->
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <div id="kt_profile_overview_table_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="table-responsive">
                                    <p-table #dt [value]="listFactures" [rows]="4" [paginator]="true"
                                        [loading]="loading" [showCurrentPageReport]="true"
                                        [globalFilterFields]="['nom','user.firstName']">

                                        <ng-template pTemplate="header">
                                            <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase"
                                                class="tr-hover">
                                                <th style="width: 200px"> Réf Facture </th>
                                                <th> Fournisseur</th>
                                                <th> Date Facture </th>
                                                <th> Utilisateur </th>
                                                <th> Montant</th>
                                                <th> Payé </th>
                                                <th> Validé </th>
                                                <th>Action </th>
                                            </tr>

                                        </ng-template>
                                        <ng-template pTemplate="body" let-facture>
                                            <tr class="even">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="me-5 position-relative">
                                                            <div class="symbol symbol-35px symbol-circle">
                                                                <span
                                                                    class=" symbol-label bg-light-danger text-danger fw-bold">
                                                                    {{ facture.refFacture|uppercase|slice:0:1 }}</span>
                                                            </div>
                                                            <div
                                                                class=" bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100  ms-n1 mt-n1 ">
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center">
                                                            <a (click)="getFilesFacture(facture.id)"
                                                                style="cursor: pointer"
                                                                class="mb-1 text-gray-800 text-hover-primary">{{facture.refFacture}}
                                                            </a>
                                                            <div class="fw-bold fs-6 text-gray-400">
                                                                {{facture.chantier.nom}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{facture.fournisseur?.nom}}
                                                </td>
                                                <td>
                                                    {{facture.date | date: "dd-MM-yyyy"}}
                                                </td>
                                                <td>
                                                    {{facture.user?.firstName}} {{facture.user?.lastName}}
                                                </td>
                                                <td>
                                                    {{facture.montant | currency: 'EUR'}}
                                                </td>
                                                <td>

                                                    <div class="fw-bold fs-6 text-gray-400" *ngIf="facture.payer">
                                                        Payé
                                                    </div>
                                                    <div class="fw-bold fs-6 text-gray-400" *ngIf="!facture.payer">
                                                        <a [routerLink]="['/dashboard/rh/facture/remboursement/',facture.id]"
                                                            class="btn btn-light btn-color-muted p-button-text btn-sm px-5">Remboursé</a>

                                                    </div>
                                                </td>
                                                <td>
                                                    <i class="pi pi-check p-button-succes p-mr-4 me-2 "
                                                        style="font-size: 2rem" *ngIf="facture.valider"></i>
                                                    <button pButton label="à valider" icon="pi pi-check"
                                                        *ngIf="!facture.valider" (click)="valider(facture.id)"
                                                        class="p-button-raised p-button-success p-button-text p-mr-2 me-2"></button>



                                                </td>
                                                <td>
                                                    <button pButton icon="pi pi-trash" (click)="deleteFacture(facture)"
                                                        class="p-button-raised p-button-danger p-button-text "></button>

                                                    <button pButton icon="pi pi-pencil" (click)="updateFacture(facture)"
                                                        class="p-button-raised p-button-primery p-button-text "></button>

                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="5">Aucun facture trouvé.</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>



                                </div>
                            </div>
                            <!--end::Table-->
                        </div>
                        <!--end::Table container-->
                    </div>
                    <!--end::Card body-->
                </div>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>


<p-dialog header="Ajouter facture" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
    [(visible)]="addFactureDialog" [style]="{width: '600px'}" [contentStyle]="{height: '600px'}" class="overflow-show">
    <div class="p-grid p-fluid flex-column">
        <div class="p-col-12 p-md-4">
            <form [formGroup]="factureForm">


                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="float-input-invalid"> Référence facture</label>
                        <input type="text" pInputText id="refFacture" required autofocus formControlName="refFacture" />
                        <small class="p-error"
                            *ngIf="submitted && factureForm.controls.refFacture.hasError('required')">La
                            Référence est requise.</small>
                    </div>

                    <div class="col-md-6">
                        <label for="currency-us">Montant TTC</label>
                        <p-inputNumber formControlName="montant" inputId="currency-us" mode="currency" currency="USD"
                            locale="en-US">
                        </p-inputNumber>
                        <small class="p-error" *ngIf="submitted && factureForm.controls.montant.hasError('required')">Le
                            Montant est requis.</small>
                    </div>

                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="float-input-invalid">Date facture</label>
                        <p-calendar formControlName="date" [disabledDays]="[0,6]" placeholder="sélectionner date"
                            dateFormat="dd-mm-yy" class="min-width-360px" [minDate]="today"></p-calendar>
                        <small class="p-error" *ngIf="submitted  && factureForm.controls.date.hasError('required')">
                            Date facture est requise.</small>
                    </div>
                    <div class="col-md-6">
                        <label for="float-input-invalid">Description</label>
                        <input type="text" pInputText id="description" required autofocus
                            formControlName="description" />
                        <small class="p-error"
                            *ngIf="submitted && factureForm.controls.description.hasError('required')">La
                            Description est requise.</small>
                    </div>

                </div>
                <div class="row mt-3">

                    <div class="col-md-10">
                        <p-fileUpload #fileInput name="file" [showUploadButton]="false" maxFileSize="1000000"
                            multiple="multiple" accept="image/*">
                        </p-fileUpload>
                        <small class="p-error" *ngIf="submitted  && this.fileInput.files.length==0">
                            Photo est requise.</small>
                    </div>

                </div>

                <div class="row mt-3">
                    <div class="col-md-6">

                        <label for="float-input-invalid">Chantier</label>
                        <p-dropdown [options]="chantiers" formControlName="chantier"
                            defaultLabel=" sélectionner chantier" placeholder="Sélectionner" optionLabel="nom"
                            class="min-width-360px">
                            <ng-template let-option pTemplate="item">
                                <span>{{option.nom}} </span>
                            </ng-template>
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted  && factureForm.controls.chantier.hasError('required')">
                            Chantier est requis.</small>
                    </div>
                    <div class="col-md-6">

                        <label for="float-input-invalid">Fournisseur</label>
                        <p-dropdown [options]="fournisseurs" formControlName="fournisseur"
                            defaultLabel=" sélectionner fournisseur" placeholder="Sélectionner" optionLabel="nom"
                            class="min-width-360px">
                            <ng-template let-option pTemplate="item">
                                <span>{{option.nom}} </span>
                            </ng-template>
                        </p-dropdown>
                        <small class="p-error"
                            *ngIf="submitted  && factureForm.controls.fournisseur.hasError('required')">
                            Chantier est requis.</small>
                    </div>

                </div>

                <div class="row mt-6">
                    <div class="col-md-4">
                        <label for="float-input-invalid">Remboursement</label>
                        <p-toggleButton [(ngModel)]="rembourser" offIcon="pi pi-times"
                            [ngModelOptions]="{standalone: true}" onLabel="remboursé" offLabel=" non remboursé"
                            [style]="{'width': '12em'}">
                        </p-toggleButton>

                    </div>
                    <div class="col-md-8" *ngIf="rembourser">
                        <label for="float-input-invalid">Ouvrier</label>
                        <p-dropdown [options]="listUser" [(ngModel)]="userRemborser" optionLabel="firstName"
                            [filter]="true" filterBy="firstName,lastName" [showClear]="true"
                            [ngModelOptions]="{standalone: true}" placeholder="Sélectionner l'ouvrier à rembourser">
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value" *ngIf="userRemborser">
                                    <div>{{ userRemborser.firstName }} {{ userRemborser.lastName }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-USER pTemplate="item">
                                <div class="country-item">
                                    <div>{{ USER.firstName }} {{ USER.lastName }}</div>

                                </div>
                            </ng-template>
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted  && this.userRemborser==null">
                            Utilisateur est requis.</small>
                    </div>
                </div>
                <button pButton pRipple label="Sauvegarder facture" *ngIf="!saved" (click)="saveFacture()"
                    icon="pi pi-plus" class="btn btn-primary p-mr-2 mt-5"></button>
                <p-progressBar mode="indeterminate" *ngIf="saved" [style]="{'height': '6px'}"></p-progressBar>
            </form>
        </div>
    </div>
</p-dialog>


<p-dialog header="Modifier facture" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
    [(visible)]="updateFactureDialog" [style]="{width: '600'}" [contentStyle]="{height: '400px'}" class="overflow-show">
    <div class="p-grid p-fluid flex-column">
        <div class="p-col-12 p-md-4">
            <form [formGroup]="factureForm">


                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="float-input-invalid"> Référence facture</label>
                        <input type="text" pInputText id="refFacture" required autofocus formControlName="refFacture" />
                        <small class="p-error"
                            *ngIf="submitted && factureForm.controls.refFacture.hasError('required')">La
                            Référence est requise.</small>
                    </div>

                    <div class="col-md-6">
                        <label for="currency-us">Montant TTC</label>
                        <p-inputNumber formControlName="montant" inputId="currency-us" mode="currency" currency="USD"
                            locale="en-US">
                        </p-inputNumber>
                        <small class="p-error" *ngIf="submitted && factureForm.controls.montant.hasError('required')">Le
                            Montant est requis.</small>
                    </div>

                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="float-input-invalid">Date facture</label>
                        <p-calendar formControlName="date" [disabledDays]="[0,6]" placeholder="sélectionner date"
                            dateFormat="dd-mm-yy" class="min-width-360px" [minDate]="today"></p-calendar>
                        <small class="p-error" *ngIf="submitted  && factureForm.controls.date.hasError('required')">
                            Date facture est requise.</small>
                    </div>
                    <div class="col-md-6">
                        <label for="float-input-invalid">Description</label>
                        <input type="text" pInputText id="description" required autofocus
                            formControlName="description" />
                        <small class="p-error"
                            *ngIf="submitted && factureForm.controls.description.hasError('required')">La
                            Description est requise.</small>
                    </div>

                </div>


                <div class="row mt-3">
                    <div class="col-md-8">

                        <label for="float-input-invalid">Chantier</label>
                        <p-dropdown [options]="chantiers" formControlName="chantier" inputId="nom"
                            defaultLabel=" sélectionner chantier" placeholder="Sélectionner" optionLabel="nom"
                            class="min-width-360px">
                            <ng-template let-option pTemplate="item">
                                <span>{{option.nom}} </span>
                            </ng-template>
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted  && factureForm.controls.chantier.hasError('required')">
                            Chantier est requis.</small>
                    </div>

                </div>
                <button pButton pRipple label="Modifier" (click)="saveUpdateFacture()" icon="pi pi-plus"
                    class="btn btn-primary p-mr-2 mt-5"></button>

            </form>
        </div>
    </div>
</p-dialog>



<p-dialog header="Image Facture" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
    [(visible)]="dialogImage" [style]="{width: '1000px'}" [contentStyle]="{height: '1000px'}" class="overflow-show">

    <p-galleria [(value)]="images" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
        [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}" [numVisible]="7"
        [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false"
        [baseZIndex]="100000">
        <ng-template pTemplate="item" let-item>
            <img [src]="item.image" style="width: 400%; display: block;" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.image" style="display: block;" />
            </div>
        </ng-template>
    </p-galleria>
    <div *ngIf="images" class="p-grid" style="max-width: 400px;">
        <div *ngFor="let image of images; let index = index;" class="p-col-3" key="index">
            <img [src]="image.image" [alt]="image.alt" style="cursor: pointer" (click)="imageClick(index)" />
        </div>
    </div>

</p-dialog>