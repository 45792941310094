<div class="content fs-6 d-flex flex-column flex-column-fluid full-height" id="kt_content">
  <div class="toolbar" id="kt_toolbar">
    <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
      <div class="
          d-flex
          flex-column
          align-items-start
          justify-content-center
          flex-wrap
          me-2
        ">
        <h1 class="text-dark fw-bolder my-1 fs-2">Prévision de paie<br /></h1>
      </div>
    </div>
  </div>

  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div class="container-xxl">
      <!--begin::Row-->
      <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh/consomamation">
        <i class="pi pi-arrow-circle-left"></i>
        Retour
      </button>
      <div class="row g-xl-8">
        <!--begin::Col-->

        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xxl-12 gy-0 gy-xxl-8">
          <!--begin::Engage Widget 1-->
          <div class="card card-xxl-stretch mb-5 mb-xl-8">
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column justify-content-between h-100">
                <!--begin::Section-->
         
                <!--end::Section-->
                <!--begin::Image-->
                <form [formGroup]="paysForm">
                  <div class="row">
                    <div class="col-12">
                      <div class="
                        forms-container
                        d-flex
                        flex-column
                        align-items-center
                        mb-10
                      ">

                        <p-dropdown class="col-6" [options]="chantiers" formControlName="chantier"
                          placeholder="Sélectionner un chantier" optionLabel="nom" [showClear]="true" class="mb-5">
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted  && paysForm.controls.chantier.hasError('required')">
                          chantier est
                          requis.</small>

                        <p-calendar formControlName="myDates" selectionMode="range" [readonlyInput]="true"
                        (onSelect)="changePeriodePays()"
                        dateFormat="dd-mm-yy"  placeholder="Sélectionner période" inputId="range"></p-calendar>
                        <small class="p-error" *ngIf="submitted  && paysForm.controls.start.hasError('required')"> La date
                          début est
                          requis.</small>
                          <small class="p-error" *ngIf="submitted  && paysForm.controls.end.hasError('required')"> La date fin
                            est
                            requis.</small>
                        <button pButton pRipple label="calculer" style="min-width: 250px" icon="pi pi-ok"
                          (click)="claculer()" class="btn btn-primary p-mr-2 mt-5"></button>

                      </div>
                    </div>
                  </div>
                </form>
                <div class="
                    flex-grow-1
                    bgi-no-repeat
                    bgi-size-contain
                    bgi-position-x-center
                    bgi-position-y-bottom
                    card-rounded-bottom
                    max-h-150px
                    min-h-150px
                  " style="
                    background-image: url('../../../assets/images/calculator.png');
                    background-size: 11%;
                  ">
                  <!--end::Image-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Engage Widget 1-->
          </div>
          <!--end::Col-->
        </div>
      </div>
    </div>
  </div>
  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div class="container-xxl">
      <div class="row g-xl-8">
        <!--begin::Col-->

        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xxl-12 gy-0 gy-xxl-8">
          <!--begin::Engage Widget 1-->
          <div class="card card-xxl-stretch mb-5 mb-xl-8">
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column justify-content-between h-100">
                <!--begin::Section-->
                <div class="mb-10 d-flex justify-content-center">
                  <div class="text-center py-2">
                    <div class="py-1" *ngIf="calculed">
                    
                        Le nombre de jours consommés entre {{startDate | date: "dd-MM-yyyy"}} et {{endDate | date: "dd-MM-yyyy"}} sur le chantier {{nomChantier}} est de : <span style="font-weight: bold;font-size: 18px;">{{hj}}  H/J</span> 
                <br>
                        L’objectif de la situation du chantier {{nomChantier}} entre {{startDate  | date: "dd-MM-yyyy"}} et {{endDate  | date: "dd-MM-yyyy"}} est
                        :  <span style="font-weight: bold;font-size: 18px;">{{situation | currency: 'EUR'}}  </span>
                      
                    </div>
                  </div>
                  <!--end::Action-->
                </div>

                <!--end::Wrapper-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Engage Widget 1-->
          </div>
          <!--end::Col-->
        </div>
      </div>
    </div>
  </div>
</div>