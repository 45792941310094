import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PointageService } from './pointage.service';


const CONFIRMED = "confirmed";
const ABSENT = "absent";
const POINTED_CONFIRMED = "pointed_confirmed";
const ANNULER_POINTAGE_CONFIRMED = "annuler_pointed_confirmed";
const ANNULER_ABSENT = "annuler_absent";

@Component({
  selector: 'app-verfi-pointage',
  templateUrl: './verfi-pointage.component.html',
  styleUrls: ['./verfi-pointage.component.css'],
  providers: [MessageService]
})
export class VerfiPointageComponent implements OnInit {
  UpdateOuvrierDialog = false;
  submitted: boolean;
  divisie= true;
  chantiers: any[];
  ouvriers: any[];
  chantierSelect
  datePointage
  datePointageForm: FormGroup;
  chantier
  ouvrierUpdate: any;
  updatePointageDialog
  entryDateUpdate
  exitDateUpdate

  constructor(private pointageService: PointageService, public datepipe: DatePipe, private router: ActivatedRoute
   ,private fb: FormBuilder, private confirmationService: ConfirmationService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getAllChantier()

    this.router.params.subscribe(
      () => {
        this.chantier = this.router.snapshot.paramMap.get('chantier');
        let date = this.router.snapshot.paramMap.get('date');
   
        this.datePointage = new Date(date)
        if (this.chantier!=null) {
          this.pointageService.getChantierById(this.chantier).subscribe((response: any) => {
            this.chantierSelect = response

            this.getPointageByIdChantierAndDate(this.chantierSelect.id,this.datepipe.transform( this.datePointage, "yyyy-MM-dd HH:mm") )
          }) 
        }
      });
   
  }


  getAllChantier() {
    this.pointageService.getAllChantier().subscribe((response: any) => {
      this.chantiers = response
    })
  }
  getPointageByIdChantierAndDate(id, date) {
    // const control = <FormArray>this.datePointageForm.controls['pointage'];
    // if (control.length!=0) {
    //   control.controls = [];
    // }
  
    this.pointageService.getPointageByIdChantierAndDate(id, date).subscribe((response: any) => {
      this.ouvriers = response
      this.ouvriers.forEach(element => {
        this.pointageService.findImageUser(element.user.email).subscribe((rep: any) => { 
          console.log(rep);  
          if (rep) {     
            this.addImage(element,rep)
          }
        
        })
  
      });
    })
 
  }

  addImage(obj, key) {
    obj["image"] = key

  }
  changeDateGetPointageOuvrier() {
    let Datet = this.datepipe.transform(this.datePointage, "yyyy-MM-dd")
    this.getPointageByIdChantierAndDate(this.chantierSelect.id,Datet)

  }
  changeStatusUser(event: any, ouvrier) {
    if (event.target.value == CONFIRMED) {
      if (ouvrier.entryDate==null||ouvrier.exitDate==null) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Ajouter date entrée/sortie !'});
         return
       }
       if (ouvrier.entryDate>ouvrier.exitDate) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
        return;
      }
      let status = {
        "pointed": true,
        "confirmed": true,
        "absent": false
      }

      this.pointageService.changeStatus(status, ouvrier.id).subscribe(
        (msg) => {
          console.log(msg)
        },
        (error) => console.log(error)
        ,
        () => {
          this.getPointageByIdChantierAndDate(this.chantierSelect.id,this.datepipe.transform( this.datePointage, "yyyy-MM-dd HH:mm") )
        }
      );
    } else
      if (event.target.value == POINTED_CONFIRMED) {
       if (ouvrier.entryDate==null||ouvrier.exitDate==null) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Ajouter date entrée/sortie !'});
         return
       } 
      
        let status = {
          "pointed": true,
          "confirmed": true,
          "absent": false
        }

        this.pointageService.changeStatus(status, ouvrier.id).subscribe(
          (msg) => {
            console.log(msg)
          },
          (error) => console.log(error)
          ,
          () => {
            this.getPointageByIdChantierAndDate(this.chantierSelect.id,this.datepipe.transform( this.datePointage, "yyyy-MM-dd HH:mm") )
          
          }
        );
      }
      else if (event.target.value == ABSENT) {
  
        var status = {
          "pointed": false,
          "confirmed": false,
          "absent": true
        }
        this.pointageService.changeStatus(status, ouvrier.id).subscribe(
          (msg) => {
            console.log(msg)
          },
          (error) => console.log(error)
          ,
          () => {
            this.getPointageByIdChantierAndDate(this.chantierSelect.id,this.datepipe.transform( this.datePointage, "yyyy-MM-dd HH:mm") )
          }
        );
      }
      else if (event.target.value == ANNULER_POINTAGE_CONFIRMED) {
        var status = {
          "pointed": false,
          "confirmed": false,
          "absent": false
        }
        this.pointageService.changeStatus(status, ouvrier.id).subscribe(
          (msg) => {
            console.log(msg);
          },
          (error) => {
            console.log(error);
          }
          ,
          () => {
            console.log("ok");
            this.getPointageByIdChantierAndDate(this.chantierSelect.id,this.datepipe.transform( this.datePointage, "yyyy-MM-dd HH:mm") )
          }
        );
      }
      else if (event.target.value == ANNULER_ABSENT) {
        var status = {
          "pointed": false,
          "confirmed": false,
          "absent": false
        }
        this.pointageService.changeStatus(status, ouvrier.id).subscribe(
          (msg) => {
            console.log(msg);
          },
          (error) => console.log(error)
          ,
          () => {
            this.getPointageByIdChantierAndDate(this.chantierSelect.id,this.datepipe.transform( this.datePointage, "yyyy-MM-dd HH:mm") )
          }
        );
      }

  }

  deletePointage(id) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de supprimer le pointage ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.pointageService.deletePointage(id).subscribe(
          (msg) => {
            console.log(msg)
           
          },
          (error) => {
            console.log(error),
            this.messageService.add({severity:'error', summary:'Erreur', detail:'Pointage non supprimé'});
          },
          () => {

            this.getPointageByIdChantierAndDate(this.chantierSelect.id, this.datePointage)
            this.messageService.add({severity:'info', summary:'Confirmation', detail:'Pointage supprimé'});
          }
        );        
      }
  });  
  }

  updatePointage(ouvrier){
    this.updatePointageDialog=true
    this.submitted=false;
    this.entryDateUpdate=null;
    this.exitDateUpdate=null;
    if (ouvrier.entryDate!=null) {
      this.entryDateUpdate=new Date( ouvrier.entryDate) 
    }
  if (ouvrier.exitDate!=null) {
    this.exitDateUpdate=new Date( ouvrier.exitDate)
  }
    this.ouvrierUpdate=ouvrier;
  }
  saveUpdatePointage(id){
    this.submitted=true;
    if (this.entryDateUpdate==null||this.exitDateUpdate==null ) {

      return
    }

    
    if (this.entryDateUpdate>this.exitDateUpdate) {
      this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
      return;
    }
    let status = {
      "entryDate": this.datepipe.transform(this.entryDateUpdate, "yyyy-MM-dd HH:mm"),
      "exitDate": this.datepipe.transform(this.exitDateUpdate, "yyyy-MM-dd HH:mm"),
    }
    this.pointageService.changeStatus(status, id).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => console.log(error)
      ,
      () => {

        this.getPointageByIdChantierAndDate(this.chantierSelect.id,this.datepipe.transform( this.datePointage, "yyyy-MM-dd HH:mm") )

      }
    );


    this.updatePointageDialog=false
  }

}
