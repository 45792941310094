import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ProfileService } from './profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user
  detail
  salaire
  url = '';
  selected: boolean = false;
  image
  ouvrierForm: FormGroup;
  submitted: boolean = false;
  edit: boolean = false;
  initialImage
  roleConducteur: boolean = false;
  roleChef: boolean = false;
  roleRH: boolean = false;
email
  constructor(private profileService: ProfileService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.email = localStorage.getItem('email');
    this.getUserByEmail(this.email);

    this.ouvrierForm = new FormGroup({
      id: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]),
      email: new FormControl('', [Validators.required, Validators.email])
    })


    const authorities = localStorage.getItem('authorities');
    if (authorities == "CONDUCTEUR_TRAVAUX") {
      this.roleConducteur = true;
    } else if (authorities == "RH") {
      this.roleChef = true;
    } else {
      this.roleChef = true;
    }

  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.image = event.target.files[0]

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.detail.image = event.target.result;
        this.selected = true;
      }
    }
  }

  onDeselectFile() {
    this.detail.image = this.initialImage;
    this.selected = false;
  }

  onUpload() {
    if (this.image) {
      this.profileService.postFile(this.image, this.email).subscribe(
        (msg) => {
          console.log(msg)

        },
        (error) => {
          console.log(error);
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Photo de profile non changer', life: 3000 })
        },
        () => {
          this.selected = false;
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Photo de profile changer', life: 3000 });
          this.ngOnInit();
        }
      );
    }
  }

  getUserByEmail(email) {
    this.profileService.getUserByEmail(email).subscribe((response: any) => {
      this.user = response
      this.getDetailsByIdUser(response.id);
      this.getSalaireByIdUser(response.id);
    })
  }

  getDetailsByIdUser(id) {
    this.profileService.getDetailsByIdUser(id).subscribe((response: any) => {
      this.detail = response
      this.initialImage = this.detail.image;
    })
  }

  getSalaireByIdUser(email) {
    this.profileService.getSalaireByIdUser(email).subscribe((response: any) => {
      this.salaire = response
    })
  }

  updateUser() {
    this.edit = true;
    this.ouvrierForm.patchValue(this.user)
  }
  AnnulerUpdateUser() {
    this.edit = false;

  }
  sauvgarderUpdateUser() {
    this.submitted = true;
    if (this.ouvrierForm.invalid) {
      return;
    }

    this.profileService.updateUser(this.ouvrierForm.value).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
        this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Profile Non modifié', life: 3000 })
      },
      () => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Profile Modifié', life: 3000 });
        this.edit = false;
        this.ngOnInit();
      }
    );

  }

}
