<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<div class="content fs-6 d-flex flex-column flex-column-fluid full-height" id="kt_content">
  <div class="container-fluid d-flex flex-stack mb-5 flex-wrap flex-sm-nowrap" style="justify-content: end">
    <!--begin::Info-->
    <!--end::Info-->
    <!--begin::Actions-->
    <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
      <button pButton pRipple label="Ajouter ouvrier" (click)="openNew()" icon="pi pi-plus"
        class="btn btn-primary p-mr-2"></button>
    </div>
    <!--end::Actions-->
  </div>

  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
    <div class="container">

      <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh">

        <i class="pi pi-arrow-circle-left"></i>
        retour
      </button>
      <div class="row g-5 gx-xxl-12 mb-xxl-3">
        <div class="col-xxl-12" data-select2-id="select2-data-139-ub4n">
          <div class="card card-xxl-stretch mb-xl-3">
            <div class="card-header border-0 pt-5 pb-3" data-select2-id="select2-data-138-w70k">
              <h3 class="card-title align-items-start flex-column">

                <span class="card-label fw-boldest text-gray-800 fs-2">Liste des ouvriers
                </span>
              </h3>

              <div class="card-toolbar">
                <!--begin::Search-->
                <div class="w-200px position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="
                      svg-icon svg-icon-3 svg-icon-gray-500
                      position-absolute
                      top-50
                      translate-middle
                      ms-6
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input type="text" class="form-control form-control-sm form-control-solid  ps-10" name="Chercher"
                    (input)="dt.filterGlobal($event.target.value, 'contains')" value="" placeholder="Search" />
                </div>
                <!--end::Search-->
              </div>
            </div>
            <div class="card-body py-0">
              <!--begin::Table-->
              <div class="table-responsive">

                <p-table #dt [value]="ouvriers" [rows]="5" [paginator]="true" [showCurrentPageReport]="true"
                  [globalFilterFields]="['firstName','lastName']" [rowHover]="true" >
                  <ng-template pTemplate="header">
                    <tr class=" text-start text-gray-400  fw-boldest fs-7 text-uppercase" class="tr-hover">
                      <th class="min-w-200px px-0" pSortableColumn="nom">Nom<p-sortIcon field="nom"></p-sortIcon>
                      </th>
                      <th class="min-w-125px" pSortableColumn="numTel">Prénom <p-sortIcon field="client.nom">
                        </p-sortIcon>
                      </th>
                      <th  pSortableColumn="numTel" style="width: 200px" >Email <p-sortIcon field="client.nom">
                        </p-sortIcon>
                      </th>
                      <th class="min-w-125px">Télèphone</th>
                      <th class="min-w-125px" style="width: 180px">Rôles </th>

                      <th class="">Action</th>

                      <th class=""></th>
                      <th class=""></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-ouvrier>
                    <tr>
                      <td class="p-0">
                        <a class="d-flex align-items-center" (click)="showDialogMoreDetails(ouvrier)"  style="cursor: pointer">
                          <div class="symbol symbol-50px me-2">
                            <span class="symbol-label">
                              <img alt="" class="w-25px" src="../../../assets/images/aven.svg" />
                            </span>
                          </div>
                          <div>
                            {{ouvrier.firstName}}

                          </div>
                        </a>
                      </td>
                      <td>
                        
                        <span class="">{{ouvrier.lastName}}</span>
                      </td>
                      <td>
                        <span  >{{ouvrier.email}}</span>
                      </td>
                      <td>
                        <span class="">{{ouvrier.phone}}</span>
                      </td>
                      <td>
                        <span>{{ouvrier.roles[0]?.name}}</span>
                      </td>

                      <td>
                        <button pButton pRipple icon="pi pi-pencil" (click)="editOuvrier(ouvrier)"
                          class=" p-mr-2 me-5 button-edit"></button>
                        <button pButton pRipple icon="pi pi-trash" (click)=" deleteOuvrier(ouvrier)"
                          class="p-button-warning button-trash"></button>
                      </td>
                      <td>
                        <button pButton class="p-button-outlined p-button-secondary" type="button" icon="pi pi-plus"
                          (click)=" openNewSalaire(ouvrier.id)" label="Salaire" iconPos="left"></button>
                      </td>
                      <td>
                        <button pButton class="p-button-outlined p-button-secondary" type="button" icon="pi pi-plus"
                          (click)=" openNewDetail(ouvrier.id)" label="détails" iconPos="left"></button>
                      </td>
                      <!-- <td>
                        <button pButton pRipple icon="pi pi-external-link" (click)="editOuvrier(ouvrier)"style="font-size: 2rem"
                         class=" p-mr-2 button-edit"></button>
                      <button pButton pRipple icon="pi pi-plus-circle" (click)=" deleteOuvrier(ouvrier)" style="font-size: 2rem"
                        class="p-button-warning button-trash"></button>      
                      </td>
                      -->
                    </tr>
                  </ng-template>

                </p-table>
              </div>
              <!--end::Table-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog [(visible)]="ouvrierDialog" [style]="{width: '450px'}" header="Information ouvrier" [modal]="true"
  class="chantier-modal" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <form class="example-form" [formGroup]="ouvrierForm">
      <div class="p-field">
        <label for="float-input-invalid">Nom</label>
        <input type="text" pInputText id="name" required autofocus formControlName="firstName" />
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.firstName.hasError('required')">Le nom de
          famille est requis.</small>
      </div>
      <div class="p-field">
        <label for="float-input-invalid">Prénom</label>
        <input type="text" pInputText id="name" required autofocus formControlName="lastName" />
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.lastName.hasError('required')">Le prénom est
          requis.</small>
      </div>
      <div class="p-field">
        <label for="name">Email</label>
        <input type="text" pInputText id="email" required autofocus formControlName="email" />
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.email.hasError('required')">L'e-mail est
          requis.</small>
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.email.hasError('email')">Email non
          validé.</small>
      </div>
      <div class="p-field">
        <label for="float-input-invalid">Téléphone</label>
        <input type="text" pInputText id="name" required autofocus formControlName="phone" />
        <small class="p-error" *ngIf="submitted && ouvrierForm.controls.phone.hasError('required')">Le téléphone est
          requis.</small>
        <small class="p-error" *ngIf="submitted && ouvrierForm.controls.phone.hasError('pattern')">téléphone non
          validé.</small>
      </div>
      <div class="p-field">
        <label for="roles">Rôle</label>
        <p-dropdown  class="text-uppercase" [options]="listRoles" placeholder="Sélectionner" [showClear]="true"
        [(ngModel)] ="myRole" [ngModelOptions]="{standalone: true}" (onChange)="onSelectRole()"
          optionLabel="name">
          <ng-template let-option pTemplate="item">
            <span>{{ option.name }}</span>
          </ng-template>
        </p-dropdown>
        <small class="p-error" *ngIf="submitted  && ouvrierForm.controls.roles.hasError('required')">rôles est
          requis.</small>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Annuler" [hidden]="loading" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
   
    <button pButton pRipple  class="p-button-text" *ngIf="showAddButton"
    [disabled]="loading"  (click)="saveOuvrier()">
      <span class="spinner-border spinner-border-sm " *ngIf="loading" role="status" aria-hidden="true"></span>
      Sauvegarder <span *ngIf="loading">...</span>
      </button>
    <button pButton pRipple label="Modifier" icon="pi pi-check" class="p-button-text" *ngIf="!showAddButton"
      (click)="saveUpdate()"></button>

  </ng-template>
</p-dialog>

<p-dialog [(visible)]="detailDialog" [style]="{width: '700px'}" header="Détails ouvrier" [modal]="true"
  class="chantier-modal" styleClass="p-fluid">
  <ng-template pTemplate="content">

    <form [formGroup]="detailForm" class="example-form">
      <div class="row">
        <div div class="col-md-12">
          <label for="float-input-invalid">Adresse France</label>
          <input type="text" pInputText id="name" required autofocus formControlName="addressFr" />
          <small class="p-error" *ngIf="submitted  && detailForm.controls.addressFr.hasError('required')">
            L'adresse est requise.</small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <label for="float-input-invalid">Numéro de CIN </label>
          <input type="text" pInputText id="name" required autofocus formControlName="cinNumber" />
          <small class="p-error" *ngIf="submitted  && detailForm.controls.cinNumber.hasError('required')">
            Numéro de CIN est requis.</small>
        </div>
        <div class="col-md-4">
          <label for="float-input-invalid">Numéro de carte séjour </label>
          <input type="text" pInputText id="name" required autofocus formControlName="carteSejourNumber" />
        </div>

        <div class="col-md-4">
          <label for="float-input-invalid">Numéro de récépissé carte séjour </label>
          <input type="text" pInputText id="name" required autofocus formControlName="recepisse_carteSejourNumber" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label for="float-input-invalid"> Numéro de passport </label>
          <input type="text" pInputText id="name" required autofocus formControlName="passportNumber" />
          <small class="p-error" *ngIf="submitted  && detailForm.controls.passportNumber.hasError('required')">
            Numéro de passport est requis.</small>
        </div>
        <div class="col-md-6">
          <label for="float-input-invalid">Numéro de Visa </label>
          <input type="text" pInputText id="name" required autofocus formControlName="visaNumber" />
          <small class="p-error" *ngIf="submitted  && detailForm.controls.visaNumber.hasError('required')">
            Numéro de Visa est requis.</small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <label for="float-input-invalid">RIB France</label>
          <input type="text" pInputText id="name" required autofocus formControlName="ribFr" />
          <small class="p-error" *ngIf="submitted  && detailForm.controls.ribFr.hasError('required')">
           Le RIB France est requis.</small>
        </div>
        <div class="col-md-6">
          <label for="float-input-invalid">RIB Tunisie</label>
          <input type="text" pInputText id="name" required autofocus formControlName="ribTn" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label for="float-input-invalid">Numéro de sécurité sociale France</label>
          <input type="text" pInputText id="name" required autofocus formControlName="socialSecurityNumberFr" />
          <small class="p-error" *ngIf="submitted  && detailForm.controls.socialSecurityNumberFr.hasError('required')">
            Le numéro de sécurité sociale France est requis.</small>
        </div>

        <div class="col-md-6">
          <label for="float-input-invalid">Numéro de sécurité sociale Tunisie</label>
          <input type="text" pInputText id="name" required autofocus formControlName="socialSecurityNumbertn" />
        </div>       
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDetail()"></button>
    <button pButton pRipple label="Sauvegarder" icon="pi pi-check" class="p-button-text" *ngIf="showButtonAddDetails"
      (click)="saveDetails()"></button>

    <button pButton pRipple label="Modifier" icon="pi pi-check" class="p-button-text" *ngIf="!showButtonAddDetails"
      (click)="saveUpdateDetails()"></button>

  </ng-template>
</p-dialog>



<p-dialog [(visible)]="salaireDialog" [style]="{width: '700px'}" header="Salaire ouvrier" [modal]="true"
  class="chantier-modal" styleClass="p-fluid">
  <ng-template pTemplate="content">

    <form [formGroup]="salaireForm" class="example-form">
      <div class="row">
        <div div class="col-md-8">
          <label for="float-input-invalid">coût journalier moyen(CJM)</label>
          <input type="text" pInputText id="name" required autofocus formControlName="cjm" />
          <small class="p-error" *ngIf="submitted  && salaireForm.controls.cjm.hasError('required')">
            Coût journalier moyen est requis.</small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label for="float-input-invalid">Salaire NET France </label>
          <input type="text" pInputText id="name" required autofocus formControlName="salaireNetFr" />
          <small class="p-error" *ngIf="submitted  && salaireForm.controls.salaireNetFr.hasError('required')">
            Salaire NET est requis.</small>
        </div>
        <div class="col-md-6">
          <label for="float-input-invalid">Salaire NET Tunisie </label>
          <input type="text" pInputText id="name" required autofocus formControlName="salaireNetTn" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label for="float-input-invalid">Charge social France </label>
          <input type="text" pInputText id="name" required autofocus formControlName="chargeSocialFr" />
          <small class="p-error" *ngIf="submitted  && salaireForm.controls.chargeSocialFr.hasError('required')">
            Charge social est requis.</small>
        </div>
        <div class="col-md-6">
          <label for="float-input-invalid">Charge social Tunisie </label>
          <input type="text" pInputText id="name" required autofocus formControlName="chargeSocialTn" />
        </div>
      
      </div>

    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideSalaire()"></button>
    <button pButton pRipple label="Sauvegarder" icon="pi pi-check" class="p-button-text" *ngIf="showButtonAddSalaire"
      (click)="saveSalaire()"></button>

    <button pButton pRipple label="Modifier" icon="pi pi-check" class="p-button-text" *ngIf="!showButtonAddSalaire"
      (click)="saveUpdateSalaire()"></button>

  </ng-template>
</p-dialog>



<p-dialog header="Details Utilisateur" [(visible)]="userDetailsSalaireDialog" [modal]="true" [style]="{width: '50vw'}"
  [maximizable]="true" [draggable]="false" [resizable]="false" >
  <div class="details-User">
    <h3>Details</h3>
    <div class="row mb-5 ">
      <div div class="col-md-4">
        <label for="float-input-invalid">Adresse France :</label>
        {{moreDetail?.addressFr}}
  
      </div>
      <div div class="col-md-4">
        <label for="float-input-invalid">Numéro de cin :</label>
        {{moreDetail?.cinNumber}}
  
      </div>
  
      <div class="col-md-4">
        <label for="float-input-invalid">Numéro de Visa :</label>
        {{moreDetail?.visaNumber}}
  
      </div>
    </div>
    <div class="row mb-5">
      <div div class="col-md-4">
        <label for="float-input-invalid">Numéro de recepisse carte Se jour :</label>
        {{moreDetail?.recepisse_carteSejourNumber}}
  
  
      </div>
      <div div class="col-md-4">
        <label for="float-input-invalid"> Numéro de passport :</label>
        {{moreDetail?.passportNumber}}
  
      </div>
  
      <div class="col-md-4">
        <label for="float-input-invalid">Numéro de carte Se jour : </label>
        {{moreDetail?.carteSejourNumber}}
  
  
      </div>
    </div>
    <div class="row mb-5">
  
     
      <div class="col-md-6">
        <label for="float-input-invalid">Rib france :</label>
        {{moreDetail?.ribFr}}
  
      </div>
  
      <div class="col-md-6">
        <label for="float-input-invalid">Rib tunisie :</label>
        {{moreDetail?.ribTn}}
  
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-6">
        <label for="float-input-invalid">Numéro de Sécurité sociale tunisie :</label>
        {{moreDetail?.socialSecurityNumbertn}}
  
      </div>
      <div class="col-md-6">
        <label for="float-input-invalid">Numéro de Sécurité sociale france :</label>
        {{moreDetail?.socialSecurityNumberFr}}
  
      </div>
    </div>
    <hr>
    <h3>Salaire</h3>
    <div class="row mb-5">
      <div div class="col-md-8">
        <label for="float-input-invalid">cjm :</label>
        {{moreSalaire?.cjm}}
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-6">
        <label for="float-input-invalid">salaire net Fr : </label>
        {{moreSalaire?.salaireNetFr}}
      </div>
      <div class="col-md-6">
        <label for="float-input-invalid">salaire net Tn : </label>
        {{moreSalaire?.salaireNetTn}}
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-6">
        <label for="float-input-invalid">charge Social Tn :</label>
        {{moreSalaire?.chargeSocialTn}}
      </div>
      <div class="col-md-6">
        <label for="float-input-invalid">charge Social fr : </label>
        {{moreSalaire?.chargeSocialFr}}
      </div>
    </div>
  </div>
  

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="userDetailsSalaireDialog=false" label="Ok" styleClass="p-button-text">
    </p-button>
  </ng-template>
</p-dialog>