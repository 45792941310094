import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { forkJoin } from 'rxjs';
import { FactureService } from './facture.service';

@Component({
  selector: 'app-add-factures',
  templateUrl: './add-factures.component.html',
  styleUrls: ['./add-factures.component.css']
})
export class AddFacturesComponent implements OnInit {
  addFactureDialog = false;
  factureForm
  email
  submitted: boolean;
  chantiers
  today: Date;
  dateFacture: Date;
  uploadedFiles: any;
  saved: boolean;
  currentDate = new Date();
  listFactures
  rembourser: boolean=false;
  dialogImage= false;
  images
  listUser
  userRemborser
  displayCustom: boolean;
  fournisseurs
  
  @ViewChild('fileInput') fileInput: FileUpload; 

    activeIndex: number = 0;
  responsiveOptions:any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
]; 

  constructor(private factureService: FactureService, public datepipe: DatePipe) { }

  ngOnInit(): void {
   this.email = localStorage.getItem('email');
  
   this.getFactureByChantierAndDate(this.email,this.datepipe.transform(this.currentDate, "yyyy-MM-dd"),this.datepipe.transform(this.currentDate, "yyyy-MM-dd"))
    this.getAllChantier();
    this.getAllFournisseur()
    this.getAllUser();
  this.factureForm = new FormGroup({

    description: new FormControl('', [Validators.required]),
    refFacture: new FormControl('', [Validators.required]),
    montant: new FormControl('', [Validators.required]),
   chantier: new FormControl('', [Validators.required]),
   fournisseur: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
  });
}

getAllUser() {
  this.factureService.getAllUser().subscribe((response: any) => {
    this.listUser = response
  })
}

getAllChantier() {
  this.factureService.getAllChantier().subscribe((response: any) => {
    this.chantiers = response
  })
}

getAllFournisseur() {
  this.factureService.getAllFournisseur().subscribe((response: any) => {
    this.fournisseurs = response

  })
}

getFactureByChantierAndDate(email, startDate, endDate){
  this.factureService.getFacturesByUserAandDate(email, startDate, endDate).subscribe((response: any) => {
    this.listFactures = response   
  })
}

changeDateGetFacture() {

  let Datet = this.datepipe.transform(this.dateFacture, "yyyy-MM-dd")
  this.getFactureByChantierAndDate(this.email, Datet,Datet)

}

openNewFacture() {
  this.submitted = false;
  this.addFactureDialog = true;
}
saveFacture(){
  this.submitted = true;
  let promiseList = [];
  if ((this.factureForm.invalid || this.fileInput.files.length == 0) || (this.rembourser && this.userRemborser==null )) {
    return;
  }
  this.saved=true;
  this.fileInput.files.forEach(file => {
    promiseList.push(file);
    });
     this.factureService.saveFacture(this.factureForm.value,this.rembourser,promiseList,this.email, this.userRemborser).subscribe(
      (msg) => {
        console.log(msg),
        this.saved=false;
        this.fileInput.clear();
        this.rembourser=false;
        this.factureForm.reset();
        this.addFactureDialog = false; 
      },
      (error) => {
        console.log(error),
        this.saved=false;
        this.fileInput.clear();
        this.rembourser=false;
        this.factureForm.reset();
        this.addFactureDialog = false; 
            },
      () => {
        this.getFactureByChantierAndDate(this.email,this.datepipe.transform(this.currentDate, "yyyy-MM-dd"),this.datepipe.transform(this.currentDate, "yyyy-MM-dd"))
      }
    );


}

getFilesFacture(idFacture){
  this.factureService.getFilesFacture(idFacture).subscribe((response: any) => {
    this.images = response
    this.dialogImage = true
  })

}
  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
}


}