import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ChantierService } from './chantier.service';


const CONFIRMED = "confirmed";
const ABSENT = "absent";
const POINTED_CONFIRMED = "pointed_confirmed";
const ANNULER_POINTAGE_CONFIRMED = "annuler_pointed_confirmed";
const ANNULER_ABSENT = "annuler_absent";

@Component({
  selector: 'app-chantier',
  templateUrl: './chantier.component.html',
  styleUrls: ['./chantier.component.css'],
  styles: [`
  :host ::ng-deep .p-dialog .product-image {
      width: 150px;
      margin: 0 auto 2rem auto;
      display: block;
   :host ::ng-deep .p-cell-editing {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
  }
`],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [MessageService, ConfirmationService]
})
export class ChantierComponent implements OnInit {
  ouvriers: any[];
  ouvrierUpdate: any;
  currentDate = new Date();
  chantierName
  conducteur: boolean;
  datePointage: FormGroup;
  submitted: Boolean = false;
  updatePointageDialog
  validIndex
  entryDateUpdate
  exitDateUpdate

  constructor(private chantierService: ChantierService, private router: ActivatedRoute,
    private messageService: MessageService, public datepipe: DatePipe, private fb: FormBuilder,
    private confirmationService: ConfirmationService
  ) { }



  ngOnInit(): void {
    this.router.params.subscribe(
      () => {
        let id = this.router.snapshot.paramMap.get('id');
        this.getPointageByIdChantierAndDate(id);
        this.getChantierById(id);
      })

    const authorities = localStorage.getItem('authorities');
    if (authorities == "CONDUCTEUR_TRAVAUX") {
      this.conducteur = true;
    } else {
      this.conducteur = false;
    }
    this.datePointage = this.fb.group({
      pointage: this.fb.array([])
    });
   
  }
  createForms(p) {
    return this.fb.group({
      "entryDate": [this.datepipe.transform(p.entryDate, "yyyy-MM-dd HH:mm"), [Validators.required]],
      "exitDate": [this.datepipe.transform(p.exitDate, "yyyy-MM-dd HH:mm"), [Validators.required]]
    });
  }
  addForms(listPointage) {
    listPointage.forEach(element => {
      const control = <FormArray>this.datePointage.controls['pointage'];
      control.push(this.createForms(element));
   
    });
  }


  getPointageByIdChantierAndDate(id) {
    this.chantierService.getPointageByIdChantierAndDate(id).subscribe((response: any) => {
      this.ouvriers = response
      this.addForms(this.ouvriers)

      this.ouvriers.forEach(element => {
        this.chantierService.findImageUser(element.user.email).subscribe((rep: any) => { 
          console.log(rep);  
          if (rep) {     
            this.addImage(element,rep)
          } 
        })
      });
    })
  }

  addImage(obj, key) {
    obj["image"] = key

  }

  getChantierById(id) {
    this.chantierService.getChantierById(id).subscribe((response: any) => {
      this.chantierName = response.nom;

    })
  }

  // paginate(event) {
  //   console.log(event.first);
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
  // }

  updatePointage(ouvrier){
    this.updatePointageDialog=true
    if (ouvrier.entryDate!=null) {
      this.entryDateUpdate=new Date( ouvrier.entryDate) 
    }
  if (ouvrier.exitDate!=null) {
    this.exitDateUpdate=new Date( ouvrier.exitDate)
  }
    this.ouvrierUpdate=ouvrier;
  }

  saveUpdatePointage(id){
    this.submitted=true;
    if (this.entryDateUpdate==null||this.exitDateUpdate==null ) {
      return
    }
    if (this.entryDateUpdate>this.exitDateUpdate) {
      this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
      return;
    }
    let status = {
      "entryDate": this.datepipe.transform(this.entryDateUpdate, "yyyy-MM-dd HH:mm"),
      "exitDate": this.datepipe.transform(this.exitDateUpdate, "yyyy-MM-dd HH:mm"),
    }

    this.chantierService.changeStatus(status, id).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => console.log(error)
      ,
      () => {

        this.ngOnInit();
      }
    );


    this.updatePointageDialog=false
  }


  changeStatusUser(event: any, id, rowIndexValue) {
    let entryDate = this.datePointage.get('pointage')['controls'][rowIndexValue].controls.entryDate.value;
    let exitDate = this.datePointage.get('pointage')['controls'][rowIndexValue].controls.exitDate.value;

    if (event.target.value == CONFIRMED) {
      this.validIndex = rowIndexValue;      
      if (entryDate == null || exitDate == null) {
        return;
      }

      if (entryDate>exitDate) {
        this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
        return;
      }
      let status = {
        "pointed": true,
        "confirmed": true,
        "absent": false,
         "entryDate": this.datepipe.transform(entryDate, "yyyy-MM-dd HH:mm"),
        "exitDate": this.datepipe.transform(exitDate, "yyyy-MM-dd HH:mm")      
      }

      this.chantierService.changeStatus(status, id).subscribe(
        (msg) => {
          console.log(msg)
        },
        (error) => console.log(error)
        ,
        () => {
          this.ngOnInit();
        }
      );
    } else
      if (event.target.value == POINTED_CONFIRMED) {
        this.validIndex = rowIndexValue;      
        if (entryDate == null || exitDate == null) {
          return;
        }
        if (entryDate>exitDate) {
          this.messageService.add({severity:'error', summary:'Erreur', detail:'Date de sortie non validé'});
          return;
        }
        let status = {
          "pointed": true,
          "confirmed": true,
          "entryDate": this.datepipe.transform(entryDate, "yyyy-MM-dd HH:mm"),
          "exitDate": this.datepipe.transform(exitDate, "yyyy-MM-dd HH:mm"),
          "absent": false
        }
        this.chantierService.changeStatus(status, id).subscribe(
          (msg) => {
            console.log(msg)
          },
          (error) => console.log(error)
          ,
          () => {
            this.ngOnInit();
            this.validIndex = null
          }
        );
      }
      else if (event.target.value == ABSENT) {
        this.validIndex = null
        var status = {
          "pointed": false,
          "confirmed": false,
          "absent": true
        }
        this.chantierService.changeStatus(status, id).subscribe(
          (msg) => {
            console.log(msg)
          },
          (error) => console.log(error)
          ,
          () => {
            this.ngOnInit();
          }
        );
      }
      else if (event.target.value == ANNULER_POINTAGE_CONFIRMED) {
        var status = {
          "pointed": false,
          "confirmed": false,
          "absent": false
        }
        this.chantierService.changeStatus(status, id).subscribe(
          (msg) => {
            console.log(msg);
          },
          (error) => {
            console.log(error);
          }
          ,
          () => {
            console.log("ok");
            this.ngOnInit();
          }
        );
      }
      else if (event.target.value == ANNULER_ABSENT) {
        var status = {
          "pointed": false,
          "confirmed": false,
          "absent": false
        }
        this.chantierService.changeStatus(status, id).subscribe(
          (msg) => {
            console.log(msg);
          },
          (error) => console.log(error)
          ,
          () => {
            this.ngOnInit();
          }
        );
      }

  }
}

