import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  baseUrl = "/api";

  constructor(private httpClient: HttpClient) { }

  getNotification() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    const email = localStorage.getItem('email');
    let params = new HttpParams();
    params = params.append('email', email);

    const notification = this.httpClient.get(environment.baseURL + environment.apiPath  + 'notification/getNotificationByUser/' , {params:params, headers: headers });
    return notification;
  }


  getCountOpenNotificationByUser() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    const email = localStorage.getItem('email');
    let params = new HttpParams();
    params = params.append('email', email);

    const open = this.httpClient.get(environment.baseURL + environment.apiPath  + 'notification/getCountOpenNotificationByUser/' , {params:params, headers: headers });
    return open;
  }
  openNotification(idNotification){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let params = new HttpParams();
    params = params.append('idNotification', idNotification);
  
    const openN = this.httpClient.put(environment.baseURL + environment.apiPath + 'notification/openNotification',idNotification, 
    {params: params, headers: headers, responseType: 'text' });
    return openN; 
  }


  openAllNotificationByUser(){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const email = localStorage.getItem('email');
    let params = new HttpParams();
    params = params.append('email', email);

    const openN = this.httpClient.put(environment.baseURL + environment.apiPath + 'notification/openAllNotificationByUser',email,
    {params:params, headers: headers, responseType: 'text' });
    return openN; 
  }

  deleteNotification(id) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    return this.httpClient.delete(environment.baseURL + environment.apiPath  + 'notification/' + id, 
    {headers: headers, responseType: 'text' })
  }

  deleteAllNotificationByUser() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const email = localStorage.getItem('email');
    let params = new HttpParams();
    params = params.append('email', email);
    return this.httpClient.delete(environment.baseURL + environment.apiPath  + 'notification/deleteAllNotificationByUser',
     {params:params,headers: headers, responseType: 'text' })
  }


  getUserByEmail() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const email = localStorage.getItem('email');
    let params = new HttpParams();
    params = params.append('email', email);
    const users = this.httpClient.get(environment.baseURL + environment.apiPath  + 'users/getUserByEmail' , 
    {params: params, headers: headers });
    return users;
  }

  getDetailsByIdUser(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'user-details/findUserDetailsByUser/'+id , 
    { headers: headers });
    return details; 
  }
}
