import { UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OuvrierService } from './ouvrier.service';




@Component({
  selector: 'app-ouvriers',
  templateUrl: './ouvriers.component.html',
  styleUrls: ['./ouvriers.component.css']
})
export class OuvriersComponent implements OnInit {
  ouvriers: any[];
  details: any[];
  salaires: any[];
  detailUserId
  salaireUserId
  ouvrierDialog: boolean;
  salaireDialog: boolean;
  detailDialog: boolean;
  userDetailsSalaireDialog: boolean;
  submitted: boolean;
  ouvrierForm: FormGroup;
  salaireForm: FormGroup
  detailForm: FormGroup;
  showAddButton: boolean = true;
  showButtonAddDetails: boolean = true;
  showButtonAddSalaire: boolean = true;
  loading: boolean = false;

  moreSalaire
  moreDetail
  listRoles: any[];
  myRole
  //  = [['rh'], ['ouvrier'], ['chef_chantier'],['conducteur_travaux'] ];
  constructor(private ouvrierService: OuvrierService, private messageService: MessageService
    , private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.ouvrierForm = new FormGroup({
      id: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      roles: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]),
      email: new FormControl('', [Validators.required, Validators.email])
    })

    this.detailForm = new FormGroup({
      id: new FormControl(),
      addressFr: new FormControl('', [Validators.required]),
      carteSejourNumber: new FormControl(''),
      cinNumber: new FormControl('', [Validators.required]),
      passportNumber: new FormControl('', [Validators.required]),
      recepisse_carteSejourNumber: new FormControl(''),
      ribFr: new FormControl('', [Validators.required]),
      ribTn: new FormControl(''),
      socialSecurityNumbertn: new FormControl(''),
      socialSecurityNumberFr: new FormControl('', [Validators.required]),
      visaNumber: new FormControl('', [Validators.required])
    })


    this.salaireForm = new FormGroup({
      id: new FormControl(),
      cjm: new FormControl('', [Validators.required]),
      salaireNetFr: new FormControl('', [Validators.required]),
      salaireNetTn: new FormControl(''),
      chargeSocialTn: new FormControl(''),
      chargeSocialFr: new FormControl('', [Validators.required])

    })


    this.getAllUser();
    this.getAllRoles();
  }
  getAllUser() {
    this.ouvrierService.getAllUser().subscribe((response: any) => {
      this.ouvriers = response
    })
  }

  getAllRoles() {
    this.ouvrierService.getAllRoles().subscribe((response: any) => {
      this.listRoles = response

    })
  }

  openNew() {
    this.submitted = false;
    this.ouvrierDialog = true;
    this.ouvrierForm.reset();
    this.myRole = "";
    this.showAddButton = true;
  }

  editOuvrier(user) {
    this.showAddButton = false;
    this.ouvrierForm.patchValue(user)
    let index = this.listRoles.filter(x => x.name === user.roles[0].name);
    this.myRole = index[0]
    this.ouvrierDialog = true;
  }


  hideDialog() {
    this.ouvrierDialog = false;
    this.submitted = false;
  }

  onSelectRole() {
    if (this.myRole) {

      this.ouvrierForm.controls.roles.setValue([this.myRole.name.toLowerCase()])
    }
  }


  saveOuvrier() {
    this.submitted = true;
    if (this.ouvrierForm.invalid) {
      return;
    }
    this.loading = true;
    this.ouvrierService.addUser(this.ouvrierForm.value).subscribe(
      (msg) => {
        console.log(msg)

      },
      (error) => {
        this.ouvrierForm.reset();
        this.ouvrierDialog = false;
        this.loading = false;
        console.log(error);
        this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Ouvrier non créé', life: 3000 })
      },
      () => {
        this.ouvrierForm.reset();
        this.ouvrierDialog = false;
        this.loading = false;
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Ouvrier créé', life: 3000 });
        this.getAllUser();
      }
    );
   
  }
  saveUpdate() {
    this.submitted = true;
    if (this.ouvrierForm.invalid) {
      return;
    }
    this.ouvrierForm.controls.roles.setValue([this.myRole.name.toLowerCase()])
    this.ouvrierService.updateUser(this.ouvrierForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Ouvrier modifié', life: 3000 });

      },
      (error) => {
        console.log(error),
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Ouvrier non modifié', life: 3000 })
      },
      () => {
        this.getAllUser();
      }
    );
    this.ouvrierDialog = false;
    this.showAddButton = true;

  }


  deleteOuvrier(user) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Etes-vous sûr que vous voulez supprimer ' + user.firstName + '?',
      header: 'Confirmer',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {


        this.ouvrierService.deleteUser(user.id).subscribe(
          (msg) => {
            console.log(msg)
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Ouvrier Supprimé', life: 3000 });
          },
          (error) => {
            console.log(error),
              this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Ouvrier Non Supprimé', life: 3000 })

          },
          () => {
            this.getAllUser();
          }
        );
      }
    });
  }
  // details
  openNewDetail(id) {
    this.showButtonAddDetails = true
    this.getDetailsByIdUser(id)
    this.detailUserId = id
    this.submitted = false;
    this.detailDialog = true;
    this.detailForm.reset();

  }

  hideDetail() {
    this.detailDialog = false;
    this.submitted = false;
  }

  getDetailsByIdUser(id) {
    this.ouvrierService.getDetailsByIdUser(id).subscribe((response: any) => {
      this.details = response
      if (response) {
        this.showButtonAddDetails = false
      }
      this.detailForm.patchValue(this.details)
    })

  }

  saveDetails() {
    this.submitted = true;
    if (this.detailForm.controls["addressFr"].invalid || this.detailForm.controls["cinNumber"].invalid
      || this.detailForm.controls["passportNumber"].invalid || this.detailForm.controls["ribFr"].invalid
      || this.detailForm.controls["socialSecurityNumberFr"].invalid || this.detailForm.controls["visaNumber"].invalid) {
      return;
    }

    this.ouvrierService.addDetails(this.detailUserId, this.detailForm.value).subscribe(
      (msg) => {
        console.log(msg)

      },
      (error) => {
        console.log(error)
      },
      () => {

        this.getAllUser();
      }
    );
    this.detailForm.reset();
    this.detailDialog = false;
  }
  saveUpdateDetails() {

    this.ouvrierService.updateDetailsUser(this.detailForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Détails utilisateur modifié', life: 3000 });

      },
      (error) => {
        console.log(error),
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Détails utilisateur ne pas modifié', life: 3000 })
      },
      () => {
        this.getAllUser();
      }
    );
    this.detailDialog = false;
    this.showButtonAddDetails = true;
  }
  //salaire

  openNewSalaire(id) {
    this.showButtonAddSalaire = true
    this.getSalaireByIdUser(id);
    this.salaireUserId = id
    this.submitted = false;
    this.salaireDialog = true;
    this.salaireForm.reset();

  }
  hideSalaire() {
    this.salaireDialog = false;
    this.submitted = false;
  }


  getSalaireByIdUser(id) {
    this.ouvrierService.getSalaireByIdUser(id).subscribe((response: any) => {
      this.salaires = response
      if (response) {
        this.showButtonAddSalaire = false
      }
      this.salaireForm.patchValue(this.salaires)
    })

  }

  saveSalaire() {
    this.submitted = true;

    if (this.salaireForm.controls["cjm"].invalid || this.salaireForm.controls["salaireNetFr"].invalid
      || this.salaireForm.controls["chargeSocialFr"].invalid) {
      return;
    }
    this.ouvrierService.addSalaire(this.salaireUserId, this.salaireForm.value).subscribe(
      (msg) => {
        console.log(msg)

      },
      (error) => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Salaire non créé', life: 3000 });
        console.log(error)
      },
      () => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Salaire créé', life: 3000 });
        this.getAllUser();
      }
    );
    this.salaireForm.reset();
    this.salaireDialog = false;
  }
  saveUpdateSalaire() {

    this.ouvrierService.updateSalairUser(this.salaireForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'salaire modifier', life: 3000 });

      },
      (error) => {
        console.log(error),
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'salaire non modifier', life: 3000 })
      },
      () => {
        this.getAllUser();
      }
    );
    this.salaireDialog = false;
    this.showButtonAddSalaire = true;
  }


  //all details and salaire

  showDialogMoreDetails(ouvrier) {
    this.moreSalaire = null;
    this.moreDetail = null;
    this.userDetailsSalaireDialog = true



    this.ouvrierService.getDetailsByIdUser(ouvrier.id).subscribe((response: any) => {
      this.moreDetail = response

    });
    this.ouvrierService.getSalaireByIdUser(ouvrier.id).subscribe((response: any) => {
      this.moreSalaire = response

    });




  }
}
