<div class="container-fluid content full-height mt-10">
    <!--begin::Row-->
    <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh">
      <i class="pi pi-arrow-circle-left"></i>
      Retour
    </button>
    <div class="row g-xl-8 mx-auto" style="justify-content: center">
      <!--begin::Col-->
     
      <div class="col-xl-8" style="justify-content: center">
        <!--begin::Stats Widget 5-->
        <div class="card bg-gray-100 card-xl-stretch mb-5 mb-xl-8">
          <!--begin::Body--> 
          <div class="card-body p-0 pb-9">
            <!--begin::Info-->
            <div class="
                  px-9
                  pt-6
                  rounded
                  h-250px
                  w-100
                  bgi-no-repeat bgi-size-cover bgi-position-y-top
                " style="
                  background-image: url('../../assets/images/construction.png');
                ">
              <!--begin::Header-->
              
              <!--end::Header-->
              <!--begin::Balance-->
              <div class="d-flex align-items-center align-self-center flex-wrap pt-8">
                <div class="fw-bolder fs-7 text-white flex-grow-1 pe-2">
                  Bonjour, 
                </div>
              </div>
              <!--begin::Balance-->
          
            </div>
            <!--end::Info-->
           
            <!--begin::Items-->
            <div class="mx-9 mt-n20">
              <!--begin::Row-->
             
              <div class="row g-8 row-cols-1 row-cols-sm-2">
              
                 <!--begin::Col-->
                 <div class="col mt-n2">
                    <div class="text-center bg-body shadow-xs rounded p-7">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-50px mt-1">
                        <i class="pi pi-user" style="font-size: 2rem"></i>
                      </div>
                      <!--end::Symbol-->
                      <a [routerLink]="['/dashboard/rh/clients']" class="
                          text-gray-800 text-hover-primary
                          fw-boldest
                          fs-5
                          mt-4
                          d-block
                        ">Clients</a>
                    </div>
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col mt-n2">
                    <div class="bg-body shadow-xs rounded text-center p-7">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-50px mt-1">
                        <span class="symbol-label bg-light-info">
                          <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                          <span class="svg-icon svg-icon-2qx svg-icon-info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect x="8" y="9" width="3" height="10" rx="1.5" fill="black"></rect>
                              <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="black"></rect>
                              <rect x="18" y="11" width="3" height="8" rx="1.5" fill="black"></rect>
                              <rect x="3" y="13" width="3" height="6" rx="1.5" fill="black"></rect>
                            </svg>
                          </span>
                          <!--end::Svg Icon-->
                        </span>
                      </div>
                      <!--end::Symbol-->
                      <a href="#" class="
                          text-gray-800 text-hover-primary
                          fw-boldest
                          fs-5
                          mt-4
                          d-block
                        " [routerLink]="['/dashboard/rh/chantiers']">Chantiers</a>
                    </div>
                  </div>
                  <!--end::Col-->
                       <!--begin::Col-->
                 <div class="col mt-n2">
                    <div class="text-center bg-body shadow-xs rounded p-7">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-50px mt-1">
                        <i class="pi pi-id-card" style="font-size: 2rem"></i>
                      </div>
                      <!--end::Symbol-->
                      <a [routerLink]="['/dashboard/rh/fournisseur']" class="
                          text-gray-800 text-hover-primary
                          fw-boldest
                          fs-5
                          mt-4
                          d-block
                        ">Fournisseur</a>
                    </div>
                  </div>
                  <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Items-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 5-->
      </div>
      <!--end::Col-->
      <!--begin::Col-->
  
      <!--end::Col-->
      <!--begin::Col-->
  
      <!--begin::Col-->
    </div>
  </div>
