<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<div class="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
  <div class="container-fluid d-flex flex-stack mb-5 flex-wrap flex-sm-nowrap" style="justify-content: end">
    <!--begin::Info-->
    <!--end::Info-->
    <!--begin::Actions-->


    <!--end::Actions-->
  </div>

  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
    <div class="container">



      <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">


        </ng-template>
        <ng-template pTemplate="right">
          <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
            <button pButton pRipple label="Ajouter Chantier" (click)="openNew()" icon="pi pi-plus"
              class="btn btn-primary p-mr-2"></button>
          </div>
        </ng-template>

        <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh/projet">
          <i class="pi pi-arrow-circle-left"></i>
          Retour
        </button>



      </p-toolbar>
      <div class="row g-5 gx-xxl-12 mb-xxl-3">
        <div class="col-xxl-12" data-select2-id="select2-data-139-ub4n">
          <div class="card card-xxl-stretch mb-xl-3">
            <div class="card-header border-0 pt-5 pb-3" data-select2-id="select2-data-138-w70k">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-boldest text-gray-800 fs-2">liste des chantiers
                </span>
              </h3>

              <div class="card-toolbar">
                <!--begin::Search-->
                <div class="w-200px position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="
                      svg-icon svg-icon-3 svg-icon-gray-500
                      position-absolute
                      top-50
                      translate-middle
                      ms-6
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input type="text" class="
                      form-control form-control-sm form-control-solid
                      ps-10
                    " name="search" value="" placeholder="Search" />
                </div>
                <!--end::Search-->
              </div>
            </div>
            <div class="card-body py-0">

              <div class="d-flex flex-column-fluid" style="background: #f5f8fa">
                <!--begin::Container-->

                <div class="container">
                  <div class="row mb-5 mt-5">
                    <div class="col-xxl-3 col-xl-6 col-md-6 col-sm-6" *ngFor="let chantier of chantiers; index as i">
                      <!--begin::Card-->
                      <div class="card card-custom gutter-b card-stretch mb-5">
                        <!--begin::Body-->
                        <div class="card-body d-flex justify-content-center flex-column">
                          <!--begin::Toolbar-->

                          <!--end::Toolbar-->
                          <!--begin::User-->
                          <div class="d-flex align-items-end py-2 mb-5">
                            <!--begin::Pic-->
                            <div class="d-flex align-items-center">
                              <!--begin::Pic-->
                              <div class="d-flex flex-shrink-0 mr-5">
                                <div class="symbol symbol-50px me-2">
                                  <span class="symbol-label">
                                    <img alt="" class="w-25px" src="../../../assets/images/aven.svg" />
                                  </span>
                                </div>
                              </div>
                              <!--end::Pic-->
                              <!--begin::Title-->
                              <div class="d-flex flex-column">
                                {{chantier.nom}}
                                <span class="text-gray-400 fw-bold d-block"> {{chantier.client.nom}} </span>
                              </div>
                              <!--end::Title-->
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::User-->
                          <!--begin::Desc-->

                          <!--end::Desc-->
                          <!--begin::Contacts-->
                          <div class="py-2">
                           
                            <div class="d-flex align-items-center mb-5">
                              <i class="pi pi-phone"></i>
                              <span class="text-muted font-weight-bold ms-3"> {{ chantier.numTel }}</span>
                            </div>
                            <div class="d-flex align-items-center mb-5">
                              <i class="pi pi-envelope"></i>
                              <a class="text-muted text-hover-primary font-weight-bold ms-3">{{ chantier.email
                                }}</a>
                            </div>
                            <div class="d-flex align-items-center mb-5">
                              <i class="pi pi-map-marker"></i>
                              <a class="text-muted text-hover-primary font-weight-bold ms-3">{{ chantier.adresse }}
                                {{ chantier.ville }}
                                {{ chantier.codePostal }}</a>
                            </div>

                            <div class="d-flex align-items-center mb-5">
                              <i class="pi pi-calendar-minus"></i>
                              <a class="text-muted text-hover-primary font-weight-bold ms-3">
                                {{ chantier.startDate| date: " d MMM, y" }}
                                </a>
                                <i class="pi pi-calendar-plus  ms-3"></i>
                                <a class="text-muted text-hover-primary font-weight-bold ms-3">
                                  {{ chantier.endDate | date: " d MMM, y"}}
                                </a>
                               
                            </div>
                            <div class="d-flex align-items-center mb-5">
                              <i class="pi pi-euro"></i>
                              <span class="text-muted font-weight-bold ms-3">
                                 {{ chantier.chiffre_affaire|currency:"EUR":"symbol" }}</span>
                            </div>
                          </div>
                          <!--end::Contacts-->
                          <!--begin::Actions-->
                          <div class="pt-2 d-flex justify-content-end">
                            <button pButton pRipple icon="pi pi-pencil" class="p-mr-2 me-5 button-edit"
                              (click)="editChantier(chantier)"></button>
                            <button pButton pRipple icon="pi pi-trash" (click)="deleteChantier(chantier)"
                              class="p-button-warning button-trash"></button>
                          </div>
                          <!--end::Actions-->
                        </div>
                        <!--end::Body-->
                      </div>
                      <!--end::Card-->
                    </div>
                  </div>
                  <!--end::Container-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<p-dialog [(visible)]="chantierDialog" [style]="{width: '600px'}" header="Détails chantier" [modal]="true"
  class="chantier-modal" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <form class="example-form" [formGroup]="chantierForm">
      <div class="p-field">
        <label for="float-input-invalid">Nom de chantier </label>
        <input type="text" pInputText id="name" required autofocus formControlName="nom" />
        <small class="p-error" *ngIf="submitted && chantierForm.controls.nom.hasError('required')">Le nom est
          requis.</small>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="name">E-mail</label>
          <input type="text" pInputText id="email" required autofocus formControlName="email" />
          <small class="p-error" *ngIf="submitted && chantierForm.controls.email.hasError('required')">L'e-mail est
            requis..</small>
          <small class="p-error" *ngIf="submitted && chantierForm.controls.email.hasError('email')">Email non
            validé.</small>
        </div>
        <div class="col-md-6">
          <label for="name">Téléphone</label>
          <input type="text" pInputText id="Telephone" required autofocus formControlName="numTel" />
          <small class="p-error" *ngIf="submitted && chantierForm.controls.numTel.hasError('required')">Le téléphone est
            requis.</small>
          <small class="p-error" *ngIf="submitted && chantierForm.controls.numTel.hasError('pattern')">téléphone non
            validé.</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <label for="description">Description</label>
          <textarea id="project_desc" pInputTextarea required rows="3" cols="20" formControlName="project_desc">
        </textarea>
          <small class="p-error" *ngIf="
            submitted && chantierForm.controls.project_desc.hasError('required')
          ">Description est requise.</small>
        </div>
        <div class="col-md-6">
          <label for="client">Clients</label>
          <p-dropdown inputId="nom" [options]="clients" placeholder="Sélectionner" formControlName="client"
            optionLabel="nom">
            <ng-template let-option pTemplate="item">
              <span>{{ option.nom }}</span>
            </ng-template>
          </p-dropdown>
          <small class="p-error" *ngIf="submitted && chantierForm.controls.client.hasError('required')">Client est
            requis.</small>
        </div>
      </div>


      <div class="row mt-2">
        <div class="col-md-4">
          <label for="name">Addresse</label>
          <input type="text" pInputText id="adresse" required autofocus formControlName="adresse" />
          <small class="p-error" *ngIf="
              submitted && chantierForm.controls.adresse.hasError('required')
            ">L'adresse est requise.</small>
        </div>
        <div class="col-md-4">
          <label for="name">Ville</label>
          <input type="text" pInputText id="ville" required autofocus formControlName="ville" />
          <small class="p-error" *ngIf="
              submitted && chantierForm.controls.ville.hasError('required')
            ">Ville est requise.</small>
        </div>
        <div class="col-md-4">
          <label for="name">Code Postal</label>
          <input type="text" pInputText id="codePostal" required autofocus formControlName="codePostal" />
          <small class="p-error" *ngIf="
              submitted && chantierForm.controls.codePostal.hasError('required')
            ">Code Postal est requis.</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <label for="name">Chiffre d'affaire</label>
          <p-inputNumber formControlName="chiffre_affaire" inputId="currency-us" mode="currency" currency="EUR"
            locale="en-US">
          </p-inputNumber>


          <small class="p-error" *ngIf="
              submitted && chantierForm.controls.chiffre_affaire.hasError('required')
            ">Chiffre d'affaire est requis.</small>
        </div>

        <div class="col-md-4">
          <label for="name">Latitude GPS</label>
          <input type="text" pInputText id="Telephone" required autofocus formControlName="latitude" />
          <small class="p-error" *ngIf="submitted && chantierForm.controls.latitude.hasError('required')">Latitude est
            requise.</small>
          <small class="p-error" *ngIf="submitted && chantierForm.controls.latitude.hasError('pattern')">Latitude non
            validée.</small>
        </div>
        <div class="col-md-4">
          <label for="name">Longitude GPS</label>
          <input type="text" pInputText id="Telephone" required autofocus formControlName="longitude" />
          <small class="p-error" *ngIf="submitted && chantierForm.controls.longitude.hasError('required')">Longitude
            est
            requise.</small>
          <small class="p-error" *ngIf="submitted && chantierForm.controls.longitude.hasError('pattern')">Longitude
            non
            validée.</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <label for="name">Date Début</label>
          <p-calendar inputId="basic" [disabledDays]="[0,6]" id="kt_filter_search" dateFormat="dd-mm-yy"
            formControlName="startDate" [showIcon]="true" placeholder="Selectionner date début">
          </p-calendar>
          <small class="p-error" *ngIf="
              submitted && chantierForm.controls.startDate.hasError('required')
            ">Date début est requise.</small>
        </div>
        <div class="col-md-6">
          <label for="name">Date fin</label>
          <p-calendar inputId="basic" [disabledDays]="[0,6]" id="kt_filter_search" dateFormat="dd-mm-yy"
            formControlName="endDate" [showIcon]="true" placeholder="Selectionner date fin">
          </p-calendar>
          <small class="p-error" *ngIf="
              submitted && chantierForm.controls.endDate.hasError('required')
            ">Date fin est requise.</small>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Sauvegarder" icon="pi pi-check" class="p-button-text" *ngIf="showAddButton"
      (click)="saveChantier()"></button>

    <button pButton pRipple label="Modifier" icon="pi pi-check" class="p-button-text" *ngIf="!showAddButton"
      (click)="saveUpdate()"></button>
  </ng-template>
</p-dialog>