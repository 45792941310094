import { Component, OnInit } from '@angular/core';
import { ConsommationService } from 'src/app/rh/consommation/consommation.service';
import { ChantierService } from '../chantier/chantier.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  chantiers: any[];
  value: number = 0;

  constructor(private chantierService: ChantierService, private consommationService: ConsommationService) { }




  ngOnInit(): void {
    this.getAllOwners()
  }


  getAllOwners() {
    this.chantierService.getChantierBychef().subscribe((response: any) => {
      this.chantiers = response
      this.chantiers.forEach(element => {
        this.consommationService.getHommeJour(element.id, element.startDate, element.endDate).subscribe((hommejour: any) => {
          console.log("hj=", element.nom, hommejour);
          console.log("budjet=", element.chiffre_affaire / 250);
          const pourcentage = (hommejour / (element.chiffre_affaire / 250)) * 100
          element.pourcentageHj = pourcentage.toFixed(2)
          element.hj = hommejour
        })
      });
    })
  }
}
