<div id="kt_header" class="header" data-kt-sticky="true" data-kt-sticky-name="header"
    data-kt-sticky-offset="{default: '200px', lg: '300px'}">
    <!--begin::Container-->
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
        <!--begin::Logo bar-->
        <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <!--begin::Aside Toggle-->
            <div class="d-flex align-items-center d-lg-none">
                <div class="btn btn-icon btn-active-color-primary ms-n2 me-1" id="kt_aside_toggle">
                    <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                    <span class="svg-icon svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                fill="black"></path>
                            <path opacity="0.3"
                                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                fill="black"></path>
                        </svg>
                    </span>
                    <!--end::Svg Icon-->
                </div>
            </div>
            <!--end::Aside Toggle-->
            <!--begin::Logo-->
            <a href="/craft/index.html" class="d-lg-none">
                <img alt="Logo" src="/craft/assets/media/logos/logo-compact.svg" class="mh-40px">
            </a>
            <!--end::Logo-->
            <!--begin::Aside toggler-->

            <!--end::Aside toggler-->
        </div>
        <!--end::Logo bar-->
        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <!--begin::Search-->
            <div class="d-flex align-items-stretch">
                <!--begin::Search-->
                <div id="kt_header_search" class="d-flex align-items-center w-lg-400px show menu-dropdown"
                    data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter"
                    data-kt-search-layout="menu" data-kt-search-responsive="lg" data-kt-menu-trigger="auto"
                    data-kt-menu-permanent="true" data-kt-menu-placement="bottom-start" data-kt-menu-flip="bottom"
                    data-kt-search="true">
                    <!--begin::Tablet and mobile search toggle-->
                    <div data-kt-search-element="toggle" class="d-flex d-lg-none align-items-center">
                        <div class="btn btn-icon btn-icon-gray-500 btn-active-light-primary">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="black"></path>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                    </div>
                    <!--end::Tablet and mobile search toggle-->
                    <!--begin::Form-->
                    <div class="navbar-brand d-flex align-items-center px-4 px-lg-5">
                        <img class="" src="../../../assets/images/logo.png" alt="" style="width: 80px;height: 50px;">


                    </div>
                </div>
            </div>

            <div class="d-flex">
                <div class="d-flex align-items-center ms-1 ms-lg-3 position-relative "
                    style="cursor: pointer;margin-right:25px" >
                    <i class="pi pi-bell p-ml-4 p-text-secondary" pBadge style="font-size: 2rem"
                        (click)="op.toggle($event)" [value]="value"></i>
                </div>

                <div class="d-flex align-items-stretch flex-shrink-0 d-block">
                    <div class="d-flex align-items-center ms-1 ms-lg-3 position-relative" >

                        <div class="cursor-pointer symbol symbol-35px symbol-lg-35px" (click)="opk.toggle($event)">
                            <img alt="Logo" src="../../../assets/images/avtar.jpg" *ngIf="!detail?.image">
                            <img [src]="detail?.image" *ngIf="detail?.image" alt="logo"
                                class="card-img rounded-circle" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<p-overlayPanel #op [style]="{width: '450px'}">



    <div class="fw-bolder d-flex align-items-center fs-5"> Notification:
        <button type="button" class="p-button-rounded  p-button-text ml-auto d-lg-block" pButton pRipple
            icon="pi pi-ellipsis-v" (click)="menu.toggle($event)"></button>
    </div>



    <p-scrollPanel [style]="{width: '100%', height: '200px'}">
        <div class="menu-item px-3" *ngFor="let not of notifications">
            <div class="menu-content d-flex align-items-center px-3"
                [ngStyle]="not.open ?{ 'background-color': ''}: {'background-color': 'Gainsboro'}">

                <div class="symbol symbol-50px me-5">
                    <div class="symbol symbol-35px symbol-circle">
                        <span class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fw-bold
                    ">{{ not?.title |uppercase|slice:0:1 }}</span>
                    </div>
                    <div class=" bg-success position-absolute  h-8px w-8px rounded-circle translate-middle start-100 top-100
              ms-n1 mt-n1 "></div>
                </div>

                <div class=" d-flex flex-column">
                    <div class="">
                        {{ not?.title }}
                    </div>

                    <a (click)="openNotification(not?.id)" *ngIf="not?.action=='liste-pointage-rh'"
                        [routerLink]="['/dashboard/rh/verifpointage/',not?.idChantier,not?.date]">
                        {{ not?.body }}
                    </a>
                    <a (click)="openNotification(not?.id)" *ngIf="not?.action=='liste-pointage-conducteur'"
                        [routerLink]="['/dashboard/pointage/',not?.idChantier]">
                        {{ not?.body }}
                    </a>
                    <a (click)="openNotification(not?.id)" *ngIf="not?.action=='liste-pointage-chef-chantier'"
                        [routerLink]="['/dashboard/pointage/',not?.idChantier]">
                        {{ not?.body }}
                    </a>
                    <a (click)="openNotification(not?.id)" *ngIf="not?.action=='liste-pointage-rh-when-conducteur-chefchantier-not-pointed'"
                    [routerLink]="['/dashboard/rh/pointage']">
                    {{ not?.body }}
                </a>
                </div>

                <i class="pi pi-times ml-auto d-lg-block" (click)="deleteNotification(not?.id)"></i>

            </div>

        </div>

    </p-scrollPanel>
</p-overlayPanel>

<p-menu #menu [popup]="true" [model]="items"></p-menu>
<p-overlayPanel #opk [style]="{width: '300'}">
    <div class="menu-item px-3">
        <div class="menu-content d-flex align-items-center px-3">

            <div class="symbol symbol-50px me-5">
                <img alt="Logo" src="../../../assets/images/avtar.jpg" *ngIf="!detail?.image">
                <img [src]="detail?.image" *ngIf="detail?.image" alt="logo" class="card-img rounded-circle" />
            </div>

            <div class="d-flex flex-column">
                <div class="fw-bolder d-flex align-items-center fs-5">Bonjour, {{name}}
                </div>
            </div>

        </div>
    </div>
    <div class="separator my-2"></div>
    <div class="menu-item px-5">
        <a href="/craft/account/overview.html" class="menu-link px-5" routerLink="/profile">Mon profile</a>
    </div>

    <div class="menu-item px-5 my-1">
        <!-- <a href="/craft/account/settings.html" class="menu-link px-5">Mon compte</a> -->
    </div>

    <div class="menu-item px-5">
        <!-- <a href="/craft/pages/projects/list.html" class="menu-link px-5">
            <span class="menu-text">Mes projet</span>
            <span class="menu-badge">
                <span class="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
            </span>
        </a> -->
    </div>


    <div class="separator my-2"></div>
    <div class="menu-item px-5">
        <a href="#" class="menu-link px-5" (click)="logOutBar()">Déconnexion</a>
    </div>

</p-overlayPanel>