import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PointageService } from './verfi-pointage/pointage.service';

@Component({
  selector: 'app-pointage',
  templateUrl: './pointage.component.html',
  styleUrls: ['./pointage.component.css']
})
export class PointageComponent implements OnInit {
  chantiers: any[];
  currentDate = new Date();
  date
  value: number = 0;
  
  constructor( public datepipe: DatePipe,private pointageService: PointageService) { }

  ngOnInit(): void {
   this.date= this.datepipe.transform(this.currentDate, "yyyy-MM-dd")
    this.getAllChantier();
  }

  getAllChantier() {
    this.pointageService.getAllChantier().subscribe((response: any) => {
      this.chantiers = response
      this.chantiers.forEach(element => {
        this.pointageService.getHommeJour(element.id, element.startDate, element.endDate).subscribe((hommejour: any) => {
          // console.log("hj=", element.nom, hommejour);
          // console.log("budjet=", element.chiffre_affaire / 250);
          const pourcentage = (hommejour / (element.chiffre_affaire / 250)) * 100
          element.pourcentageHj = pourcentage.toFixed(2)
          element.hj = hommejour
        })
      });
    })
  }
}
