import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanningService {

  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }

  
  getAllChantier() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantiers/', { headers: headers });
    return chantier;
  }
  getChantierById(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantiers/'+id,
      { headers: headers });
      return chantier; 
  }
  getTachByChantierAndDate(id) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    var date = new Date();
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");
    let params = new HttpParams();
    params = params.append('date', latest_date);
    params = params.append('idChantier', id);
    const taches = this.httpClient.get(environment.baseURL + environment.apiPath + 'tache/getTachesEnCoursByChantier',
                                     { params: params, headers: headers });
    return taches;

  }

  getOuvrierDisponibleInDate(sDate,edate){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    let startDate = this.datepipe.transform(sDate, "yyyy-MM-dd");
    let endDate = this.datepipe.transform(edate, "yyyy-MM-dd");
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    const users = this.httpClient.get(environment.baseURL + environment.apiPath + 'tache/getOuvrierDisponibleInDate',
                                                                   { params: params, headers: headers });
    return users;
  }
  getAllOuvrier(){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    const users = this.httpClient.get(environment.baseURL + environment.apiPath + 'users/getAllOuvrier', {  headers: headers });
    return users;

  }

  saveTache(tache){
    const httpOptions = {
      responseType: 'text' as 'json'
    };

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('startDate', tache.startDate);
    params = params.append('endDate', tache.endDate);
    params = params.append('idChantier', tache.idChantier);
    params = params.append('nom', tache.nom);

    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'tache/saveTache' ,tache,
                                      { params: params, headers: headers , responseType: 'text' as 'json' });
  }

  savePointagesByDuree(pointage,idsUsers){
    const httpOptions = {
      responseType: 'text' as 'json'
    };

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('startDate', pointage.startDate);
    params = params.append('endDate', pointage.endDate);
    params = params.append('idChantier', pointage.idChantier);

    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'pointage/savePointagesByDuree' ,idsUsers,
                                      { params: params, headers: headers , responseType: 'text' as 'json' });
  }

  getPointagesByDateAndChantier(id,date) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
 

    let params = new HttpParams();
    params = params.append('idChantier', id);
    params = params.append('fromDate', date);
    params = params.append('toDate', date);

    const ouvrierP = this.httpClient.get(environment.baseURL + environment.apiPath + 'pointage/getPointageByIdChantierAndDate', 
                                        { params: params, headers: headers });
    return ouvrierP;
  }


getListChantier(){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  const users = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantiers',
                                                                   { headers: headers });
    return users;
}

getOuvrierWithPointage(startDate,endDate,idUser){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

  let params = new HttpParams();
  params = params.append('emailUser', idUser);
  params = params.append('startDate', startDate);
  params = params.append('endDate', endDate);

  const pointage = this.httpClient.get(environment.baseURL + environment.apiPath + 'pointage/getPointageByEmailOuvrierAndDate',
                                                                   {params: params,  headers: headers });
    return pointage;
}

getPointageUserByIdChantierAndDate(startDate,endDate,idChantier){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  
  let params = new HttpParams();
  params = params.append('idChantier', idChantier);
  params = params.append('fromDate', startDate);
  params = params.append('toDate', endDate);

  const pointage = this.httpClient.get(environment.baseURL + environment.apiPath + 'pointage/getPointageUserByIdChantierAndDate',
                                                                   {params: params,  headers: headers });
    return pointage;
}


getPointageOuvrierByChantier(startDate,idUser){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);


  const taches = this.httpClient.get(environment.baseURL + environment.apiPath + 'users/getPointageAndUserByIdChantierAndDate'+idUser,
                                                                   { headers: headers });
    return taches;
}

getTachesEnCoursByChantier(){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
 // var date = new Date();
  let latest_date = this.datepipe.transform("2021-09-10", "yyyy-MM-dd");
  // let params = new HttpParams();
  // params = params.append('date',latest_date);
  // params = params.append('idChantier',id);

  const taches = this.httpClient.get(environment.baseURL + environment.apiPath + 'tache/getChantierWithTache',
                                                                   { headers: headers });
    return taches;
}


// chef chantier to chantier

getChefChantierAffecte(id){
  const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    var date = new Date();
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");
    let params = new HttpParams();
    params = params.append('date', latest_date);
    params = params.append('idChantier', id);
    const ChefChantierAffecte = this.httpClient.get(environment.baseURL + environment.apiPath + 'chantUser/getChefChantierAffecterEnCours',
                                                    { params: params, headers: headers });
    return ChefChantierAffecte; 
}
getAllChefChantier(){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  let params = new HttpParams();
  const users = this.httpClient.get(environment.baseURL + environment.apiPath + 'users/getAllChefChantier',
                                                                 { params: params, headers: headers });
  return users;
}

affectChefToChantier(affect){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

  let params = new HttpParams();
  params = params.append('startDate', affect.startDate);
  params = params.append('endDate', affect.endDate);
  params = params.append('emailUser',affect.user.email );
  params = params.append('idChantier', affect.idChantier);

  return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'chantUser/affecterChefChantierToChantier' ,affect,
  { params: params, headers: headers, responseType: 'text' as 'json' });
}


updateDeplace(id,idNewChantier){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  let params = new HttpParams();
  params = params.append('idChantier', idNewChantier);
  return this.httpClient.patch<any>(environment.baseURL + environment.apiPath  + 'pointage/updateChantierPointageByID/'+id,idNewChantier,
  { headers: headers });

}

diviserPointage(id,idChantierMatin,idChantierSoir){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  let params = new HttpParams();
  params = params.append('id', id);
  params = params.append('idChantierMatin', idChantierMatin);
  params = params.append('idChantierSoir', idChantierSoir);
  return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'pointage/dividePointage',id,{params: params, headers: headers });

}

deletePointage(id){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  return this.httpClient.delete(environment.baseURL + environment.apiPath + 'pointage/' + id,{headers: headers })
}

deleteUserAffecter(id){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  return this.httpClient.delete(environment.baseURL + environment.apiPath + 'chantUser/' + id,{headers: headers })
}
}
  