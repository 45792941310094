<div class="content fs-3 d-flex flex-column flex-column-fluid" id="kt_content">
  <div class="container-fluid d-flex flex-stack mb-5 flex-wrap flex-sm-nowrap" style="justify-content: end">
  </div>

  <div class="post fs-6 d-flex flex-column-fluid" id="kt_post">
    <div class="container">
      <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
          <h3>Affectation / Pointage </h3>
        </ng-template>
        <ng-template pTemplate="right">

          <button pButton pRipple type="button" label="Ajouter mon pointage" icon="pi pi-check-circle"
          class="p-button-raised p-button-text"
          [routerLink]="['/dashboard/monPointage']"></button>
        </ng-template>
      </p-toolbar>
      <div class="row g-5 gx-xxl-12 mb-xxl-3">
        <div class="col-xxl-12" data-select2-id="select2-data-139-ub4n">
          <div class="card card-xxl-stretch mb-xl-3">
            <div class="card-header border-0 pt-5 pb-3" data-select2-id="select2-data-138-w70k">
              <div class="card-toolbar">
                <!--begin::Search-->
                <div class="w-200px position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="
                      svg-icon svg-icon-3 svg-icon-gray-500
                      position-absolute
                      top-50
                      translate-middle
                      ms-6
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input type="text" class="
                      form-control form-control-sm form-control-solid
                      ps-10
                    " name="search" value="" placeholder="Chercher" />
                </div>
                <!--end::Search-->
              </div>
            </div>
            <div class="card-body py-0">

              <div class="d-flex flex-column-fluid" style="background: #f5f8fa">
                <!--begin::Container-->

                <div class="container">
                  <div class="row mb-5 mt-5">
                    <div class="col-xxl-3 col-xl-6 col-md-6 col-sm-6" *ngFor="let chantier of chantiers; index as i">
                      <!--begin::Card-->
                      <div class="card card-custom gutter-b card-stretch mb-5">
                        <!--begin::Body-->
                        <div class="card-body d-flex justify-content-center flex-column">
                          <!--begin::Toolbar-->

                          <!--end::Toolbar-->
                          <!--begin::User-->
                          <div class="d-flex align-items-end py-2 mb-5">
                            <!--begin::Pic-->
                            <div class="d-flex align-items-center">
                              <!--begin::Pic-->
                              <div class="d-flex flex-shrink-0 mr-5">
                                <div class="symbol symbol-50px me-2">
                                  <span class="symbol-label">
                                    <img alt="" class="w-25px" src="../../../assets/images/aven.svg" />
                                  </span>
                                </div>
                              </div>
                              <!--end::Pic-->
                              <!--begin::Title-->
                              <div class="d-flex flex-column">
                                {{chantier.nom}}
                                <span class="text-gray-400 fw-bold d-block"> {{chantier.client.nom}} </span>
                              </div>
                              <!--end::Title-->

                            </div>
                            <!--end::Title-->

                          </div>
                          <!--end::User-->
                          <!--begin::Desc-->

                          <!--end::Desc-->
                          <!--begin::Contacts-->
                          <div class="py-2">
                            <div class="d-flex align-items-center mb-5">
                              <i class="pi pi-map-marker"></i>

                              <span class="text-muted font-weight-bold ms-3"> {{chantier.adresse}}-{{chantier.ville}} {{
                                chantier.codePostal }}</span>
                            </div>

                            <div class="d-flex align-items-center mb-5">
                              <span class="flex-shrink-0">
                                <i class="pi pi-pi-box"></i>
                                <a class="text-muted text-hover-primary font-weight-bold ms-4">budget :
                                  {{chantier.chiffre_affaire/250 }} j/h</a>
                              </span>
                            </div>
                            <!-- <div class="d-flex align-items-center mb-5">
                              <span class="flex-shrink-0">
                                <i class="pi pi-pi-box" ></i>
                              <a  class="text-muted text-hover-primary font-weight-bold ms-4">Homme jour : {{chantier.hj}} j/h</a>
                            </span>
                            </div> -->
                            <p-progressBar [value]="chantier.pourcentageHj" [showValue]="true"></p-progressBar>
                            <div class="d-flex mt-6">
                              <button pButton pRipple type="button" label="Affectation" icon="pi pi-user-plus"
                                class="p-button-raised p-button-text"
                                [routerLink]="['/dashboard/affectOuvrier/',chantier.id]"></button>
                              <button pButton pRipple type="button" label="Pointage" icon="pi pi-check-circle"
                                class="p-button-raised p-button-text"
                                [routerLink]="['/dashboard/pointage/',chantier.id]"></button>
                            </div>
                          </div>
                          <!--end::Contacts-->
                          <!--begin::Actions-->

                          <!--end::Actions-->
                        </div>
                        <!--end::Body-->
                      </div>
                      <!--end::Card-->





                    </div>
                  </div>

                  <!--end::Container-->
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>