<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<p-tabView class="center-tab">

    <p-tabPanel header="Remboursement">
        <div class="container-xxl">
           
        <button type="button" class="btn btn-secondary no-background" routerLink="/dashboard/rh">
            <i class="pi pi-arrow-circle-left"></i>
            Retour
        </button>
        </div>
        <div class="d-flex flex-column justify-content-between h-100">
     
            <div class="flex-grow-1 bgi-no-repeat bgi-size-contain  bgi-position-x-center  bgi-position-y-bottom  card-rounded-bottom
                    max-h-175px  min-h-175px "
                style=" background-image: url('../../../../assets/images/remb.png'); "></div>
   
        </div>
        <div class="content fs-6 d-flex justify-content-center full-height">
           
            <div class="col-xl-8">
                <div class="card card-flush mt-6 mt-xl-9">
                    <div class="card-header mt-5">
                        <div class="card-title flex-column">
                            <h2 class="fw-boldest mb-2"> Remboursement Factures le {{
                                DateRemboursement | date: " d MMM, y"
                                }}</h2>
                            <div class="fs-6 fw-bold text-gray-400">
    
                            </div>
                        </div>
    
                        <div class="card-toolbar my-1" data-select2-id="select2-data-217-w6yy">
                            <div class="d-flex align-items-center position-relative my-1">
                                <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                                <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                            transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                        <path
                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                            fill="black"></path>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                                <p-calendar inputId="basic" [disabledDays]="[0,6]" id="kt_filter_search" dateFormat="dd-mm-yy"
                                    [(ngModel)]="DateRemboursement" (onSelect)="changeDateGetRemboursement()" [showIcon]="true"
                                     placeholder="Selectionner date">
                                </p-calendar>
                            </div>
                            <!--end::Search-->
                        </div>
                        <!--begin::Card toolbar-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-0">
                        <!--begin::Table container-->
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <div id="kt_profile_overview_table_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="table-responsive">
                                    <p-table #dt [value]="listRemboursement" [rows]="4" [paginator]="true" responsiveLayout="stack"
                                    editMode="row"  [showCurrentPageReport]="true" [globalFilterFields]="['nom','user.firstName']">
    
                                        <ng-template pTemplate="header">
                                            <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase"
                                                class="tr-hover">
                                                <th style="width: 20rem">
                                                    Utilisateur
                                                </th>
                                                <th>
                                                    Ref Facture
                                                </th>
                                                <th>
                                                    Date Facture
                                                </th>
                                            
                                                <th>
                                                    Montant remboursé
                                                </th>
                                              
                                                <th>
                                                    Valider et payer
                                                </th>
    
                                                <th>
                                                    Action
                                                </th>
                                             
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-remboursement>
                                            <tr class="even">
                                                <td style="width:80%">
                                                    <div class="d-flex align-items-center">
                                                        <div class="me-5 position-relative">
                                                            <div class="symbol symbol-35px symbol-circle">
                                                                <span
                                                                    class=" symbol-label bg-light-danger text-danger fw-bold">
                                                                    {{ remboursement.user.firstName|uppercase|slice:0:1 }}</span>
                                                            </div>
                                                            <div
                                                                class=" bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100  ms-n1 mt-n1 ">
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <a 
                                                                class="mb-1 text-gray-800 text-hover-primary"> {{remboursement.user.firstName}} {{remboursement.user.lastName}}
                                                            </a>
                                                            <span class="fw-bold fs-6 text-gray-400 ">
                                                                {{remboursement.user.email}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{remboursement.facture.refFacture}}
                                                </td>
                                                <td>
                                                    {{remboursement.date | date: 'dd-MM-yyyy'}}
                                                </td>
                                               
                                              
                                                <td>
                                                    {{remboursement.montant | currency: 'EUR'}}
                                                </td>
                                                <td>
    
                                                
                                                    <button pButton label="valider" icon="pi pi-check"
                                                        *ngIf="!remboursement.isValid" (click)="validerRemboursement(remboursement.id)"
                                                        class="p-button-raised p-button-success p-button-text p-mr-2 me-2"></button>
    
                                                        <i class="pi pi-check p-button-succes p-mr-4 me-2 "
                                                        style="font-size: 2rem" *ngIf="remboursement.payer"></i>
                                                    <button pButton label="payer " icon="pi pi-check"
                                                    *ngIf="remboursement.isValid && !remboursement.payer " (click)="payerRemboursement(remboursement.id)"
                                                        class="p-button-raised p-button-success p-button-text p-mr-2 me-2"></button>
    
                                                </td>
                                               
                                                <td>
                                                    <button pButton icon="pi pi-trash" (click)="deleteRemboursement(remboursement)"
                                                        class="p-button-raised p-button-danger p-button-text "></button>
    
                                                    <button pButton icon="pi pi-pencil" (click)="updateRemboursement(remboursement)"
                                                        class="p-button-raised p-button-primery p-button-text "></button>
    
                                                </td>
    
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                            <!--end::Table-->
                        </div>
                        <!--end::Table container-->
                    </div>
                    <!--end::Card body-->
                </div>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>


<p-dialog header="Modifier remboursement facture" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
    [(visible)]="updateRemboursementDialog" [style]="{width: '400px'}" [contentStyle]="{height: '200px'}" class="overflow-show">
    <div class="p-grid p-fluid flex-column">
        <div class="p-col-12 p-md-4">
        
                <div class="row mt-3">
                    <p-dropdown [options]="listUser" [(ngModel)]="userRemborser" optionLabel="firstName" [filter]="true"
                        filterBy="firstName,lastName" [showClear]="true" [ngModelOptions]="{standalone: true}"
                        placeholder="Sélectionner l'ouvrier à rembourser">
                        <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="userRemborser">
                                <div>{{userRemborser.firstName}} {{userRemborser.lastName}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-USER pTemplate="item">
                            <div class="country-item">
                                <div>{{ USER.firstName }} {{ USER.lastName }}</div>

                            </div>
                        </ng-template>
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted  && this.userRemborser==null">
                        Utilisateur est requis.</small>
                </div>

                <button pButton pRipple label="Modifier" (click)="saveupdateRemboursement()" icon="pi pi-plus"
                    class="btn btn-primary p-mr-2 mt-5"></button>

        </div>
    </div>
</p-dialog>