import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment  } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FactureService {

  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }

  getAllChantier() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath  + 'chantiers/' , { headers: headers });
    return chantier;
  }
  getAllFournisseur() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const fournisseurs = this.httpClient.get(environment.baseURL + environment.apiPath  + 'fournisseurs/' , { headers: headers });
    return fournisseurs;
  }
  getAllUser() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const users = this.httpClient.get(environment.baseURL + environment.apiPath  + 'users/getAllUsers' , { headers: headers });
    return users;
  }
  getFacturesByUserAandDate(email,fromDate,toDate) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);

    const listMasse = this.httpClient.get(environment.baseURL + environment.apiPath + 'factures/getFacturesByUserAndDate', {params: params, headers: headers });
    return listMasse;
  }


  saveFacture(facture,rembourser,promiseList,email,userRemborser){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    // const formData: FormData = new FormData();
    // formData.append('file',file, file.name);
    const formData = new FormData();
    promiseList.forEach(file => {

      formData.append('file', file, file.name);
      formData.append('type', file.type);
    formData.append('force', file.toString());
   })

    let DATE = this.datepipe.transform(facture.date, "yyyy-MM-dd");
    let params = new HttpParams();
    params = params.append('date',DATE)
    params = params.append('idChantier',facture.chantier.id)
    params = params.append('idFournisseur',facture.fournisseur.id)
    if (userRemborser) {
      params = params.append('idUserRemborser',userRemborser.id)
    }else{
      params = params.append('idUserRemborser','0')
    }
    params = params.append('email',email)
    params = params.append('montant',facture.montant)
    params = params.append('description',facture.description)
    params = params.append('refFacture',facture.refFacture)
    params = params.append('rembourser',rembourser)
    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'factures/saveNewFacture' ,formData,{ params: params, headers: headers });
  }
//upload file method
uploadFile(file) {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
   //append any other key here if required
  return this.httpClient.post(`<upload URL>`, formData);
}

getFilesFacture(idFacture){
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  let params = new HttpParams();
  params = params.append('idFacture', idFacture);

  const listMasse = this.httpClient.get(environment.baseURL + environment.apiPath + 'fileFacture/', {params: params, headers: headers });
  return listMasse;

}

}
