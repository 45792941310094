import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common'
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PointageChefConducteurService {

  baseUrl = "/api";

  constructor(private httpClient: HttpClient, public datepipe: DatePipe) { }

  
  savePointageChefChantierInRh(email){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token)

    var date = new Date();
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");

    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('date', latest_date);

return this.httpClient.post(environment.baseURL + environment.apiPath + 'pointageChefChantierAndConducteur/savePointagesChefChantierAndConducteurByDuree',email, 
 {params:params,headers: headers , responseType: 'text'})
  }

  getPointageByChefConducteurByDateAndEmailInRh(){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token)
    const email = localStorage.getItem('email');
    var date = new Date();
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");
    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('fromDate', latest_date);
    params = params.append('toDate', latest_date);

 return   this.httpClient.get(environment.baseURL + environment.apiPath + 'pointageChefChantierAndConducteur/getPointageByChefConducteurByDateAndEmail', 
  {params:params, headers: headers })
  }

  getPointageChefConducteurByDateInRh(date){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token)
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");
    let params = new HttpParams();
    params = params.append('fromDate', latest_date);
    params = params.append('toDate', latest_date);

 return   this.httpClient.get(environment.baseURL + environment.apiPath + 'pointageChefChantierAndConducteur/getPointageChefConducteurByDate', 
  {params:params, headers: headers })
  }

  getChefChantierConducteurNoPointedByDate() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    var date = new Date();
    let latest_date = this.datepipe.transform(date, "yyyy-MM-dd");
    let params = new HttpParams();
    params = params.append('fromDate', latest_date);
    params = params.append('toDate', latest_date);

 return   this.httpClient.get(environment.baseURL + environment.apiPath + 'pointageChefChantierAndConducteur/getChefChantierConducteurNoPointedByDate', 
  {params:params, headers: headers })
  }

  changeStatusInRh(status,id){

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token)
 return   this.httpClient.patch(environment.baseURL + environment.apiPath + 'pointageChefChantierAndConducteur/updatePointageChefChantierAndConducteurByID/'+id,status, 
 {headers: headers })
  }

  deletePointage(id){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.delete(environment.baseURL + environment.apiPath + 'pointageChefChantierAndConducteur/' + id,{headers: headers })
  }



  findImageUser(email){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const details = this.httpClient.get(environment.baseURL + environment.apiPath  + 'user-details/findImageUser/'+email , 
    { headers: headers, responseType: 'text' });
    return details; 
  }
}
