import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CalendarOptions } from "@fullcalendar/angular";
import { ChantierConducteurService } from "../../chantiers/chantier-conducteur.service";
import { PlanningService } from "../planning.service";
import { L10n, setCulture } from '@syncfusion/ej2-base';

@Component({
  selector: "app-table-dashboard-occupation",
  templateUrl: "./table-dashboard-occupation.component.html",
  styleUrls: ["./table-dashboard-occupation.component.css"],
})
export class TableDashboardOccupationComponent implements OnInit {
  events: any[];
  eventss: any[];
  options: any;
  header: any;
  tachEUsers: any[];
  chantiers: any[];
  userTache: any[];
  startDate: Date;
  chantierSelect
  calendarOptions: CalendarOptions;
  stateOptions: any[];
  diableDrodown: boolean;
  allSelect: any[];

  //gant
  public data: object[];
  public taskSettings: object;
  public labelSettings: object;
  public projectStartDate: Date;
  public projectEndDate: Date;
  public toolbar: string[];
  public columns: object[];
  public editSettings: object;

  @ViewChild('calendar', { static: true }) calendar: ElementRef<any>

  constructor(private tacheService: PlanningService) { }

  ngOnInit() {
    this.getAllTachesFromDate()

    //gant  
    this.taskSettings = {
      id: 'TaskID', visible: false,
      name: 'TaskName',
      duration: 'Duration',
      startDate: 'StartDate',
      endDate: 'EndDate',
      child: 'Children'
    
  };

//   this.editSettings = {
//     allowEditing:true,
//     allowTaskbarEditing:true
// };
 
  this.labelSettings = {
      rightLabel: 'TaskName',
  };
  }

  getAllTachesFromDate() {
 this.tacheService.getTachesEnCoursByChantier().subscribe((response: any) => {
      this.tachEUsers =  response.filter(chantier=>{return  chantier.taches.length!=0})
      console.log(this.tachEUsers);
      
      this.tachEUsers.forEach(obj => {
        obj.taches.forEach(element => {
          if (element) {
            this.renameKey(element, 'endDate', 'EndDate'),
            this.renameKey(element, 'startDate', 'StartDate'),
            this.renameKey(element, 'nom', 'TaskName'),
            this.renameKey(element, 'id', 'TaskID')
          }    
        });     
      });
      this.tachEUsers.forEach(obj => {
        if (obj.taches!=[]) {
          this.renameKey(obj, 'taches', 'Children')
        }
        this.renameKey(obj, 'nom', 'TaskName')
       this.renameKey(obj, 'id', 'TaskID') 
        
      });
     
      this.data =  this.tachEUsers;

 

    })

  }
  renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  ajoutDay(obj) {
    let date = new Date(obj.end);
    let newdate = new Date(date.setDate(date.getDate() + 1));
    obj.end = newdate
  }

  ajoutNomCh(obj) {
   obj.title=obj.title+" ("+obj.chantier.nom+" )"
  }

  addKey(obj, key) {
    obj[key] = 1
  }
}
