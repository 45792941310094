import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PlanningService } from 'src/app/conducteur-travaux/planning/planning.service';

@Component({
  selector: 'app-affecte-ouvrier',
  templateUrl: './affecte-ouvrier.component.html',
  styleUrls: ['./affecte-ouvrier.component.css'],
  providers: [MessageService]
})
export class AffecteOuvrierComponent implements OnInit {

  affectOuvrierDialog = false;
  UpdateOuvrierDialog = false;
 divisie= true;

  ouvrierForm
  updateChantierPointageForm
  devisePointageForm
  myDates: Date;
  datePointage: Date;
  allChantier: any[];
  ouvriers: any[];
  currentDate = new Date();
  today: Date;
  defaultDate;
  chantierName;
  submitted: boolean;
  ouvrierPointage
  id
  constructor(private plannningService: PlanningService, private router: ActivatedRoute, public datepipe: DatePipe
    , private confirmationService: ConfirmationService, private messageService: MessageService) {
  }

  ngOnInit(): void {

    this.router.params.subscribe(
      () => {
        this.id = this.router.snapshot.paramMap.get('id');
      })
    
    this.getAllOuvrier();
    this.getChantierById(this.id);
    this.getAllChantierForUpdate();
    this.getPointagesByDateAndChantier(this.id, this.datepipe.transform(this.currentDate, "yyyy-MM-dd"))
    this.datePointage = new Date();
    this.today = new Date();
   

    this.ouvrierForm = new FormGroup({
      user: new FormControl('', [Validators.required]),
      idChantier: new FormControl(),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      myDates: new FormControl('', [Validators.required]),
    });
    this.updateChantierPointageForm = new FormGroup({
      id: new FormControl(''),
      NewChantier: new FormControl('', [Validators.required]),
    });

    this.devisePointageForm = new FormGroup({
      id: new FormControl(''),
      idChantierMatin: new FormControl('', [Validators.required]),
      idChantierSoir: new FormControl('', [Validators.required]),
    });
  }


  getChantierById(id) {
    this.plannningService.getChantierById(id).subscribe((response: any) => {
      this.chantierName = response.nom;

    })
  }
  getAllChantierForUpdate() {
    this.plannningService.getAllChantier().subscribe((response: any) => {
      this.allChantier = response;
    })
  }
  

  
  // affect ouvrier

  openNewOuvrier() {
    this.submitted = false;
    this.affectOuvrierDialog = true;
  }

  deletePointage(id) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de supprimer le pointage ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.plannningService.deletePointage(id).subscribe(
          (msg) => {
            console.log(msg)
           
          },
          (error) => {
            console.log(error),
            this.messageService.add({severity:'error', summary:'Erreur', detail:'Pointage non supprimé'});
          },
          () => {
            this.getPointagesByDateAndChantier(this.id, this.datepipe.transform(this.currentDate, "yyyy-MM-dd"));
            this.messageService.add({severity:'info', summary:'Confirmation', detail:'Pointage supprimé'});
          }
        );        
      }
  });  
  }

  openNewUpdateOuvrier(pointage) {
    this.submitted = false;
    this.UpdateOuvrierDialog = true;
   
    if (pointage.duree=="JOURNEE") {
      this.divisie =true;
    }else{
      this.divisie =false;
    }
 
    this.updateChantierPointageForm.reset();
    this.devisePointageForm.reset();
    this.updateChantierPointageForm.controls['id'].setValue(pointage.id);
    this.devisePointageForm.controls['id'].setValue(pointage.id);
  }

  saveUpdateDeplace() {

    this.submitted = true;
    if (this.updateChantierPointageForm.invalid) {
      return;
    }
    let id = this.updateChantierPointageForm.value.id
    let idNewChantier = this.updateChantierPointageForm.value.NewChantier.id

    this.plannningService.updateDeplace(id, idNewChantier).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.ngOnInit();
          this.messageService.add({severity:'success', summary: 'Success', detail: 'pointage modifié !'});
      }
    );
    this.updateChantierPointageForm.reset();
    this.UpdateOuvrierDialog = false

  }

  saveDivisierPointage() {
    this.submitted = true;
    if (this.devisePointageForm.invalid) {
      return;
    }
    let id = this.devisePointageForm.value.id
    let idChantierMatin = this.devisePointageForm.value.idChantierMatin.id
    let idChantierSoir = this.devisePointageForm.value.idChantierSoir.id
    this.plannningService.diviserPointage(id, idChantierMatin, idChantierSoir).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Pointage divisé !'});
        this.ngOnInit();
      }
    );
    this.devisePointageForm.reset();
    this.UpdateOuvrierDialog = false

  }


  getAllOuvrier() {
    this.plannningService.getAllOuvrier().subscribe((response: any) => {
      this.ouvriers = response

    })
  }
  getPointagesByDateAndChantier(id, date) {
    this.plannningService.getPointagesByDateAndChantier(id, date).subscribe((response: any) => {
      this.ouvrierPointage = response
    })
  }

  changeDateGetPointage() {

    let Datet = this.datepipe.transform(this.datePointage, "yyyy-MM-dd")
    this.getPointagesByDateAndChantier(this.id, Datet)

  }
  changePeriodePointage() {
    let startDate = this.datepipe.transform(this.ouvrierForm.value.myDates[0], "yyyy-MM-dd")
    let endDate = this.datepipe.transform(this.ouvrierForm.value.myDates[1], "yyyy-MM-dd")
    this.ouvrierForm.patchValue({ startDate: startDate })
    this.ouvrierForm.patchValue({ endDate: endDate })
  }

  saveOuvrier() {
    this.ouvrierForm.patchValue({ idChantier: this.id })
    this.submitted = true;
    if (this.ouvrierForm.invalid) {
      return;
    }
    let idsUsers = [];
    this.ouvrierForm.value.user.forEach(user => {
      idsUsers.push(user.id)

    });
    this.plannningService.savePointagesByDuree(this.ouvrierForm.value, idsUsers).subscribe(
      (msg) => {
        console.log(msg)
      },
      (error) => {
      this.ouvrierForm.reset(),
        console.log(error)
      },
      () => {
        this.ouvrierForm.reset(),
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: ' Affectation est réussie!'});
        this.ngOnInit();
        
      }
    );
   
   this.affectOuvrierDialog = false
  
  }
}
