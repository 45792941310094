import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FournisseurService } from './fournisseur.service';


@Component({
  selector: 'app-fournisseur',
  templateUrl: './fournisseur.component.html',
  styleUrls: ['./fournisseur.component.css']
})
export class FournisseurComponent implements OnInit {
  fournisseurs: any[];
  fournisseurDialog: boolean;
  submitted: boolean;
  fournisseurForm: FormGroup;
  showAddButton: boolean = true;

  constructor(private fournisseurService: FournisseurService, private messageService: MessageService
              , private confirmationService: ConfirmationService) { }


              
  ngOnInit(): void {
    this.fournisseurForm = new FormGroup({
      id: new FormControl(),
      nom: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      numTel: new FormControl('',  [Validators.required,Validators.pattern('[- +()0-9]+')]),
      adresse: new FormControl('', [Validators.required]),
      codePostal: new FormControl('', [Validators.required]),
      ville: new FormControl('', [Validators.required]),
      modeDePaiment: new FormControl('', [Validators.required]),
    })

    this.getAllFournisseurs();
  }
  getAllFournisseurs() {
    this.fournisseurService.getAllFournisseur().subscribe((response: any) => {
      this.fournisseurs = response
      console.log(response);
      
    })
  }

  openNew() {
    this.submitted = false;
    this.fournisseurDialog = true;
    this.fournisseurForm.reset();
    this.showAddButton = true;
  }

  editFournisseur(fournisseur) {
    this.showAddButton = false;
    this.fournisseurForm.patchValue(fournisseur)
    this.fournisseurDialog = true;
  }

  hideDialog() {
    this.fournisseurDialog = false;
    this.submitted = false;
  }


  saveFournisseur() {
    this.submitted = true;
    if (this.fournisseurForm.invalid) {
      return;
    }
    this.fournisseurService.addFournisseur(this.fournisseurForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Fournisseur Créé', life: 3000 });

      },
      (error) => {
        console.log(error)
        this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Fournisseur Non Créé', life: 3000 })
      },
      () => {

        this.getAllFournisseurs();
      }
    );
    this.fournisseurForm.reset();
    this.fournisseurDialog = false;

  }

  deleteFournisseur(fournisseur) {
  this.confirmationService.confirm({
    target: event.target,
      message: 'Etes-vous sûr que vous voulez supprimer ' + fournisseur.nom + '?',
      header: 'Confirmer',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.fournisseurService.deleteFournisseur(fournisseur.id).subscribe(
          (msg) => {
            console.log(msg)
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Fournisseur Supprimé', life: 3000 });
          },
          (error) => {
            console.log(error),
              this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Fournisseur Non Supprimé', life: 3000 })

          },
          () => {
            this.getAllFournisseurs();
          }
        );
      }
    });
  }
 
  saveUpdate() {
    this.fournisseurDialog = false;
    this.showAddButton = true;

    this.fournisseurService.updateFournisseur(this.fournisseurForm.value).subscribe(
      (msg) => {
        console.log(msg)
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Fournisseur modifié', life: 3000 });

      },
      (error) => {
        console.log(error),
          this.messageService.add({ severity: 'error ', summary: 'Erreur', detail: 'Fournisseur non modifié', life: 3000 })
      },
      () => {
        this.getAllFournisseurs();
      }
    );

  }

}
