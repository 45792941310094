import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erreur-not-found',
  templateUrl: './erreur-not-found.component.html',
  styleUrls: ['./erreur-not-found.component.css']
})
export class ErreurNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
