import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsommationService } from '../consommation.service';

@Component({
  selector: 'app-depense',
  templateUrl: './depense.component.html',
  styleUrls: ['./depense.component.css']
})
export class DepenseComponent implements OnInit {
  chantiers: any[];
  listMasseSalUser: any[];
  listFactures: any[];
  masseSal
  sumFact
  sumDespense
  depenseForm: FormGroup;
  startDate
  endDate
  chantier
  submitted: boolean;
  calculed: boolean;
  masseDialog: boolean = false;
  factureDialog: boolean = false;

  constructor(private consommationService: ConsommationService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.getAllChantier();
    this.depenseForm = new FormGroup({
      chantier: new FormControl('', [Validators.required]),
      myDates: new FormControl('', [Validators.required]),
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
    });


  }
  getAllChantier() {
    this.consommationService.getAllChantier().subscribe((response: any) => {
      this.chantiers = response
    })
  }

  changePeriodeDepense() {
    let start = this.datepipe.transform(this.depenseForm.value.myDates[0], "yyyy-MM-dd")
    let end = this.datepipe.transform(this.depenseForm.value.myDates[1], "yyyy-MM-dd")
    this.depenseForm.patchValue({ start: start })
    this.depenseForm.patchValue({ end: end })
  }

  claculer() {
    this.submitted = true;

    if (this.depenseForm.invalid) {
      return; 
    }

    this.calculed = true;
    this.startDate = this.datepipe.transform(this.depenseForm.value.myDates[0], "yyyy-MM-dd")
    this.endDate = this.datepipe.transform(this.depenseForm.value.myDates[1], "yyyy-MM-dd")
    let idChantier = this.depenseForm.value.chantier.id;
    this.chantier = this.depenseForm.value.chantier;
    this.getSumMasseSalarialesByChantier(idChantier, this.startDate, this.endDate);
    this.getSumFacturesByChantier(idChantier, this.startDate, this.endDate);
    this.getSumCountSumDespensesByChantier(idChantier, this.startDate, this.endDate);
    this.getMasseSalarialesUser(idChantier,this.startDate,this.endDate);
    this.getFactureByChantierAndDate(idChantier,this.startDate,this.endDate)
  }


  getSumMasseSalarialesByChantier(idChantier, startDate, endDate) {
    this.consommationService.getMasseSalarialesByChantier(idChantier, startDate, endDate).subscribe((response: any) => {
      this.masseSal = response
    })
  }

 getSumFacturesByChantier(idChantier, startDate, endDate) {
    this.consommationService.getFacturesByChantier(idChantier, startDate, endDate).subscribe((response: any) => {
      this.sumFact = response
    })
  }

  getSumCountSumDespensesByChantier(idChantier, startDate, endDate) {
    this.consommationService.getCountSumDespensesByChantier(idChantier, startDate, endDate).subscribe((response: any) => {
      this.sumDespense = response
    })
  }


  getMasseSalarialesUser(idChantier, startDate, endDate) {
    this.consommationService.getMasseSalarialesUser(idChantier, startDate, endDate).subscribe((response: any) => {
      this.listMasseSalUser = response    
    })
  }
  getFactureByChantierAndDate(idChantier, startDate, endDate){
    this.consommationService.getFacturesByChantierAandDate(idChantier, startDate, endDate).subscribe((response: any) => {
      this.listFactures = response   
    })
  }

  showDialogMasseSal(){
    this.masseDialog=true;
  }

  showDialogFacture(){
    this.factureDialog=true;
  }
}
