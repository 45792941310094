<p-toast></p-toast>
<div class="d-flex flex-column flex-root full-page-height">
  <!--begin::Authentication - Sign-in -->
  <div class="d-flex flex-column flex-lg-row flex-column-fluid">
    <!--begin::Aside-->

    <!--begin::Aside-->
    <!--begin::Body-->
    <div class="d-flex flex-column flex-lg-row-fluid">
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column flex-column-fluid">
        <!--begin::Wrapper-->
        <div class="w-lg-500px p-10 p-lg-15 mx-auto">
          <!--begin::Form-->
          <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate"
            [formGroup]="loginForm" id="kt_sign_in_form">
            <!--begin::Heading-->
            <div class="text-center logo-container">
              <img src="../../../assets/images/logo.png" alt="logo" />
            </div>
          
            <div class="fv-row mb-10 fv-plugins-icon-container">
          
              <div class="input-group">
                <input type="text" pInputText name="email" placeholder="Entrer votre email" style=" width: 31rem" formControlName="email" />
              </div>
              <div>
                <small class="text-danger" *ngIf="submitted && loginForm.get('email').hasError('required')">Email is
                  required.</small>
                <small class="text-danger" *ngIf="submitted && loginForm.get('email').hasError('email')">Email not
                  valid.</small>
              </div>


            </div>
         

            <div class="fv-row mb-10 fv-plugins-icon-container">

              <div class="input-group">
                <p-password formControlName="password" [feedback]="false" placeholder="Entrer votre mot de passe"  [toggleMask]="true">
                </p-password>
              </div>
              <div>
                <small class="text-danger" *ngIf="submitted && loginForm.get('password').hasError('required')">Password
                  is
                  required.</small>
              </div>
            </div>
            <div class="text-center">
              <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 fw-bolder me-3 my-2"
                (click)="submitForm()">
                <span class="indicator-label">Connexion</span>
                <span class="indicator-progress">Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>

            </div>
            <a routerLink="/forgetPassword" class="link-primary fs-6 ">Mot de passe oublié ?</a>
            <!--end::Actions-->
            <div>
            </div>
          </form>
          <!--end::Form-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Authentication - Sign-in-->
</div>