import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChantierConducteurService {
  baseUrl = "/api";


  constructor(private httpClient: HttpClient) { }



  getAllChantier() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath  + 'chantiers/' , { headers: headers });
    return chantier;
  }

  getAllClient() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const chantier = this.httpClient.get(environment.baseURL + environment.apiPath  + 'clients/' , { headers: headers });
    return chantier;
  }

  addChantier(chantier){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.post<any>(environment.baseURL + environment.apiPath  + 'chantiers/' , chantier,  { headers: headers });
  }

  deleteChantier(id) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);

    return this.httpClient.delete(environment.baseURL + environment.apiPath  + 'chantiers/' + id, {headers: headers, responseType: 'text' })
  }
  updateChantier(chantier) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.httpClient.put(environment.baseURL + environment.apiPath  + 'chantiers/' +chantier.id, chantier, {headers: headers, responseType: 'text' })
  }


}

